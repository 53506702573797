<!-- Tabs buttons -->
<div class="flex gap-[1px]">
  <!-- Tabs button -->
  <div
    *ngFor="let tab of tabs; let i = index"
    (click)="onSelectTab(tab, i)"
    class="flex items-center justify-center bg-neutral-focus w-[248px] h-[64px] px-4 rounded-t-xl cursor-pointer"
    [ngClass]="{ '!bg-white': tab.selected }"
  >
    <ng-container
      *ngTemplateOutlet="tab.titleTemplate; context: { tab }"
    ></ng-container>
  </div>
</div>

<!-- Projected tabs content -->
<ng-content></ng-content>
