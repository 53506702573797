import { Injectable } from '@angular/core';
import { DocumentMarkup } from '@et/typings';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root',
})
export class MarkupRepositoryService {
  protected _tableName = 'markups';

  constructor(private storage: StorageService) {}

  /**
   * This function sets initial dummy value in the table
   */
  async addDummy() {
    try {
      await this.setTable();
      await this.storage.setItem('0', '1');
    } catch (error) {
      console.error('MarkupRepositoryService: ', error);
      return;
    }
  }

  /**
   * This function return all values from storage
   */
  async getAll(): Promise<DocumentMarkup[]> {
    try {
      await this.setTable();
      const markupJSONArrWithDummy = await this.storage.getAllValues();
      // Remove dummy
      const markupJSONArr = markupJSONArrWithDummy.filter((m) => m !== '1');
      const markups: DocumentMarkup[] = [];
      for (const markupJSON of markupJSONArr) {
        const markup = JSON.parse(markupJSON);
        markups.push(markup);
      }
      return markups;
    } catch (error) {
      console.error('MarkupRepositoryService: ', error);
      return [];
    }
  }

  /**
   * This function gets DocumentMarkup by id
   * @param {string} id - packet id or eNote id
   */
  async get(id: string): Promise<DocumentMarkup | null> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return null;
      }
      const markupJSON = await this.storage.getItem(id);
      const markup = JSON.parse(markupJSON);
      return markup;
    } catch (error) {
      console.error('MarkupRepositoryService: ', error);
      return null;
    }
  }

  /**
   * This function adds DocumentMarkup to storage
   * @param {string} id - Packet id or eNote id
   * @param {DocumentMarkup} markup - PacketListItem
   */
  async createOrUpdate(id: string, markup: DocumentMarkup): Promise<void> {
    try {
      await this.setTable();
      const markupJSON = JSON.stringify(markup);
      await this.storage.setItem(id, markupJSON);
    } catch (error) {
      console.error('MarkupRepositoryService: ', error);
    }
  }

  /**
   * This function makes default markup
   * @param {string} id - packet id
   * @param {DocumentMarkup} markup - DocumentMarkup
   */
  async createDefault(id: string, markup: DocumentMarkup): Promise<void> {
    try {
      await this.setTable();
      const markupJSON = JSON.stringify(markup);
      const defaultMarkupId = this.getDefaultMarkupId(id);
      const exists = await this.storage.isKey(defaultMarkupId);
      if (exists) {
        return;
      }
      await this.storage.setItem(defaultMarkupId, markupJSON);
    } catch (error) {
      console.error('MarkupRepositoryService: ', error);
    }
  }

  /**
   * This function takes default markup and sets it as a document markup
   * @param {string} id - packet Id
   */
  async resetToDefault(id: string): Promise<void> {
    try {
      await this.setTable();
      const defaultMarkupId = this.getDefaultMarkupId(id);
      const exists = await this.storage.isKey(defaultMarkupId);
      if (!exists) {
        return;
      }
      const defaultMarkupJSON = await this.storage.getItem(defaultMarkupId);
      await this.storage.setItem(id, defaultMarkupJSON);
    } catch (error) {
      console.error('MarkupRepositoryService: ', error);
    }
  }

  /**
   * This function deletes DocumentMarkup in storage
   * @param {string} id - Packet id or eNote id
   */
  async delete(id: string): Promise<void> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return;
      }
      await this.storage.removeItem(id);
    } catch (error) {
      console.error('MarkupRepositoryService: ', error);
    }
  }

  /**
   * This function deletes default DocumentMarkup in storage
   * @param {string} id - Packet id or eNote id
   */
  async deleteDefault(id: string): Promise<void> {
    try {
      const defaultMarkupId = this.getDefaultMarkupId(id);
      await this.delete(defaultMarkupId);
    } catch (error) {
      console.error('MarkupRepositoryService: ', error);
    }
  }

  /**
   * This function deletes all keys
   */
  async clear(): Promise<void> {
    try {
      await this.setTable();
      await this.storage.clear();
      await this.addDummy();
    } catch (error) {
      console.error('MarkupRepositoryService: ', error);
    }
  }

  /**
   * This function sets storage table
   */
  async setTable() {
    await this.storage.setTable(this._tableName);
  }

  /**
   * It takes takes packet id and returns default markup id
   * @param {string} packetId - string
   * @returns {string} - default markup id
   */
  private getDefaultMarkupId(packetId: string): string {
    return `${packetId}_default`;
  }
}
