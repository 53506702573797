import { LoginFailedComponent } from './components/login-failed/login-failed.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';

export const authRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'error', component: LoginComponent },
  { path: 'login-failed', component: LoginFailedComponent },
];

@NgModule({
  imports: [RouterModule.forChild(authRoutes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
