<button
  class="flex items-center justify-center w-12 h-12 transition-colors border-2 rounded-full border-midnight"
  [class]="_isActive ? 'bg-midnight' : 'bg-transparent'"
  [ngClass]="{
    '!pointer-events-none !border-neutral-secondary !bg-transparent':
      _isDisabled
  }"
>
  <div #svgContainer>
    <ng-content></ng-content>
  </div>
</button>
<span
  class="mt-1 text-xs font-medium text-center"
  [ngClass]="{
    '!text-neutral-secondary': _isDisabled
  }"
  >{{ name }}</span
>
