import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '@et/auth';

@Component({
  selector: 'et-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutModalComponent {
  constructor(private authService: AuthService) {}
  /**
   * Logs out user
   * @returns None
   * @memberof LogoutModalComponent
   */
  onLogout() {
    this.authService.logout();
  }
}
