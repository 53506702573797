import {
  Component,
  ChangeDetectionStrategy,
  Input,
  TemplateRef,
  AfterViewInit,
} from '@angular/core';
import { uid } from '@et/utils';
@Component({
  selector: 'et-atoms-modal-with-template',
  templateUrl: './modal-with-templates.component.html',
  styleUrls: ['./modal-with-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalWithTemplatesComponent implements AfterViewInit {
  @Input()
  openButtonTemplate!: TemplateRef<unknown>;
  @Input()
  bodyTemplate!: TemplateRef<unknown>;
  @Input()
  footerTemplate!: TemplateRef<unknown>;
  @Input() isOpen: boolean | undefined = false;
  @Input() id = uid();

  ngAfterViewInit() {
    const model = document.getElementById(this.id);
    document.body.appendChild(model as HTMLElement);
  }

  /**
   * Toggle the modal state
   * @param {MouseEvent} event - MouseEvent
   *
   * @memberof ModalWithTemplatesComponent
   */
  toggleModal(event: MouseEvent) {
    event.preventDefault();
    this.isOpen = !this.isOpen;
  }
}
