<button class="flex items-center gap-2 font-medium">
  <img
    src="assets/icons/arrow-left.svg"
    width="12"
    height="12"
    alt="Back"
    class="w-3 h-3 m-0"
  />
  Back
</button>
