<div
  class="absolute bg-[#BFF4D2] h-full left-0 transition-all duration-700 w-0"
  [style.width.%]="
    isNumber(completedItems) && isNumber(totalItems)
      ? (completedItems! / totalItems!) * 100
      : 0
  "
></div>
<p
  *ngIf="isNumber(completedItems) && isNumber(totalItems)"
  class="relative flex gap-1 text-xs font-bold"
>
  <span>{{ completedItems }}</span
  ><span>/</span><span>{{ totalItems }}</span>
  <span *ngIf="showText" class="font-medium">signature pages</span>
</p>
