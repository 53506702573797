import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
import { allStates } from '@et/typings';
declare const parseFullName: ParseFullName;
declare const addresser: Adresser;

interface ParseFullName {
  parse: (
    nameToParse: string,
    partToReturn?: partToReturn,
    fixCase?: boolean | -1 | 0 | 1,
    stopOnError?: boolean | 0 | 1,
    useLongLists?: boolean | 0 | 1,
  ) => Name;
}

type partToReturn =
  | 'title'
  | 'first'
  | 'middle'
  | 'last'
  | 'nick'
  | 'suffix'
  | 'error'
  | 'all';

interface Name {
  title?: string | undefined;
  first?: string | undefined;
  middle?: string | undefined;
  last?: string | undefined;
  nick?: string | undefined;
  suffix?: string | undefined;
  error?: [] | undefined;
}

interface Adresser {
  parseAddress: (addressString: string) => IParsedAddress;
}

interface IParsedAddress {
  zipCode: string;
  stateAbbreviation: string;
  stateName: string;
  placeName: string;
  addressLine1: string;
  addressLine2?: string;
  streetNumber: string;
  streetSuffix: string;
  streetName: string;
  id: string;
  errorMsg?: string;
}

/**
 * Parses address
 * @returns Parsed address
 *
 */
export function parseAddress(value: string) {
  const address = addresser.parseAddress(value);
  if (address.errorMsg) {
    return {
      zipCode: address.errorMsg,
      stateAbbreviation: address.errorMsg,
      stateName: address.errorMsg,
      placeName: address.errorMsg,
      addressLine1: address.errorMsg,
      addressLine2: address.errorMsg,
      streetNumber: address.errorMsg,
      streetSuffix: address.errorMsg,
      streetName: address.errorMsg,
      id: address.errorMsg,
      errorMsg: address.errorMsg,
    };
  }
  return address;
}

/**
 * Parses full name
 * @returns A string with numbers
 *
 */
export function parseName(name: string) {
  return parseFullName.parse(name);
}

/**
 * Adds decimals to string
 * @returns A string
 *
 */
export function addDecimal(
  value: string,
  decimal: number = 2,
  maxNum?: number,
) {
  if (!value) {
    return '';
  }
  if (Number.isNaN(Number(value))) {
    return 'Number is invalid';
  }
  if (maxNum && Number(value) > maxNum) {
    return 'Max value is ' + maxNum;
  }
  return Number(value).toFixed(decimal).toString();
}

/**
 * Remove all characters which is not a number
 * @returns A string with numbers
 *
 */
export function onlyDigits(value: string, maxLength?: number) {
  const parsedValue = value.replace(/[^0-9]/g, '');
  if (maxLength) {
    return parsedValue.slice(0, maxLength);
  }
  return parsedValue;
}

/**
 * Remove characters $ and , and %
 * @returns A string without following characters $ and , and %
 *
 */
export function removeDollarSignCommaPercentage(value: string) {
  return value.replace(/[^0-9.]|,|%/g, '');
}

/**
 * Remove all end of line symbols. Optionally add string to replave with
 * @returns String
 *
 */
export function removeEndOfLine(value: string, replaceWith: string = '') {
  return value.replace(/[\r\n]/gm, replaceWith);
}

/**
 * Format date
 * @returns A string with numbers
 *
 */
export function transformDate(
  value: string,
  dateFormat: string = 'YYYY-MM-DD',
) {
  return dayjs(new Date(value)).format(dateFormat);
}

/**
 * "Get the current UTC date and format it as a string."
 *
 * @returns A string in the format of YYYY-MM-DDTHH:mm:ssZ
 */
export function getCurrentUTCDate() {
  return dayjs.utc().format();
}

/**
 * Finds state in provided string and return state name and abbreviation
 * @returns An object with state name and abbreviation
 *
 */
export function findStateInString(value: string) {
  const stateObj = allStates.find((state) =>
    value.toLocaleLowerCase().includes(state.name.toLocaleLowerCase()),
  );
  return (
    stateObj || {
      name: 'State not found',
      abbreviation: 'State not found',
    }
  );
}
