import { docType, docState, docSortField, docSortDirection } from '.';

export interface BaseItemObjectApiModel {
  active: boolean;
  code: string;
  description: string;
  name: string;
  uuid: string;
}

export interface ParamsDocBrowse extends BaseOpenAPIParams {
  /**
   * Number of items to skip before returning the results.
   */
  page?: number;

  /**
   * Type of the document
   */
  type?: docType;

  /**
   * State of the document, eg signed, unsigned
   */
  state?: docState;
}

export interface BaseOpenAPIParams {
  /**
   * Keyword to search
   */
  q?: string;

  /**
   * Number of items to skip before returning the results.
   */
  offset?: number;

  /**
   * Maximum number of items to return.
   */
  limit?: number;

  /**
   * Results sort order field
   */
  'sort-field'?: docSortField | string;

  /**
   * Results sort order direction
   */
  'sort-direction'?: docSortDirection | string;
}
