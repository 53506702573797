import {
  Component,
  ContentChild,
  ElementRef,
  OnDestroy,
  AfterContentInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { SelectInputComponent } from '../select-input/select-input.component';

@Component({
  selector: 'et-atoms-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFieldComponent implements OnDestroy, AfterContentInit {
  labelClickSub!: Subscription;

  @ContentChild(SelectInputComponent, { read: ElementRef })
  selectComponent!: ElementRef;

  constructor(private elementRef: ElementRef) {}

  ngAfterContentInit(): void {
    const label = this.elementRef.nativeElement.querySelector('label');

    if (!label) {
      console.error(
        '<label></label> is required inside <et-atoms-select-field></et-atoms-select-field> component',
      );
      return;
    }
    // Open menu when label is clicked
    const label$ = fromEvent(label, 'click');
    this.labelClickSub = label$.subscribe(() => {
      const selectElement =
        this.selectComponent?.nativeElement.querySelector('[cdkoverlayorigin]');
      selectElement.click();

      // Focus select when clicked on label
      this.selectComponent.nativeElement?.focus();
    });
  }

  ngOnDestroy(): void {
    this.labelClickSub?.unsubscribe();
  }
}
