import { Injectable } from '@angular/core';
import { PacketListItem } from '@et/typings';
import { convertFileToDataURLAsync, getFileFromUrl } from '@et/utils';
import { StorageService } from '../storage.service';

interface PacketListItemWithUserId extends PacketListItem {
  userId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PacketRepositoryService {
  protected _tableName = 'packets';

  constructor(private storage: StorageService) {}

  /**
   * This function sets initial dummy value in the table
   */
  async addDummy() {
    try {
      await this.setTable();
      await this.storage.setItem('0', '1');
    } catch (error) {
      console.error('PacketRepositoryService: ', error);
      return;
    }
  }

  /**
   * This function return all values from storage
   * @param {string} userId - user id
   * @returns {PacketListItem[]} - PacketListItem[]
   */
  async getAll(userId: string): Promise<PacketListItem[]> {
    try {
      await this.setTable();
      const packetsJSONArrWithDummy = await this.storage.getAllValues();
      // Remove dummy
      const packetsJSONArr = packetsJSONArrWithDummy.filter((p) => p !== '1');
      const packets: PacketListItem[] = [];
      for (const packetsJSON of packetsJSONArr) {
        const packet = JSON.parse(packetsJSON) as PacketListItemWithUserId;
        if (packet.userId !== userId) {
          continue;
        }
        const fileUrl = packet.file as unknown as string;
        const file = await getFileFromUrl(fileUrl, 'signed.pdf');
        packet.file = file;
        delete packet.userId;
        packets.push(packet);
      }
      return packets;
    } catch (error) {
      console.error('PacketRepositoryService: ', error);
      return [];
    }
  }

  /**
   * This function gets PacketListItem by id
   * @param {string} id - packet id
   */
  async get(id: string): Promise<PacketListItem | null> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return null;
      }
      const packetJSON = await this.storage.getItem(id);
      const packet = JSON.parse(packetJSON) as PacketListItemWithUserId;
      const fileUrl = packet.file as unknown as string;
      const file = await getFileFromUrl(fileUrl, 'signed.pdf');
      packet.file = file;
      delete packet.userId;
      return packet;
    } catch (error) {
      console.error('PacketRepositoryService: ', error);
      return null;
    }
  }

  /**
   * This function adds new packet to storage
   * @param {string} id - Packet id
   * @param {PacketListItem} packet - PacketListItem
   */
  async createOrUpdate(
    id: string,
    userId: string,
    packet: PacketListItem,
  ): Promise<void> {
    try {
      await this.setTable();
      const file = await convertFileToDataURLAsync(packet.file as File);
      const packetJSON = JSON.stringify({
        ...packet,
        file,
        userId,
        downloaded: true,
      });
      await this.storage.setItem(id, packetJSON);
    } catch (error) {
      console.error('PacketRepositoryService: ', error);
    }
  }

  /**
   * This function deletes packet in storage
   * @param {string} id - Packet id
   */
  async delete(id: string): Promise<void> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return;
      }
      await this.storage.removeItem(id);
    } catch (error) {
      console.error('PacketRepositoryService: ', error);
    }
  }

  /**
   * This function deletes all keys
   */
  async clear(): Promise<void> {
    try {
      await this.setTable();
      await this.storage.clear();
      await this.addDummy();
    } catch (error) {
      console.error('PacketRepositoryService: ', error);
    }
  }

  /**
   * This function sets storage table
   */
  async setTable() {
    await this.storage.setTable(this._tableName);
  }
}
