import {
  Component,
  Input,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'et-atoms-native-nav-icon',
  templateUrl: './native-nav-icon.component.html',
  styleUrls: ['./native-nav-icon.component.scss'],
})
export class NativeNavIconComponent implements AfterViewInit {
  _isActive = false;
  _isDisabled = false;
  pathArrayEl!: NodeListOf<SVGPathElement>;
  @Input() set isActive(isActive: boolean) {
    this._isActive = isActive;
    this.updateClasses();
  }
  @Input() name!: string;
  @Input() set disabled(disabled: boolean) {
    this._isDisabled = disabled;
    const classes = disabled
      ? this.disableClasses
      : this._isActive
      ? this.activeClasses
      : this.classes;
    const removeClasses = `${this.disableClasses} ${this.activeClasses} ${this.classes}`;
    this.addClassesToElements(this.pathArrayEl, classes, removeClasses);
  }

  private readonly classes = '!fill-midnight';
  private readonly activeClasses = '!fill-white';
  private readonly disableClasses = '!fill-neutral-secondary';

  @ViewChild('svgContainer') svgContainer!: ElementRef<HTMLElement>;

  ngAfterViewInit(): void {
    // Add classes to paths elements
    this.pathArrayEl =
      this.svgContainer?.nativeElement?.querySelectorAll('path');
    this.updateClasses();
  }

  /**
   * This function adds and removes classes to HTML elements
   *
   */
  private updateClasses() {
    if (!this.pathArrayEl) {
      return;
    }
    const classes = this._isActive ? this.activeClasses : this.classes;
    const removeClasses = `${this.disableClasses} ${this.activeClasses} ${this.classes}`;
    this.addClassesToElements(this.pathArrayEl, classes, removeClasses);
  }

  /**
   * This function takes a list of HTML elemts and adds / removes provided classes to them
   * @param {NodeListOf<SVGSVGElement | SVGPathElement>} list - NodeListOf<SVGSVGElement | SVGPathElement>
   * @param {string} classesAdd - add list of classes
   * @param {string} classesRemove - remove list of classes
   *
   */
  private addClassesToElements(
    list: NodeListOf<SVGSVGElement | SVGPathElement>,
    classesAdd: string,
    classesRemove?: string,
  ) {
    if (!list) {
      return;
    }
    list.forEach((el) => {
      if (classesRemove) {
        el.classList.remove(...classesRemove.split(' '));
      }
      el.classList.add(...classesAdd.split(' '));
    });
  }
}
