<!-- Displayed icon -->
<div (mouseenter)="onOpen()" #trigger="cdkOverlayOrigin" cdkOverlayOrigin>
  <ng-container
    *ngTemplateOutlet="displayTemplate || defaultTemplate"
  ></ng-container>
</div>

<ng-template #defaultTemplate>
  <img
    src="assets/icons/question-filled.svg"
    alt="Question"
    width="16"
    height="16"
    class="m-0"
  />
</ng-template>

<!-- Hover popup -->
<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayOffsetX]="offsetX"
  [cdkConnectedOverlayOffsetY]="offsetY"
  [cdkConnectedOverlayPositions]="positions"
>
  <div
    class="relative py-2 pl-10 pr-4 bg-midnight w-fit rounded-xl card"
    [ngStyle]="{
      width: width ? width + 'px' : '375px',
      maxWidth: width ? width + 'px' : '375px'
    }"
    (mouseleave)="onClose()"
  >
    <img
      [src]="iconSrc"
      width="16"
      height="16"
      alt="Question"
      class="absolute m-0 top-[10px] left-[10px]"
    />
    <p class="m-0 text-xs font-medium leading-5 text-white">
      <ng-content></ng-content>
    </p>
  </div>
</ng-template>
