<!-- Clicker container -->
<div
  class="flex items-center justify-between h-10 px-4 bg-base-200 rounded-full w-[136px] lg:w-[172px] transition-colors relative"
  [ngClass]="{ '!bg-midnight !text-white': isNavOpen }"
>
  <!-- Left arrow -->
  <span
    id="arrow-left"
    class="!border-t-[10px] !border-b-[10px] !border-r-[11px] cursor-pointer"
  ></span>

  <p *ngIf="totalPages" class="flex gap-2 text-sm font-bold">
    <span>{{ currentPage }}</span
    ><span>/</span><span>{{ totalPages }}</span>
  </p>

  <!-- Right arrow -->
  <span
    id="arrow-right"
    class="!border-t-[10px] !border-b-[10px] !border-l-[11px] cursor-pointer"
  ></span>

  <!-- Clicking area -->
  <div class="absolute flex w-[136px] lg:w-[172px] left-0">
    <!-- Previous page -->
    <div
      (click)="onPreviousPage()"
      class="flex-1 h-10 outline-none cursor-pointer"
      tabindex="0"
    >
      &nbsp;
    </div>
    <!-- Navigation -->
    <div
      *ngIf="!disableNav"
      (click)="openNav()"
      class="flex-1 w-16 h-10 outline-none cursor-pointer"
      tabindex="0"
    >
      &nbsp;
    </div>
    <!-- Next page -->
    <div
      (click)="onNextPage()"
      class="flex-1 h-10 outline-none cursor-pointer"
      tabindex="0"
    >
      &nbsp;
    </div>
  </div>
</div>

<et-atoms-native-popup
  #advanceNavigation
  [popupTemplate]="navigationTemplate"
  [offsetX]="0"
  [offsetY]="10"
  [disabled]="true"
  [positions]="popupPosition"
  [flexibleDimensions]="true"
  [growAfterOpen]="true"
  (menuClose)="closeNav()"
></et-atoms-native-popup>

<!-- Navigation template -->
<ng-template #navigationTemplate>
  <et-atoms-native-advanced-navigation
    [style.width.px]="navigationWidth"
    [totalPages]="totalPages"
    [currentPage]="currentPage"
    [flaggedPages]="flaggedPages"
    (closeNav)="closeNav()"
    (changePage)="onChangePage($event)"
  ></et-atoms-native-advanced-navigation>
</ng-template>
