import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DocumentMarkupPage } from '@et/typings';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  Subscription,
  tap,
} from 'rxjs';
import { NavigationPDFService } from './navigation-pdf.service';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'et-atoms-native-advanced-navigation',
  templateUrl: './native-advanced-navigation.component.html',
  styleUrls: ['./native-advanced-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NavigationPDFService],
  animations: [
    trigger('pagePreviewIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-2px)' }),
        animate(
          '150ms ease-in',
          style({ opacity: 1, transform: 'translateY(0)' }),
        ),
      ]),
    ]),
  ],
})
export class NativeAdvancedNavigationComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private cdr = inject(ChangeDetectorRef);
  private pdfService = inject(NavigationPDFService);

  rangeCtrlSub!: Subscription;
  rangeCtrl = new FormControl<number | undefined>(undefined);
  pageNumLeft = '0';
  sliderWidth = 0;
  page: number | undefined;
  pagePreview: string | undefined;
  pagePreviewOffset = '0';
  readonly thumbWidth = 24;
  readonly pagePreviewWidth = 132;
  readonly pagePreviewHeight = 170;

  @Input() totalPages!: number | undefined;
  @Input() flaggedPages: DocumentMarkupPage[] | null = [];
  @Input() set currentPage(page: number | undefined) {
    this.page = page;
    this.rangeCtrl.setValue(page);
  }

  /**
   * If the file is passed to the component,
   * if will be used to display page previews
   */
  @Input() file: File | string | undefined = undefined;

  /**
   * This input property is used to hide the images of required pages
   * in the navigation component
   * @type {boolean}
   * @default false
   */
  @Input() hideImages: boolean = false;
  @Input() bodyText =
    'Slide to a specific page or navigate to notarized pages.';
  @Output() closeNav = new EventEmitter<void>();
  @Output() changePage = new EventEmitter<number>();

  @ViewChild('slider') slider!: ElementRef<HTMLInputElement>;

  ngAfterViewInit(): void {
    // Set slider width
    this.sliderWidth = this.slider.nativeElement.getBoundingClientRect().width;
    // Set page number position
    if (this.page) {
      this.pageNumLeft = this.calculateOffset(this.page, this.thumbWidth);
    }
    // Set page preview position
    if (this.page && this.file) {
      this.pagePreviewOffset = this.calculatePagePreviewOffset(
        this.page,
        this.pagePreviewWidth,
      );
    }
    this.cdr.markForCheck();
  }

  ngOnInit(): void {
    // Watch slider value changes
    this.rangeCtrlSub = this.rangeCtrl.valueChanges
      .pipe(
        distinctUntilChanged(),
        filter(Boolean),
        tap((page) => {
          this.page = page;
          this.pageNumLeft = this.calculateOffset(page, this.thumbWidth);
          this.pagePreviewOffset = this.calculatePagePreviewOffset(
            page,
            this.pagePreviewWidth,
          );
        }),
        debounceTime(75),
      )
      .subscribe((page) => {
        this.renderPreviewPage(page);
      });

    // Init PDF service
    this.initPdfService();
  }

  /**
   * This function initializes the PDF service with the provided file and renders the preview page.
   * If a file is provided, it initializes the PDF service with the file and the page preview width.
   * After the PDF service is initialized, it renders the preview page.
   * If no page number is provided, it defaults to rendering the first page.
   */
  private async initPdfService() {
    if (this.file) {
      await this.pdfService.init(this.file, this.pagePreviewWidth);
      await this.renderPreviewPage(this.page || 1);
    }
  }

  /**
   * This function renders the specified page of the PDF document.
   * If a file is loaded, it loads the specified page from the PDF service
   * and sets it as the page preview.
   *
   * @param {number} page - The number of the page to render.
   */
  async renderPreviewPage(page: number) {
    if (this.file) {
      this.pagePreview = await this.pdfService.loadPage(page);
      this.cdr.markForCheck();
    }
  }

  /**
   * This function emits closeNav event to parnt component
   * @memberof NativeAdvancedNavigationComponent
   */
  onClose() {
    this.closeNav.emit();
  }

  /**
   * This function gets the current page number and emits it via changePage event
   * @memberof NativeAdvancedNavigationComponent
   */
  pageChanged() {
    const page = this.rangeCtrl.value as number;
    this.changePage.emit(page);
  }

  /**
   * The function returns an image based on the presence of a notarization stamp and a required
   * signature in a document markup page.
   * @param {DocumentMarkupPage} p - The parameter `p` is of type `DocumentMarkupPage`, which represents a page in a document that can be marked up with stamps and signatures. The function
   * `getImage` takes this page as input and returns an image file path based on whether the page has a
   * notarization stamp, a
   * @returns a string that represents the path to an image file. The image file path depends on the
   * presence of a notarization stamp and a required signature in the given DocumentMarkupPage object.
   * If both are present, the function returns the path to an image of an open lock. If only a
   * signature is present, the function returns the path to an image of a gray pen.
   */
  getImage(p: DocumentMarkupPage) {
    const stamp = p.notarize;
    const signature = p.tabs.find((d) => d.required);
    if (stamp && signature) {
      return 'assets/icons/stamp-signature.svg';
    } else if (signature) {
      return 'assets/icons/pen-gray.svg';
    }
    return 'assets/icons/stamp-green.svg';
  }

  /**
   * This function calculates the offset for the specified page and object width.
   * It first checks if the total number of pages and the slider are defined.
   * If not, it returns an empty string.
   * Otherwise, it calculates the of the input thumb
   *
   * @param {number} page - The number of the page.
   * @param {number} objectWidth - The width of the object.
   * @returns {string} The calculated offset in pixels.
   */
  calculateOffset(page: number, objectWidth: number): string {
    if (!this.totalPages || !this.slider) {
      return '';
    }
    const position = ((page - 1) / (this.totalPages - 1)) * this.sliderWidth;
    const offset = (page - 1) / (this.totalPages - 1);
    const offsetPosition = offset * objectWidth;
    return position - offsetPosition + 'px';
  }

  /**
   * This function calculates the page preview left margin for the specified page and preview width.
   * It first checks if the total number of pages and the slider are defined.
   * If not, it returns '0'.
   * Otherwise, it calculates the offset and position based on the page number,
   * total number of pages, slider width, and thumb width.
   * It adjusts left and right position based on the preview width and slider width.
   * Finally, it returns the margin left in pixels.
   *
   * @param {number} page - The number of the page.
   * @param {number} previewWidth - The width of the preview.
   * @returns {string} The calculated page preview offset in pixels.
   */
  private calculatePagePreviewOffset(
    page: number,
    previewWidth: number,
  ): string {
    if (!this.totalPages || !this.slider) {
      return '0';
    }
    const offset = (page - 1) / (this.totalPages - 1);
    const position = offset * this.sliderWidth;
    const offsetPosition = offset * this.thumbWidth;
    let marginLeft =
      position - offsetPosition - previewWidth / 2 + this.thumbWidth / 2;
    // Adjust left position
    if (marginLeft < 0) marginLeft = 0;
    // Adjust right position
    if (marginLeft + previewWidth > this.sliderWidth) {
      marginLeft = this.sliderWidth - previewWidth;
    }
    return marginLeft + 'px';
  }

  ngOnDestroy(): void {
    this.rangeCtrlSub?.unsubscribe();
  }
}
