import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MobileInaccessibleGuard {
  constructor(
    private deviceService: DeviceDetectorService,
    private router: Router,
  ) {}
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.deviceService.isMobile()) {
      return this.router.parseUrl('/error/mobile');
    } else {
      return true;
    }
  }
}
