import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@et/environment';
import { CompanyData, CompanyResponse } from '@et/typings';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  private http = inject(HttpClient);

  /**
   * Retrieves company details by company ID.
   *
   * @param {string} companyId - The unique identifier of the company to retrieve.
   * @returns {Observable<CompanyData>} An Observable containing the response with the company's details.
   */
  getCompanyById(companyId: string): Observable<CompanyData> {
    return this.http
      .get<CompanyResponse>(
        environment.profilev2.domain + '/api/Companies/' + companyId,
      )
      .pipe(map((res) => res.data));
  }
}
