import { SigningSession, SigningInfo, EtJournalFlowType } from '@et/typings';

export enum EtJournalStateActionsType {
  FETCH_ET_JOURNAL_DOCS = '[ET JOURNAL] fetch unsigned docs from api',
  FETCH_ET_JOURNAL_DOCS_SUCCESS = '[ET JOURNAL] add unsigned docs to the store',
  FETCH_ET_JOURNAL_DOCS_ERROR = '[ET JOURNAL] add unsigned docs error to the store',
  SELECT_SIGNING_SESSION = '[ET JOURNAL] select selected signing session',
  SET_SIGNING_INFO = '[ET JOURNAL] set signing info',
  UPDATE_SIGNING_INFO = '[ET JOURNAL] update signing info',
  SET_VERIFICATION_ERROR = '[ET JOURNAL] set verification error',
  CLEAR_STORE = '[ET JOURNAL] clear store',
  SET_ET_JOURNAL_FLOW = '[ET JOURNAL] set et journal flow type',
}

export class FetchEtJournalDocuments {
  static readonly type = EtJournalStateActionsType.FETCH_ET_JOURNAL_DOCS;
}

export class FetchEtJournalDocumentsSuccess {
  static readonly type =
    EtJournalStateActionsType.FETCH_ET_JOURNAL_DOCS_SUCCESS;
  constructor(public documents: SigningSession[]) {}
}

export class FetchEtJournalDocumentsError {
  static readonly type = EtJournalStateActionsType.FETCH_ET_JOURNAL_DOCS_ERROR;
  constructor(public error: string) {}
}

export class SelectSigningSession {
  static readonly type = EtJournalStateActionsType.SELECT_SIGNING_SESSION;
  constructor(public document: SigningSession) {}
}

export class SetSigningInfo {
  static readonly type = EtJournalStateActionsType.SET_SIGNING_INFO;
  constructor(public signingInfo: SigningInfo | undefined) {}
}

export class UpdateSigningInfo {
  static readonly type = EtJournalStateActionsType.UPDATE_SIGNING_INFO;
  constructor(public signingInfo: Partial<SigningInfo>) {}
}

export class SetVerificationError {
  static readonly type = EtJournalStateActionsType.SET_VERIFICATION_ERROR;
  constructor(public error: string) {}
}

export class ClearEtJournalStore {
  static readonly type = EtJournalStateActionsType.CLEAR_STORE;
}

export class SetEtJournalFlow {
  static readonly type = EtJournalStateActionsType.SET_ET_JOURNAL_FLOW;
  constructor(public flowType: EtJournalFlowType) {}
}
