import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'et-atoms-tile-tab',
  templateUrl: './tile-tab.component.html',
  styleUrls: ['./tile-tab.component.scss'],
})
export class TileTabComponent {
  @Input() titleTemplate!: TemplateRef<any>;
  @Input() title: string | null | undefined;
  @Input() selected = false;
}
