import {
  ChangeDetectionStrategy,
  Component,
  ChangeDetectorRef,
} from '@angular/core';
import { LogoutModalComponent } from '../logout-modal/logout-modal.component';
import { take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'et-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent {
  isModalOpen = false;

  constructor(
    private dialog: MatDialog,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  /**
   * Opens logout modal or closes if it is already open
   * @returns None
   * @memberof NavbarComponent
   */
  onLogout() {
    if (this.isModalOpen) {
      this.dialog.closeAll();
      return;
    }
    this.isModalOpen = true;
    const dialog = this.dialog.open(LogoutModalComponent, {
      backdropClass: 'bg-midnight',
      minWidth: 342,
      position: { top: '150px' },
      panelClass: ['et-dialog-radius', 'et-dialog-padding'],
      disableClose: true,
    });

    dialog
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.isModalOpen = false;
        this.changeDetectorRef.markForCheck();
      });
  }
}
