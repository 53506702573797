import { DocumentFileGetAPIResponseData } from '@et/api';

export interface ENote {
  loanNumber: string;
  min: string;
  closingDate: string;
  closingCity: string;
  closingState: string;
  propertyAddressFull: string;
  propertyHouse: string;
  propertyStreetName: string;
  propertyStreetSuffix: string;
  propertyCity: string;
  propertyState: string;
  propertyZipcode: string;
  propertyCounty: string;
  loanAmount: string;
  lenderName: string;
  interestRate: string;
  remittanceDay: string;
  firstPayment: string;
  maturationDate: string;
  payToAddress: string;
  payToCity: string;
  payToState: string;
  payToZipcode: string;
  paymentAmount: string;
  latePeriod: string;
  lateCharge: string;
  bor1First: string;
  bor1Middle: string;
  bor1Last: string;
  bor1suffix: string;
  bor1SSN: string;
  bor1Type: string;
  bor2First?: string;
  bor2Middle?: string;
  bor2Last?: string;
  bor2suffix?: string;
  bor2SSN?: string;
  bor2type?: string;
  bor3First?: string;
  bor3Middle?: string;
  bor3Last?: string;
  bor3suffix?: string;
  bor3SSN?: string;
  bor3type?: string;
  bor4First?: string;
  bor4Middle?: string;
  bor4Last?: string;
  bor4suffix?: string;
  bor4SSN?: string;
  bor4type?: string;
  lender: string;
  lenderNMLS: string;
  originator: string;
  originatorNMLS: string;
  propertyType: string;
  lienPosition: string;
  customRegistryOperatorName: string;
  loanClosingLenderContactPointType: string;
  loanClosingLenderContactPointTypeOtherDesc: string;
}

export interface ENoteAPIBody {
  people: Person[];
  places: Place[];
  loan: Loan;
  'loan-terms': LoanTerms;
  'loan-closing': LoanClosing;
  custom: Custom;
  mers: Mers;
}

export interface ENoteAPIResponse {
  code: number;
  msg: string;
  data: Data;
}

interface Data {
  status: string;
  enote: EnotePDF;
  'document-id': string;
}

export interface EnotePDF {
  people: Person[];
  places: Place[];
  loan: Loan;
  'loan-terms': LoanTerms;
  'loan-closing': LoanClosing;
  custom: Custom;
  mers: Mers;
}

interface Person {
  'name-suffix': string;
  'name-first': string;
  'name-middle': string;
  'name-last': string;
  ssn: string;
  type: string;
}

interface Place {
  'place-city': string;
  'place-county': string;
  'place-postal-code': string;
  'place-state': string;
  'place-street-address': string;
  'place-house-number': string;
  'place-street-name': string;
  'place-street-suffix': string;
  'place-type': string;
}

interface Loan {
  'loan-lien-priority-type': string;
  'loan-maturity-date': string;
  'loan-original-principal-and-interest-payment-amount': string;
  'loan-scheduled-first-payment-date': string;
  'loan-late-charge-grace-period': string;
  'loan-late-charge-rate': string;
  'loan-pay-to-city': string;
  'loan-pay-to-postal-code': string;
  'loan-pay-to-state': string;
  'loan-pay-to-street-address': string;
}

interface LoanTerms {
  'loan-terms-lender-loan-identifier': string;
  'loan-terms-note-rate-percent': string;
  'loan-terms-original-loan-amount': string;
  'loan-terms-payment-remittance-day': string;
}

interface LoanClosing {
  'loan-closing-execution-city': string;
  'loan-closing-execution-date': string;
  'loan-closing-execution-state': string;
  'loan-closing-lender-unparsed-name': string;
  'loan-closing-lender-contact-name': string;
  'loan-closing-lender-contact-point-type': string;
  'loan-closing-lender-contact-point-type-other-desc': string;
  'loan-closing-lender-contact-point-value': string;
}

interface Custom {
  'custom-registry-operator-name': string;
  'custom-loan-originator-id': string;
  'custom-loan-originator-unparsed-name': string;
}

interface Mers {
  min: string;
}

export enum eNoteKeysEnum {
  'loanNumber' = 'loanNumber',
  'min' = 'min',
  'closingDate' = 'closingDate',
  'closingCity' = 'closingCity',
  'closingState' = 'closingState',
  'propertyAddressFull' = 'propertyAddressFull',
  'propertyHouse' = 'propertyHouse',
  'propertyStreetName' = 'propertyStreetName',
  'propertyStreetSuffix' = 'propertyStreetSuffix',
  'propertyCity' = 'propertyCity',
  'propertyState' = 'propertyState',
  'propertyZipcode' = 'propertyZipcode',
  'propertyCounty' = 'propertyCounty',
  'loanAmount' = 'loanAmount',
  'lenderName' = 'lenderName',
  'interestRate' = 'interestRate',
  'remittanceDay' = 'remittanceDay',
  'firstPayment' = 'firstPayment',
  'maturationDate' = 'maturationDate',
  'payToAddress' = 'payToAddress',
  'payToCity' = 'payToCity',
  'payToState' = 'payToState',
  'payToZipcode' = 'payToZipcode',
  'paymentAmount' = 'paymentAmount',
  'latePeriod' = 'latePeriod',
  'lateCharge' = 'lateCharge',
  'bor1First' = 'bor1First',
  'bor1Middle' = 'bor1Middle',
  'bor1Last' = 'bor1Last',
  'bor1SSN' = 'bor1SSN',
  'bor1Type' = 'bor1Type',
  'bor2First' = 'bor2First',
  'bor2Middle' = 'bor2Middle',
  'bor2Last' = 'bor2Last',
  'bor2SSN' = 'bor2SSN',
  'bor2type' = 'bor2type',
  'bor3First' = 'bor3First',
  'bor3Middle' = 'bor3Middle',
  'bor3Last' = 'bor3Last',
  'bor3SSN' = 'bor3SSN',
  'bor3type' = 'bor3type',
  'bor4First' = 'bor4First',
  'bor4Middle' = 'bor4Middle',
  'bor4Last' = 'bor4Last',
  'bor4SSN' = 'bor4SSN',
  'bor4type' = 'bor4type',
  'lender' = 'lender',
  'lenderNMLS' = 'lenderNMLS',
  'originator' = 'originator',
  'originatorNMLS' = 'originatorNMLS',
  'propertyType' = 'propertyType',
  'lienPosition' = 'lienPosition',
  'customRegistryOperatorName' = 'customRegistryOperatorName',
  'loanClosingLenderContactPointType' = 'loanClosingLenderContactPointType',
  'loanClosingLenderContactPointTypeOtherDesc' = 'loanClosingLenderContactPointTypeOtherDesc',
}

export interface ENoteFormField {
  key: eNoteKeysEnum;
  name: string;
  canParse?: boolean;
  disabled?: boolean;
}

export enum MismoTemplateEnote {
  'mismo-smartdoc-v1-02-3200e-fixed' = 'mismo-smartdoc-v1-02-3200e-fixed',
  'mismo-smartdoc-v1-02-3210e-fixed' = 'mismo-smartdoc-v1-02-3210e-fixed',
  'mismo-smartdoc-v1-02-3200e-fixed-multistate-07-2021' = 'mismo-smartdoc-v1-02-3200e-fixed-multistate-07-2021',
  'mismo-smartdoc-v1-02-3210e-fixed-florida-07-2021' = 'mismo-smartdoc-v1-02-3210e-fixed-florida-07-2021',
}

export interface ENoneInfoForm {
  name: string;
  signors: number;
  eNoteFormat: MismoTemplateEnote;
}

export interface ENoneDocForm {
  signingAgent: string;
}

export interface ENoteProxyResponse {
  msg: string;
  code: string;
  info: ENoteProxyResponseInfo;
  data: ENoteProxyResponseData;
}

interface ENoteProxyResponseInfo {
  version: string;
  action: string;
  path: string;
  format: string;
  'route-val': string;
  'route-detail-val': string;
}

export interface DocumentContentData {
  'data-type': string;
  code: string;
  description: string;
  display: string;
  id: string;
  url: string;
}

export interface DataView {
  id: string;
  'agent-id': string;
  'originator-id': string;
  display: string;
  'file-name': string;
  'file-content-type': string;
  pin: string;
  'is-template-mismo': boolean;
  'is-template-mismo-smart-doc': boolean;
  template: string;
  unsigned: string;
  'unsigned-encoded': string;
  'unsigned-fileinfo': string;
  'unsigned-fileinfo-encoded': string;
  view: string;
  'view-encoded': string;
  'view-fileinfo': string;
  'view-fileinfo-encoded': string;
  signed: string;
  'signed-encoded': string;
  'signed-fileinfo': string;
  'signed-fileinfo-encoded': string;
  'is-signed': boolean;
  'template-encoded': string;
  'template-fileinfo': string;
  'template-fileinfo-encoded': string;
  'document-content-data': DocumentContentData[];
  'has-document-content-data': boolean;
  'is-template': boolean;
  'is-edit': boolean;
  'is-view': boolean;
  'viewer-url': string;
}

export interface EnoteViews {
  msg: string;
  code: string;
  info: ENoteProxyResponseInfo;
  data: DataView;
}

interface ENoteProxyResponseData {
  'originator-id': string;
  'agent-id': string;
  'is-signed': boolean;
  'page-count': number;
  'file-size': number;
  'access-pin': string;
  'file-type': string;
  'original-file-name': string;
  'file-name': string;
  'file-path': string;
  'screenshot-path': string;
  'original-document-id': string;
  display: string;
  id: string;
  'utc-date-created': string;
  'utc-date-modified': string;
  code: string;
  active: boolean;
}

export interface v1Enote extends DocumentFileGetAPIResponseData {
  thumbnail: string;
  file: File;
  totalPages: number;
}

export interface SignedEnote {
  pdfFile: File;
  metadataFile: File;
}

export interface eNotePacketBody {
  eNoteId?: string;
  eNoteDataId?: string;
  submittingPartyId: string;
  requestingPartyId: string;
  receivingPartyId: string;
  controllerId: string;
  locationId: string;
  masterServicerId: string;
  delegateeForTransferId: string;
}

export interface ENoteSignature {
  strokes: string[];
  width: number;
  height: number;
  page: number;
}

export interface ENoteFieldRect {
  left: number;
  top: number;
  width: number;
  height: number;
}
