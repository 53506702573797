import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { ProfileState } from '@et/guards';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppApiModule } from './app-api.module';
import { HomeComponent } from './components/home/home.component';
import { environment } from '@et/environment';
import { AuthModule } from '@et/auth';
import { MSALProviders } from './add-config';
import { ReactiveFormsModule } from '@angular/forms';
import { PresentationalAtomsModule } from '@et/presentational/atoms';
import { NavbarComponent } from './components/navbar/navbar.component';
import { LogoutModalComponent } from './components/logout-modal/logout-modal.component';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { NgxsModule } from '@ngxs/store';
import { EtJournalState } from '@et/et-journal/state';
import { NotificationsModule } from '@et/notifications';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NavbarComponent,
    LogoutModalComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule,
    PresentationalAtomsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NotificationsModule,
    AppApiModule,
    NgxsModule.forRoot([ProfileState, EtJournalState]),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    ...MSALProviders,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
