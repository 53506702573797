export interface AdminCompanies {
  companyId: string;
  companyType: string;
  emailDomain: string;
  isActive: boolean;
  elsiEnabled: boolean;
  enoteEnabled: boolean;
  iDverify: boolean;
  autoTagOnboarding: boolean;
  name: string;
  hqState: string;
}
export interface AdminCompaniesTypes {
  companyTypeId: number;
  name: string;
  description: string;
}
