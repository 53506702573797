import {
  notaryMarkerFill,
  defaultMarkerFill,
  signorOneMarkerFill,
  signorTwoMarkerFill,
  signorThreeMarkerFill,
  signorFourMarkerFill,
} from './../typings/constants/native.constants';

/**
 * It returns a color based on the type of user
 * @param {string} type - string - the type of user that is signing the document.
 * @returns The color of the marker.
 */
export const getMarkingFillByUserType = (
  type: string,
  required: boolean,
): string => {
  let color = required ? signorOneMarkerFill : defaultMarkerFill;
  switch (type) {
    case 'notary1':
      color = notaryMarkerFill;
      break;
    case 'buyer1':
    case 'signor1':
      color = signorOneMarkerFill;
      break;
    case 'buyer2':
    case 'signor2':
      color = signorTwoMarkerFill;
      break;
    case 'buyer3':
    case 'signor3':
      color = signorThreeMarkerFill;
      break;
    case 'buyer4':
    case 'signor4':
      color = signorFourMarkerFill;
      break;
    case 'seller1':
      color = signorOneMarkerFill;
      break;
    case 'seller2':
      color = signorTwoMarkerFill;
      break;
    case 'seller3':
      color = signorThreeMarkerFill;
      break;
    case 'seller4':
      color = signorFourMarkerFill;
      break;
  }
  return color;
};
