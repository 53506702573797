import { NgModule } from '@angular/core';
import { environment } from '@et/environment';
import {
  v2ProfileApiModule,
  Configuration,
  ConfigurationParameters,
} from '@escrowtab/v2profile-api-client';
import {
  packetsApiModule,
  Configuration as PacketsConfiguration,
  ConfigurationParameters as PacketsConfigurationParameters,
} from '@escrowtab/packets-api-client';

function profileConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.profilev2.domain,
  };
  return new Configuration(params);
}

function packetsConfigFactory(): PacketsConfiguration {
  const params: PacketsConfigurationParameters = {
    basePath: environment.packetService.domain,
  };
  return new PacketsConfiguration(params);
}

@NgModule({
  imports: [
    v2ProfileApiModule.forRoot(profileConfigFactory),
    packetsApiModule.forRoot(packetsConfigFactory),
  ],
  exports: [v2ProfileApiModule],
})
export class AppApiModule {}
