@switch (alert?.type) { @case ('error') {
<!-- Error message -->
<ng-container *ngTemplateOutlet="errorTemplate"></ng-container>
} @case ('success') {
<!-- Success message -->
<ng-container *ngTemplateOutlet="successTemplate"></ng-container>
} }

<!-- Error notification -->
<ng-template #errorTemplate>
  <div
    class="min-w-[64px] bg-[#EA4848] flex items-center justify-center rounded-l-xl"
  >
    <i class="text-2xl icon-Info text-secondary-content"></i>
  </div>
  <div class="w-full">
    <button (click)="onClose()" class="absolute top-2 right-4">
      <ng-container *ngTemplateOutlet="closeIcon"></ng-container>
    </button>
    <p class="mt-6 ml-4 text-base font-bold">Error</p>
    <p class="mx-4 mb-2 text-xs font-medium whitespace-pre-line text-light">
      {{ alert.message }}
    </p>
  </div>
</ng-template>

<!-- Success notification -->
<ng-template #successTemplate>
  <div
    class="min-w-[64px] bg-base-200 flex items-center justify-center rounded-l-xl"
  >
    <img
      src="assets/icons/complete-outline.svg"
      class="w-8 h-8 my-0"
      alt="Complete"
    />
  </div>
  <div class="w-full">
    <button (click)="onClose()" class="absolute top-2 right-4">
      <ng-container *ngTemplateOutlet="closeIcon"></ng-container>
    </button>
    <p class="mt-6 ml-4 text-base font-bold">Success</p>
    <p class="mx-4 mb-2 text-xs font-medium whitespace-pre-line text-light">
      {{ alert.message }}
    </p>
  </div>
</ng-template>

<!-- Close icon -->
<ng-template #closeIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    class="w-4 h-4"
  >
    <path
      d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z"
    />
  </svg>
</ng-template>
