import { CdkOverlayOrigin, ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  OnDestroy,
  QueryList,
  inject,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { TileMenuItemComponent } from '../tile-menu-item/tile-menu-item.component';
import { menu } from './tile-menu.animation';

@Component({
  selector: 'et-atoms-tile-menu',
  template: `<ng-template
    cdkConnectedOverlay
    cdkConnectedOverlayHasBackdrop
    cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="isOpen"
    [cdkConnectedOverlayPositions]="menuPositions"
    [cdkConnectedOverlayOffsetY]="offsetY"
    [cdkConnectedOverlayOffsetX]="offsetX"
    (backdropClick)="isOpen = false"
  >
    <div
      [@menuAnimation]
      class="card dropdown w-full max-w-md shadow-lg py-3 bg-white rounded-lg overflow-hidden"
      [ngStyle]="{
        width: width,
        minWidth: minWidth,
        maxWidth: maxWidth
      }"
    >
      <ng-content></ng-content>
    </div>
  </ng-template>`,
  animations: [menu],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileMenuComponent implements AfterContentInit, OnDestroy {
  private cdr = inject(ChangeDetectorRef);
  @Input() trigger!: CdkOverlayOrigin;
  @Input() width = 'fit-content';
  @Input() maxWidth = '300px';
  @Input() minWidth = '200px';
  @Input() offsetY = 0;
  @Input() offsetX = 0;
  @Input() isOpen = false;
  @Input() closeOnClick = true;

  @ContentChildren(TileMenuItemComponent)
  menuItems!: QueryList<TileMenuItemComponent>;

  menuClickSub: Subscription | undefined;

  menuPositions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
    },
  ];

  ngAfterContentInit(): void {
    this.menuClickSub = this.menuItems.changes.subscribe(
      (items: QueryList<TileMenuItemComponent>) => {
        this.closeOnClicked(items);
      },
    );
    const menuItems = this.menuItems;
    if (!menuItems.length) return;
    this.closeOnClicked(menuItems);
  }

  /**
   * This function closes the menu when a menu item is clicked
   * It triggers from the `TileMenuItemComponent` component when a menu item is clicked
   * @param menuItems - The list of menu items
   */
  private closeOnClicked(menuItems: QueryList<TileMenuItemComponent>) {
    if (!this.closeOnClick) return;
    menuItems.forEach((item) =>
      item.clicked.subscribe(() => {
        this.isOpen = false;
        this.cdr.detectChanges();
      }),
    );
  }

  /**
   * This function toggles the menu open and close
   */
  toggle() {
    this.isOpen = !this.isOpen;
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.menuClickSub?.unsubscribe();
  }
}
