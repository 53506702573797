import { NavigationClient } from '@azure/msal-browser';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const cordova: any;

export class CustomNavigationAndroidClient extends NavigationClient {
  override async navigateExternal(url: string, options: any) {
    // Cortdova implementation
    // eslint-disable-next-line no-prototype-builtins
    if (window.hasOwnProperty('cordova')) {
      const ref = cordova.InAppBrowser.open(
        url,
        '_blank',
        'location=yes,clearcache=yes,clearsessioncache=yes',
      );

      // Check if the appbrowser started a navigation
      ref.addEventListener('loadstart', (event: any) => {
        // Check if the url contains the #state login parameter
        if (event.url.includes('#state')) {
          // Close the in app browser and redirect to localhost + the state parameter
          // msal-login is a fake route to trigger a page refresh
          ref.close();
          const domain = event.url.split('#')[0];
          const url = event.url.replace(domain, 'http://localhost/app');
          window.location.href = url;
        }
      });
    } else {
      if (options.noHistory) {
        window.location.replace(url);
      } else {
        window.location.assign(url);
      }
    }
    return true;
  }
}
