import { Injectable, signal } from '@angular/core';
import { MeasurementConfig } from '@cloudflare/speedtest';
import { SpeedTestEngine } from './speed-test.interface';

export interface SpeedTestResultsInterface {
  upload?: number;
  uploadMbps?: number;
  latency?: number;
  jitter?: number;
  upLoadedLatency?: number;
  upLoadedJitter?: number;
}

@Injectable({
  providedIn: 'root',
})
export class SpeedTestService {
  private measurmentConfig: MeasurementConfig[] = [
    { type: 'latency', numPackets: 1 },
    { type: 'latency', numPackets: 20 },
    { type: 'upload', bytes: 1e5, count: 8 },
    { type: 'upload', bytes: 1e6, count: 6 },
    { type: 'upload', bytes: 1e7, count: 4 },
    { type: 'upload', bytes: 2.5e7, count: 4 },
    { type: 'upload', bytes: 5e7, count: 3 },
  ];

  private speedTest!: SpeedTestEngine;

  private timer = 0;

  /**
   * The maximum number of speed tests to run when repeating the test.
   * Assumint 15 minutes interval, this will run for 4 hours.
   */
  private maxChecks = 16;

  /**
   * The current check number when repeating the speed test.
   */
  private currentCheck = 0;

  private _isRunning = signal<boolean>(false);
  isRunning = this._isRunning.asReadonly();

  private _results = signal<SpeedTestResultsInterface>({
    upload: undefined,
    uploadMbps: undefined,
    latency: undefined,
    jitter: undefined,
    upLoadedLatency: undefined,
    upLoadedJitter: undefined,
  });
  results = this._results.asReadonly();

  private _error = signal<any>(null);
  error = this._error.asReadonly();

  constructor() {
    this.init();
    // Handle results change
    this.speedTest.onResultsChange = () => {
      const summary = this.speedTest.results.getSummary();
      this._results.set({
        upload: summary.upload,
        uploadMbps: this.bytesToMbps(summary.upload),
        latency: summary.latency,
        jitter: summary.jitter,
        upLoadedLatency: summary.upLoadedLatency,
        upLoadedJitter: summary.upLoadedJitter,
      });
    };

    // Handle test start and stop
    this.speedTest.onRunningChange = (isRunning: boolean) => {
      this._isRunning.set(isRunning);
    };

    // Handle test completion
    this.speedTest.onFinish = (res) => {
      const summary = res.getSummary();
      this._results.set({
        upload: summary.upload,
        uploadMbps: this.bytesToMbps(summary.upload),
        latency: summary.latency,
        jitter: summary.jitter,
        upLoadedLatency: summary.upLoadedLatency,
        upLoadedJitter: summary.upLoadedJitter,
      });
    };

    // Handle errors
    this.speedTest.onError = (err) => {
      console.error(err);
      this._error.set(err);
    };
  }

  /**
   * Starts or restarts the speed test process.
   *
   * This method clears any existing errors by setting the `_error` property to `null`
   * and then calls the `restart` method on the `speedTest` instance to initiate or restart the speed test.
   */
  start() {
    this._error.set(null);
    this.speedTest.restart();
  }

  /**
   * Starts the speed test at a specified interval, repeating until a maximum number of checks is reached.
   *
   * @param {number} [repeat=900000] - The interval in milliseconds at which to repeat the speed test.
   * Defaults to 15 minutes (900000 milliseconds).
   */
  startRepeat(repeat: number = 1000 * 60 * 15) {
    this._error.set(null);
    this.speedTest.restart();
    this.currentCheck = 0;
    clearInterval(this.timer);
    this.timer = setInterval(() => {
      if (this.currentCheck >= this.maxChecks) {
        this.stop();
        return;
      }
      this.currentCheck++;
      this.speedTest.restart();
    }, repeat) as any;
  }

  /**
   * Stops the ongoing speed test and clears the timer.
   *
   * This method pauses the speed test by calling the `pause` method on the `speedTest` instance.
   * It also clears the interval timer set for repeating the speed test,
   * ensuring that the test does not continue or restart automatically.
   */
  stop() {
    this.speedTest.pause();
    clearInterval(this.timer);
  }

  /**
   * Initializes the SpeedTest instance with the specified configuration.
   *
   * @private
   */
  private init() {
    // When importing the SpeedTest via import, the tests fail. This is a workaround to fix the issue.
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const SpeedTest = require('@cloudflare/speedtest').default;
    this.speedTest = new SpeedTest({
      measurements: this.measurmentConfig,
      autoStart: false,
    });
  }

  /**
   * Converts a speed from bytes per second to megabits per second (Mbps).
   *
   * @param {number | undefined} bytesPerSecond - The speed in bytes per second to be converted.
   * @returns {number} The converted speed in megabits per second, rounded to two decimal places.
   */
  private bytesToMbps(bytesPerSecond: number | undefined): number {
    if (!bytesPerSecond) return 0;
    const megabitsPerSecond = bytesPerSecond / 1_000_000;
    return +megabitsPerSecond.toFixed(2);
  }
}
