export interface ENoteRegistration {
  dateCreated?: string;
  originator?: string;
  status: string;
  docName: string;
  min?: string;
  docID: string;
  template: string;
}

export interface ENoteTransaction {
  dateCreated: string;
  status: string;
  originator: string;
  template: string;
  docID: string;
  type?: string;
  date?: string;
  networkResult?: string;
  transitionResult?: string;
  requester?: string;
  responder?: string;
  timeSent?: string;
  timeResponse?: string;
  requestXML?: string;
  responseXML?: string;
}

export enum eNoteRegistration {
  'registered' = 'Registered',
  'unregistered' = 'Unregistered',
}

export interface mersActionRequest {
  type: string;
  typeRequest: string;
  environment: string;
  submittingPartyId: string;
  requestingPartyId: string;
  receivingPartyId: string;
  documentId: string;
  actionType: string;
  controllerOrgId: string;
  locationOrgId: string;
  delegateeOrgId: string;
}

export interface Datum {
  id: string;
  'mers-submitting-party-identifier': string;
  'mers-submitting-party-name': string;
  'mers-requesting-party-identifier': string;
  'mers-requesting-party-name': string;
  'mers-requesting-party-data': string;
  'mers-requesting-party-object'?: {
    '?xml': {
      '@version': string;
      '@encoding': string;
      '@standalone': string;
    };
    '!DOCTYPE': {
      '@name': string;
      '@system': string;
    };
    REQUEST_GROUP: {
      '@MISMOVersionID': string;
      REQUESTING_PARTY: {
        '@_Identifier': string;
        '@_Name': string;
      };
      RECEIVING_PARTY: {
        '@_Identifier': string;
        '@_Name': string;
      };
      SUBMITTING_PARTY: {
        '@_Identifier': string;
        '@_Name': string;
      };
      REQUEST: {
        '@_Datetime': string;
        '@_ID': string;
        REQUEST_DATA: {
          EREGISTRY_EDELIVERY_REQUEST: {
            '@MISMOVersionID': string;
            '@_RegistryTransactionIdentifier': string;
            '@_Type': string;
            EDELIVERY_RECIPIENT: {
              '@_Identifier': string;
            };
            EDELIVERY_DATA: {
              MERS: {
                '@MERS_MINNumber': string;
              };
              EMORTGAGE_PACKAGE: {
                '@MISMOVersionIdentifier': string;
                '@_ID': string;
                EMBEDDED_FILE: {
                  '@MIMEType': string;
                  '@MISMOVersionID': string;
                  '@_Description': string;
                  '@_EncodingType': string;
                  '@_ID': string;
                  '@_Name': string;
                  '@_Type': string;
                  DOCUMENT: string;
                };
              };
            };
          };
        };
      };
    };
  };
  'mers-requesting-party-object-val': string;
  'mers-receiving-party-identifier': string;
  'mers-receiving-party-name': string;
  'mers-receiving-party-data': string;
  'mers-receiving-party-object'?: {
    '?xml': {
      '@version': string;
      '@encoding': string;
    };
    '!DOCTYPE': {
      '@name': string;
      '@system': string;
    };
    RESPONSE_GROUP: {
      '@MISMOVersionID': string;
      '@_ID': string;
      RESPONDING_PARTY: {
        '@_Name': string;
        '@_Identifier': string;
      };
      RESPOND_TO_PARTY: {
        '@_Name': string;
        '@_Identifier': string;
      };
      RESPONSE: {
        '@_Datetime': string;
        '@_ID': string;
        RESPONSE_DATA: {
          EREGISTRY_EDELIVERY_RESPONSE: {
            '@MISMOVersionID': string;
            '@_TrackingNumber': string;
            '@_Datetime': string;
            '@_RegistryTransactionIdentifier': string;
            '@_ResultType': string;
            '@_Type': string;
            EDELIVERY_DATA: {
              STATUS: {
                '@_Condition': string;
                '@_Code': string;
              };
            };
          };
        };
      };
    };
  };
  'mers-receiving-party-object-val': string;
  'date-utc': Date;
  'date-pretty'?: any;
  environment: string;
  'network-status'?: any;
  'data-status'?: any;
  'mers-type': string;
  'mers-type-request': string;
  'external-id': string;
  'external-code': string;
  'external-type': string;
  direction: string;
  'mers-transaction-status': {
    'is-network-result-success': boolean;
    'is-transaction-result-success': boolean;
    code: string;
    type: string;
    name?: string;
    description?: string;
  };
  display: string;
  route: string;
  'route-action': string;
}

export interface MERS {
  code: string;
  name: string;
  description: string;
  data: {
    min?: string;
    uid: string;
    actions: {
      code: string;
      data: {
        registration: {
          code: string;
          status: string;
          complete: boolean;
          'action-show': boolean;
          visible: boolean;
        };
        edelivery: {
          code: string;
          status: string;
          complete: boolean;
          'action-show': boolean;
          visible: boolean;
        };
        'change-status': {
          code: string;
          status: string;
          complete: boolean;
          'action-show': boolean;
          visible: boolean;
        };
        transfer: {
          code: string;
          status: string;
          complete: boolean;
          'action-show': boolean;
          visible: boolean;
        };
        inquiry: {
          code: string;
          status: string;
          complete: boolean;
          'action-show': boolean;
          visible: boolean;
        };
      };
    };
    status: {
      code: string;
      data: {
        transactions: boolean;
      };
    };
    data?: {
      code: string;
      data: any;
    };
  };
}

export interface Data2 {
  data: Datum[];
}

export interface Items {
  data: Data2;
  next: string;
}

export interface Data {
  items: Items;
}

export interface V1Transactions {
  msg: string;
  code: string;
  info: any;
  data: Data;
}

export interface V1Document {
  msg: string;
  code: string;
  info: any;
  data: any;
}

export interface V1DocumentList {
  msg: string;
  code: string;
  info: any;
  data: V1DocumentListItem;
}

export interface V1DocumentListItem {
  items: {
    data: ListEnoteData;
    next: string;
  };
}

export interface ListEnoteData {
  data: EnoteData[];
}

export interface EnoteData {
  id: string;
  display: string;
  'file-name': string;
  'date-utc': Date;
  'date-pretty': string;
  route: string;
  'route-action': string;
  template: string;
  type: string;
  state: string;
}

export interface MersReport {
  msg: string;
  code: string;
  info: any;
  data: { items: { mers: MERS } };
}

export interface RegistryResponse {
  msg: string;
  code: string;
  info: any;
  data: RegistryData;
}

export interface RegistryData {
  uid: string;
  status: string;
  url: string;
  'mers-requesting-party-identifier': string;
  'mers-requesting-party-name': string;
  'mers-receiving-party-identifier': string;
  'mers-receiving-party-name': string;
  'mers-type': string;
  'mers-type-request': string;
  'environment-type': string;
  'network-status': string;
  'data-status': string;
  data: any;
  'data-filter-object': any;
  'data-status-object': any;
  'mers-submitting-party-identifier': string;
  'mers-submitting-party-name': string;
  'action-type': string;
  REQUEST_DATA: any;
  '@_RegistryTransactionIdentifier': string;
  'external-transaction-action-id': string;
  min: string;
  signature: string;
  schema: string;
  'external-transaction-id': string;
  'external-action-date-time': Date;
  'mers-requesting-party-data': string;
  'mers-requesting-party-object': any;
  'mers-receiving-party-data': string;
  'mers-receiving-party-object': any;
  'external-id': string;
  'external-code': string;
  'external-type': string;
  'external-method': string;
  direction: string;
  'mers-type-request-val': string;
  content?: any;
}
