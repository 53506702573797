import { Dan } from '@escrowtab/v2profile-api-client';
import { PSStrokeAsJSON } from '@et/presentational/signing-engine';
import { fabric } from 'fabric';
import { AuditAction } from '@et/typings';

export enum SocketsMessagesType {
  SOCKET_PAGE_CHANGE_EVENTS = 'SOCKET_PAGE_CHANGE_EVENTS',
  SOCKET_STAMP_EVENTS = 'SOCKET_STAMP_EVENTS',
  SOCKET_ANNOTATION_EVENTS = 'SOCKET_ANNOTATION_EVENTS',
  SOCKET_ANNOTATION_EVENTS_CHUNKED = 'SOCKET_ANNOTATION_EVENTS_CHUNKED',
  SOCKET_PAGE_ERASE = 'SOCKET_PAGE_ERASE',
  SOCKET_SIGNING_COMPLETE = 'SOCKET_SIGNING_COMPLETE',
  SOCKET_SKIP_TO_NEXT = 'SOCKET_SKIP_TO_NEXT',
  SOCKET_TEXTBOX_EVENT = 'SOCKET_TEXTBOX_EVENT',
  SOCKET_SIGNING_COMPLETE_STARTED = 'SOCKET_SIGNING_COMPLETE_STARTED',
  SOCKET_AUDIT_LOG_ACTION_ADDED = 'SOCKET_AUDIT_LOG_ACTION_ADDED',
  SOCKET_HISTORY = 'SOCKET_HISTORY',
  SOCKET_KEYBOARD = 'SOCKET_KEYBOARD',
}

export interface SOCKET_SKIP_TO_NEXT {
  type: SocketsMessagesType.SOCKET_SKIP_TO_NEXT;
}

export interface SOCKET_PAGE_CHANGE_EVENTS {
  type: SocketsMessagesType.SOCKET_PAGE_CHANGE_EVENTS;
  currentPage: number;
}
export interface SOCKET_ANNOTATION_EVENTS {
  type: SocketsMessagesType.SOCKET_ANNOTATION_EVENTS;
  annotation: PSStrokeAsJSON;
  canvasWidth: number;
  currentPage: number;
}

export interface SOCKET_ANNOTATION_EVENTS_CHUNKED {
  id: string;
  index: string;
  chunk: string;
  final: boolean;
}

export interface SOCKET_PAGE_ERASE {
  type: SocketsMessagesType.SOCKET_PAGE_ERASE;
  currentPage: number;
}

export interface SOCKET_SIGNING_COMPLETE {
  type: SocketsMessagesType.SOCKET_SIGNING_COMPLETE;
}

export interface SOCKET_SIGNING_COMPLETE_STARTED {
  type: SocketsMessagesType.SOCKET_SIGNING_COMPLETE_STARTED;
}

export interface SOCKET_TEXTBOX_EVENT {
  type: SocketsMessagesType.SOCKET_TEXTBOX_EVENT;
  textbox: fabric.ITextboxOptions;
  canvasWidth: number;
  currentPage: number;
}

export interface SOCKET_STAMP_EVENTS {
  type: SocketsMessagesType.SOCKET_STAMP_EVENTS;
  stampId: string | 'DEFAULT_ESCROWTAB_STAMP';
  canvasWidth: number;
  coordinates: {
    x: number;
    y: number;
  };
  currentPage: number;
  dan?: Dan;
  isNotarized: boolean;
}

export interface SOCKET_AUDIT_LOG_ACTION_ADDED {
  type: SocketsMessagesType.SOCKET_AUDIT_LOG_ACTION_ADDED;
  auditAction: AuditAction;
}

export interface SOCKET_HISTORY {
  type: SocketsMessagesType.SOCKET_HISTORY;
  currentPage: number;
  action: 'undo' | 'redo';
}

export interface SOCKET_KEYBOARD {
  type: SocketsMessagesType.SOCKET_KEYBOARD;
  currentPage: number;
  text: string;
  matrixId: string;
  action: 'added' | 'updated' | 'deleted';
}
