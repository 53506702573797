import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { NotificationService } from '@et/notifications';
@Injectable()
export class FortyNineNineInterceptor implements HttpInterceptor {
  constructor(private notificationService: NotificationService) {}
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 499) {
          this.notificationService.showError(
            'You appear to be on a spotty WiFi network, which may have upload restrictions. For a better experience consider switching to a different network.',
          );
        }
        // handle other errors as before
        throw new Error(err.message);
      }),
    );
  }
}
