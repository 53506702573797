import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'et-atoms-page-tab',
  templateUrl: './page-tab.component.html',
  styleUrls: ['./page-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTabComponent {
  @Input() isActive = false;
  @Input() routerLink!: string[];
}
