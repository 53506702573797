import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Alert } from '../../services/notification.service';

@Component({
  selector: 'et-notifications-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  @Input() alert!: Alert;

  onClose() {
    this.alert.remove();
  }
}
