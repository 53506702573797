import {
  Component,
  ContentChild,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DocumentMenuComponent } from './../document-menu/document-menu.component';

@Component({
  selector: 'et-atoms-document-display',
  templateUrl: './document-display.component.html',
  styleUrls: ['./document-display.component.scss'],
})
export class DocumentDisplayComponent implements OnChanges {
  @Input() thumbnail!: string | null | undefined;
  @Input() fileName: string | undefined;
  @Input() totalPages: number | undefined;

  @ContentChild(DocumentMenuComponent) menu!: DocumentMenuComponent;

  ngOnChanges(changes: SimpleChanges): void {
    if (this.menu && changes['fileName']) {
      this.menu.fileName = changes['fileName'].currentValue;
    }
    if (this.menu && changes['totalPages']) {
      this.menu.totalPages = changes['totalPages'].currentValue;
    }
  }
}
