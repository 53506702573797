import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'et-atoms-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
}
