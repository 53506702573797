<h3 class="mb-5 text-base font-semibold tracking-wide text-white">
  <ng-container *transloco="let t; read: 'add-document'">
    <span class="no-underline">{{ t('saveDocumentAs') }}</span>
  </ng-container>
</h3>

<!-- Input field -->
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <et-atoms-input class="w-full">
    <label class="!text-midnight !bg-white">
      <ng-container *transloco="let t; read: 'add-document'">
        <span class="no-underline">{{ t('documentName') }}</span>
      </ng-container>
    </label>
    <input
      etAtomsInput
      formControlName="name"
      type="text"
      class="!border-neutral !text-midnight !bg-white focus:!shadow-none"
    />
    <span
      etAtomsInputError
      *ngIf="form.get('name')?.dirty && form.get('name')?.hasError('required')"
      class="!text-white"
      >Name is required</span
    >
    <span
      etAtomsInputError
      *ngIf="
        form.get('name')?.dirty &&
        form.get('name')?.hasError('duplicateFileName')
      "
      class="!text-white"
      >File with this name already exist</span
    >
  </et-atoms-input>

  <!-- Buttons -->
  <div class="flex items-center justify-end gap-4">
    <button
      mat-dialog-close
      class="capitalize rounded-full btn btn-outline min-w-[120px] border-white text-white hover:border-white"
    >
      <ng-container *transloco="let t; read: 'add-document'">
        <span>{{ t('close') }}</span>
      </ng-container>
    </button>
    <button class="capitalize rounded-full btn btn-primary min-w-[120px]">
      <ng-container *transloco="let t; read: 'add-document'">
        <span>{{ t('save') }}</span>
      </ng-container>
    </button>
  </div>
</form>
