@if (template(); as template) {
<!-- Selected template -->
<div>
  <p class="m-0 text-sm font-bold">Selected template:</p>
  <p class="m-0 truncate">{{ template.templateInfo.name }}</p>
</div>
<!-- Buttons -->
<div class="flex justify-start gap-6 my-[5px]">
  <!-- Delete button -->
  <button
    class="flex items-center gap-2 text-sm text-error"
    (click)="onDeleteSelectedTemplate()"
  >
    <ng-container *ngTemplateOutlet="deleteIcon"></ng-container>
    Remove
  </button>
  <a
    target="_blank"
    [routerLink]="['/app/profile/templates', template.id]"
    class="flex items-start gap-2 text-sm no-underline text-primary"
  >
    Preview
    <ng-container *ngTemplateOutlet="previewIcon"></ng-container>
  </a>
</div>
} @else {
<!-- Input search template -->
<et-atoms-input class="w-full">
  <label>Search template</label>
  <input
    etAtomsInput
    [formControl]="searchCtrl"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    #inputEl
  />
</et-atoms-input>

<!-- Search results popup -->
@if (searchResults$ | async; as templates) {
<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isSearchMenuOpen()"
  [cdkConnectedOverlayPositions]="position"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  (backdropClick)="isSearchMenuOpen.set(false)"
>
  <et-atoms-templates-search-list
    [style.width.px]="inputEl.getBoundingClientRect().width"
    [templates]="templates"
    (templateSelected)="onTemplateSelected($event)"
  ></et-atoms-templates-search-list>
</ng-template>
} }

<!-- Delete icon template -->
<ng-template #deleteIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    class="w-4 h-4"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
    />
  </svg>
</ng-template>

<!-- Preview icon template -->
<ng-template #previewIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke-width="1.5"
    stroke="currentColor"
    class="w-4 h-4"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
    />
  </svg>
</ng-template>
