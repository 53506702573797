<ng-container *ngIf="show">
  <span
    *ngIf="signed !== totaldFields || needStamp; else complete"
    class="pt-1 text-xs font-bold"
  >
    <ng-container *ngIf="signed !== totaldFields">
      {{ totaldFields - signed }} required field{{
        totaldFields - signed > 1 ? 's' : ''
      }}
      <ng-container *ngIf="needStamp"> | </ng-container>
    </ng-container>

    <ng-container *ngIf="needStamp">1 stamp </ng-container>
  </span>
</ng-container>

<span *ngIf="doNotSign" class="pt-1 text-xs font-bold">{{
  isENote ? 'Do not sign' : 'Wet ink sign'
}}</span>

<ng-template #complete>
  <div class="flex items-center gap-3">
    <img src="assets/icons/complete-green.svg" alt="Complete" />
    <span class="pt-1 text-xs font-bold">Page complete</span>
  </div>
</ng-template>
