import { Component, OnInit, OnDestroy } from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  InteractionStatus,
  AccountInfo,
  EventType,
  EventCallbackFunction,
} from '@azure/msal-browser';
import { Capacitor } from '@capacitor/core';
import { SetAADUserWithClaims } from '@et/guards';
import { AADUserWithClaims } from '@et/typings';
import { initTracking, setUser, trackEv, trackingEvents } from '@et/utils';
import { Store } from '@ngxs/store';
import { filter, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'et-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private readonly _onDestroy$ = new Subject<void>();

  constructor(
    private msalBroadcastService: MsalBroadcastService,
    private msal: MsalService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    initTracking();

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        takeUntil(this._onDestroy$),
      )
      .subscribe(() => {
        const [account] = this.msal.instance.getAllAccounts();
        this.store.dispatch(
          new SetAADUserWithClaims(account as AADUserWithClaims),
        );
        setUser(account as AccountInfo);
        trackEv(trackingEvents.webEJournalLogIn, {
          platform: Capacitor.getPlatform(),
        });
      });
    this.setMsalInstaceCallback();
  }

  /**
   * Sets the active account after a successful login redirect
   */
  private setMsalInstaceCallback() {
    const callback = (event: {
      eventType: EventType;
      payload: { account: AccountInfo };
    }) => {
      // set active account after redirect
      if (
        event.eventType === EventType.LOGIN_SUCCESS &&
        event.payload.account
      ) {
        const account = event.payload.account;
        this.msal.instance.setActiveAccount(account);

        this.msal.loginRedirect();
      }
    };
    this.msal.instance.addEventCallback(callback as EventCallbackFunction);
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
