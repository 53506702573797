/* `const CHARS` is a constant string that contains the characters used in the Base64 encoding scheme.
It includes all uppercase and lowercase letters of the English alphabet, numbers from 0 to 9, and
two special characters, "+" and "/". The "=" character is used as a padding character in Base64
encoding. This constant is used in the `encodeSVG` function to encode the input SVG string into a
Base64-encoded data URI. */
const CHARS =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

/**
 * The function encodes a string into UTF-8 format.
 * @param {string} input - The input parameter is a string that needs to be encoded in UTF-8 format.
 * @returns The function `utf8Encode` returns a string that is the UTF-8 encoded version of the input
 * string.
 */
export const utf8Encode = (input: string): string => {
  input = input.replace(/\r\n/g, '\n');

  let i = 0;
  let output = '';

  for (; i < input.length; i++) {
    const c = input.charCodeAt(i);

    if (c < 128) {
      output += String.fromCharCode(c);
    } else if (c > 127 && c < 2048) {
      output += String.fromCharCode((c >> 6) | 192);
      output += String.fromCharCode((c & 63) | 128);
    } else {
      output += String.fromCharCode((c >> 12) | 224);
      output += String.fromCharCode(((c >> 6) & 63) | 128);
      output += String.fromCharCode((c & 63) | 128);
    }
  }

  return output;
};

/**
 * The function encodes a given SVG input string into a base64-encoded data URI.
 * @param {string} input - The input parameter is a string that represents the SVG content that needs
 * to be encoded.
 * @returns A string that represents the input SVG encoded in base64 format and prefixed with the data
 * URI scheme for SVG images.
 */
export function encodeSVG(input: string): string {
  let i = 0;
  let chr1: number;
  let chr2: number;
  let chr3: number;
  let enc1: number;
  let enc2: number;
  let enc3: number;
  let enc4: number;
  let output = '';

  input = utf8Encode(input);

  while (i < input.length) {
    chr1 = input.charCodeAt(i++);
    chr2 = input.charCodeAt(i++);
    chr3 = input.charCodeAt(i++);

    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;

    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }

    output =
      output +
      CHARS.charAt(enc1) +
      CHARS.charAt(enc2) +
      CHARS.charAt(enc3) +
      CHARS.charAt(enc4);
  }

  return `data:image/svg+xml;base64,${output}`;
}
