<svg
  width="11"
  height="16"
  viewBox="0 0 11 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path d="M10.6667 16H0V0H7.28693L10.6667 3.37973V16Z" fill="#C6CCC6" />
</svg>

<input
  type="range"
  [min]="min"
  [max]="max"
  [(ngModel)]="widthSizeModel"
  (ngModelChange)="onValueChanged($event)"
  [disabled]="disabled"
  class="w-20 mx-2"
/>
<svg
  width="18"
  height="16"
  viewBox="0 0 18 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M4.84804 7.27206H0V0H3.3121L4.84804 1.53594V7.27206Z"
    fill="#C6CCC6"
  />
  <path
    d="M11.1664 7.27206H6.31836V0H9.63046L11.1664 1.53594V7.27206Z"
    fill="#C6CCC6"
  />
  <path
    d="M17.4848 7.27206H12.6367V0H15.9488L17.4848 1.53594V7.27206Z"
    fill="#C6CCC6"
  />
  <path d="M4.84804 16H0V8.72794H3.3121L4.84804 10.2639V16Z" fill="#C6CCC6" />
  <path
    d="M11.1664 16H6.31836V8.72794H9.63046L11.1664 10.2639V16Z"
    fill="#C6CCC6"
  />
  <path
    d="M17.4848 16H12.6367V8.72794H15.9488L17.4848 10.2639V16Z"
    fill="#C6CCC6"
  />
</svg>
