import { Injectable } from '@angular/core';
import { v1Enote } from '@et/typings';
import { convertFileToDataURLAsync, getFileFromUrl } from '@et/utils';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root',
})
export class EnoteEntityService {
  protected _tableName = 'enote_entities';

  constructor(private storage: StorageService) {}

  /**
   * This function sets initial dummy value in the table
   */
  async addDummy() {
    try {
      await this.setTable();
      await this.storage.setItem('0', '1');
    } catch (error) {
      console.error('EnoteEntityService: ', error);
      return;
    }
  }

  /**
   * This function return all values from storage
   */
  async getAll(): Promise<v1Enote[]> {
    try {
      await this.setTable();
      const eNoteJSONArrWithDummy = await this.storage.getAllValues();
      const eNoteJSONArr = eNoteJSONArrWithDummy.filter((e) => e !== '1');
      const eNotes: v1Enote[] = [];
      for (const eNoteJSON of eNoteJSONArr) {
        const eNote = JSON.parse(eNoteJSON);
        const file = await getFileFromUrl(eNote.file, 'enote.pdf');
        eNote.file = file;
        eNotes.push(eNote);
      }
      return eNotes;
    } catch (error) {
      console.error('EnoteEntityService: ', error);
      return [];
    }
  }

  /**
   * This function gets eNote entity by id
   * @param {string} id - packet id
   */
  async get(id: string): Promise<v1Enote | null> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return null;
      }
      const eNoteJSON = await this.storage.getItem(id);
      const eNote = JSON.parse(eNoteJSON);
      const file = await getFileFromUrl(eNote.file, 'enote.pdf');
      eNote.file = file;
      return eNote;
    } catch (error) {
      console.error('EnoteEntityService: ', error);
      return null;
    }
  }

  /**
   * This function adds new eNote entity to storage
   * @param {string} id - eNote id
   * @param {v1Enote} eNote - v1Enote
   */
  async createOrUpdate(id: string, eNote: v1Enote): Promise<void> {
    try {
      await this.setTable();
      const file = await convertFileToDataURLAsync(eNote.file as File);
      const eNoteJSON = JSON.stringify({ ...eNote, file });
      await this.storage.setItem(id, eNoteJSON);
    } catch (error) {
      console.error('EnoteEntityService: ', error);
    }
  }

  /**
   * This function deletes eNote entity in storage
   * @param {string} id - Packet id
   */
  async delete(id: string): Promise<void> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return;
      }
      await this.storage.removeItem(id);
    } catch (error) {
      console.error('EnoteEntityService: ', error);
    }
  }

  /**
   * This function deletes all keys
   */
  async clear(): Promise<void> {
    try {
      await this.setTable();
      await this.storage.clear();
      await this.addDummy();
    } catch (error) {
      console.error('EnoteEntityService: ', error);
    }
  }

  /**
   * This function sets storage table
   */
  async setTable() {
    await this.storage.setTable(this._tableName);
  }
}
