import {
  Injectable,
  OnDestroy,
  Renderer2,
  RendererFactory2,
} from '@angular/core';
import { readFileAsync } from '@et/utils';
import {
  PDFDocumentProxy,
  PDFPageProxy,
  RenderTask,
  getDocument,
  GlobalWorkerOptions,
} from 'pdfjs-dist';

@Injectable()
export class NavigationPDFService implements OnDestroy {
  private cachedData = new Map<number, string>();
  private PDFDocumentProxy!: PDFDocumentProxy;
  private renderer: Renderer2;
  private renderTask!: RenderTask | null;
  private pageWidth!: number;

  constructor(rendererFactory: RendererFactory2) {
    GlobalWorkerOptions.workerSrc = 'assets/scripts/pdf.worker.js';
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Initialize the PDF service with a PDF file or a string representing a PDF file.
   * @param {File | string} pdf - The PDF file or a string representing a PDF file.
   * @param {number} [pageWidth=150] - The width of the page.
   * @throws {Error} If the PDF file is not provided.
   */
  async init(pdf: File | string, pageWidth: number = 150) {
    this.pageWidth = pageWidth;

    if (!pdf) throw new Error('File is required');

    let fileread: string;
    if (pdf instanceof File) {
      fileread = (await readFileAsync(pdf)) as string;
    } else {
      fileread = pdf;
    }
    this.PDFDocumentProxy = await getDocument(fileread as string).promise;
  }

  /**
   * Load a page from the PDF file. If the page is already cached, return the cached page.
   * @param {number} page - The page number to load.
   * @returns {Promise<string>} A promise that resolves to the loaded page.
   */
  async loadPage(page: number): Promise<string> {
    if (this.cachedData.has(page)) {
      return this.cachedData.get(page) as string;
    }
    const img = await this.renderPage(page);
    this.cachedData.set(page, img);
    return img;
  }

  /**
   * Render a page from the PDF file.
   * @param {number} pageNumber - The page number to render.
   * @returns {Promise<string>} A promise that resolves to the rendered page.
   * @throws {Error} If the PDF file is not loaded.
   */
  async renderPage(pageNumber: number): Promise<string> {
    if (!this.PDFDocumentProxy) {
      throw new Error('PDF file is not loaded');
    }
    const page = await this.PDFDocumentProxy.getPage(pageNumber);
    const vp = page.getViewport({ scale: 1 });
    const heightWidthRatio = vp.height / vp.width;
    const canv = await this.createCanvas(page);
    canv.height = this.pageWidth * heightWidthRatio;
    canv.width = this.pageWidth;
    const scale = Math.min(canv.width / vp.width, canv.height / vp.height);
    if (this.renderTask) {
      this.renderTask.cancel();
    }
    this.renderTask = page.render({
      canvasContext: canv.getContext('2d') as CanvasRenderingContext2D,
      viewport: page.getViewport({ scale }),
    });
    return this.renderTask.promise
      .then(() => canv.toDataURL('image/png'))
      .catch((error: any) => {
        this.renderTask = null;

        if (error.name === 'RenderingCancelledException') {
          return this.renderPage(pageNumber);
        }
        return error;
      });
  }

  /**
   * Create a canvas for a page from the PDF file.
   * @param {PDFPageProxy} page - The page to create a canvas for.
   * @returns {Promise<HTMLCanvasElement>} A promise that resolves to the created canvas.
   * @private
   */
  private async createCanvas(page: PDFPageProxy): Promise<HTMLCanvasElement> {
    const vp = page.getViewport({ scale: 1 });
    const canvas = this.renderer.createElement('canvas');
    const scalesize = 1;
    canvas.width = vp.width * scalesize;
    canvas.height = vp.height * scalesize;

    const scale = Math.min(canvas.width / vp.width, canvas.height / vp.height);
    return await page
      .render({
        canvasContext: canvas.getContext('2d'),
        viewport: page.getViewport({ scale: scale }),
      })
      .promise.then(() => canvas);
  }

  /**
   * Cleanup and destroy the PDF document when the service is destroyed.
   */
  ngOnDestroy() {
    this.PDFDocumentProxy?.cleanup();
    this.PDFDocumentProxy?.destroy();
  }
}
