/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import {
  CompanyAPIModel,
  CompanyResponseApiModel,
} from '../models/company-response-api-model';
import { DocumentResponseApiModel } from '../models/document-response-api-model';
import { BaseOpenAPIParams } from '../models/base-item-object-api-model';
import { ApiConfiguration } from '../configs/api-configuration';
import { StrictHttpResponse } from '../configs/strict-http-response';
import { RequestBuilder } from '../../../request-builder';
import { BaseService } from './base-service';

/**
 * Public operations to regular authenticated API consumers
 */
@Injectable({
  providedIn: 'root',
})
export class CompanyApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation companyBrowse
   */
  static readonly CompanyBrowsePath = '/company';
  /**
   * Path part for operation companySet
   */
  static readonly CompanySetPath = '/company';
  /**
   * Path part for operation companyGet
   */
  static readonly CompanyGetPath = '/company/{uuid}';

  /**
   * Company search.
   *
   * Search via browse filter, sort order + sort field
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyBrowse()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyBrowse$Response(
    params?: BaseOpenAPIParams,
  ): Observable<StrictHttpResponse<Array<DocumentResponseApiModel>>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      CompanyApiService.CompanyBrowsePath,
      'get',
    );
    if (params) {
      rb.query('q', params['q'], {});
      rb.query('offset', params.offset, {});
      rb.query('limit', params.limit, {});
      rb.query('sort-field', params['sort-field'], {});
      rb.query('sort-direction', params['sort-direction'], {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<DocumentResponseApiModel>>;
        }),
      );
  }

  /**
   * Company search.
   *
   * Search via browse filter, sort order + sort field
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyBrowse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyBrowse(
    params?: BaseOpenAPIParams,
  ): Observable<Array<DocumentResponseApiModel>> {
    return this.companyBrowse$Response(params).pipe(
      map(
        (r: StrictHttpResponse<Array<DocumentResponseApiModel>>) =>
          r.body as Array<DocumentResponseApiModel>,
      ),
    );
  }

  /**
   * Set item by UUID.
   *
   * Set item by UUID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companySet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  companySet$Response(params?: {
    body?: CompanyResponseApiModel;
  }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      CompanyApiService.CompanySetPath,
      'post',
    );
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        }),
      );
  }

  /**
   * Set item by UUID.
   *
   * Set item by UUID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companySet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  companySet(params?: {
    body?: CompanyResponseApiModel;
  }): Observable<CompanyResponseApiModel> {
    return this.companySet$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as CompanyResponseApiModel),
    );
  }

  /**
   * Get item by UUID.
   *
   * Get item by UUID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyGet$Response(params: {
    /**
     * UUID
     */
    uuid: string;
  }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      CompanyApiService.CompanyGetPath,
      'get',
    );
    if (params) {
      rb.path('uuid', params.uuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        }),
      );
  }

  /**
   * Get item by UUID.
   *
   * Get item by UUID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyGet(params: {
    /**
     * UUID
     */
    uuid: string;
  }): Observable<CompanyAPIModel> {
    return this.companyGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<string>) =>
          JSON.parse(r.body) as CompanyAPIModel,
      ),
    );
  }
}
