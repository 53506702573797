import { DocumentsFilter } from '@et/typings';
import { BaseItemObjectApiModel } from './base-item-object-api-model';
export interface DocumentResponseApiModel {
  data: {
    action?: string;
    display?: string;
    email?: string;
    pin?: string;
    'original-document-id'?: string;
    id?: string;
  };
  files?: File[];
}
export interface DocumentRequestPostApiModel {
  items?: BaseItemObjectApiModel;
}

export interface DocumentGetAPIResponse {
  msg: string;
  code: string;
  info: BaseResponseAPIInfo;
  data: DocumentGetAPIResponseData;
}

export interface DocumentGetAPIResponseData {
  items: BaseResponseAPIItems;
}

export interface BaseResponseAPIItems {
  data: BaseResponseAPIItemsData;
  next: string;
}

export interface BaseResponseAPIItemsData {
  data: BaseResponseAPIDatum[];
}

export interface BaseResponseAPIDatum {
  id: string;
  display: string;
  'file-name': string;
  'date-utc': string;
  'date-pretty': string;
  route: string;
  'route-action': string;
  type: docType;
  state: docState;
  template?: string;
}

export type DocumentsFilterAndBaseResponseAPIInfo =
  Partial<BaseResponseAPIFilter> & Partial<DocumentsFilter>;

export interface BaseResponseAPIInfo {
  version: string;
  action: string;
  path: string;
  format: string;
  'route-val': string;
  'route-detail-val': string;
  filter: DocumentsFilterAndBaseResponseAPIInfo;
}

export interface BaseResponseAPIFilter {
  sortField: string;
  sortDirection: string;
  page: number;
  limit: number;
  count: number;
}
export enum docSortDirection {
  DESC = 'desc',
  ASC = 'asc',
}

export enum docSortField {
  DATE = 'date-modified',
  NAME = 'name',
}

export enum docType {
  All = 'all',
  ORIGINATOR = 'originator',
  AGENT = 'agent',
}

export enum docState {
  All = 'all',
  SIGNED = 'signed',
  UNSIGNED = 'unsigned',
}

export interface docSet {
  action: string;
  display: string;
  email: string;
  pin: string;
}

export interface DocumentFileGetAPIResponse {
  msg: string;
  code: string;
  info: DocumentFileGetAPIResponseInfo;
  data: DocumentFileGetAPIResponseData;
}

export interface DocumentFileGetAPIResponseInfo {
  version: string;
  action: string;
  path: string;
  format: string;
  'route-val': string;
  'route-detail-val': string;
}

export interface DocumentFileGetAPIResponseData {
  id: string;
  'agent-id': string;
  'originator-id': string;
  display: string;
  'file-name': string;
  'file-content-type': string;
  pin: string;
  unsigned: string;
  'unsigned-encoded': string;
  'unsigned-fileinfo': string;
  'unsigned-fileinfo-encoded': string;
  view: string;
  'view-encoded': string;
  'view-fileinfo': string;
  'view-fileinfo-encoded': string;
  signed: string;
  'signed-encoded': string;
  'signed-fileinfo': string;
  'signed-fileinfo-encoded': string;
  'is-signed': boolean;
  'has-document-content-data': boolean;
  'is-template': boolean;
  'is-edit': boolean;
  'is-view': boolean;
  'viewer-url': string;
}

export interface DocumentProcessData {
  settings: unknown;
  meta: any;
  files: DocumentProcessDataFile[];
  file?: File;
}

export interface DocumentProcessDataFile {
  'file-uid': string;
  'file-name': string;
  'file-data': string;
  'file-data-type': string;
}

export interface DocumentProcessDataResponse {
  statusCode: number;
  status: boolean;
  message: string | null;
  data: {
    id: string;
    externalId: string;
  };
  modelErrors: string | null;
}

export interface DocumentGetExternalAPIResponse {
  items: Items;
  uid: string;
}

interface Items {
  mers: Mers;
}

interface Mers {
  code: string;
  name: string;
  description: string;
  data: MersData;
}

interface MersData {
  uid: string;
  min: string;
  actions: Actions;
  status: Status;
  data: PurpleData;
}

interface Actions {
  code: string;
  data: ActionsData;
}

interface ActionsData {
  registration: ChangeStatus;
  edelivery: ChangeStatus;
  'change-status': ChangeStatus;
  transfer: ChangeStatus;
  inquiry: ChangeStatus;
}

interface ChangeStatus {
  code: string;
  status: string;
  complete: boolean;
  'action-show': boolean;
  visible: boolean;
}

interface PurpleData {
  code: string;
  data: never;
}

interface Status {
  code: string;
  data: StatusData;
}

interface StatusData {
  transactions: boolean;
}
