import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { selectOption } from '@et/typings';
@Component({
  selector: 'et-atoms-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent {
  @Input() options: selectOption[] = [];
  @Input() label = '';
  @Input() selected?: selectOption;
  @Output() optionChanged: EventEmitter<selectOption> = new EventEmitter();
}
