import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  inject,
  Input,
} from '@angular/core';
import { Highlightable } from '@angular/cdk/a11y';
import { DocPrepDocument } from '@et/typings';

@Component({
  selector: 'et-atoms-templates-search-list-item',
  standalone: true,
  imports: [],
  template: `{{ template.templateInfo.name }}`,
  styles: [
    `
      :host {
        @apply block px-4 py-2 cursor-pointer hover:bg-base-100;
        &.active {
          @apply bg-base-100;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplatesSearchListItemComponent implements Highlightable {
  private elementRef = inject(ElementRef);

  @Input({ required: true }) template!: DocPrepDocument;

  @HostBinding('class.active')
  active = false;

  /**
   * Sets the active styles for the list item.
   */
  setActiveStyles(): void {
    this.active = true;
    this.scrollIntoView();
  }

  /**
   * Sets the inactive styles for the list item.
   */
  setInactiveStyles(): void {
    this.active = false;
  }

  /**
   * Gets the label for the list item.
   */
  getLabel(): string {
    return this.template.templateInfo.name;
  }

  /**
   * Scrolls the list item into view.
   */
  private scrollIntoView() {
    this.elementRef.nativeElement.scrollIntoView({ block: 'center' });
  }
}
