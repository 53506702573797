<div
  class="flex items-center justify-between max-w-screen-xl px-6 m-6 mx-auto mb-0 sm:flex-row-reverse"
>
  <!-- Menu: sm screen only -->
  <button
    type="button"
    class="flex text-sm rounded-full sm:hidden"
    aria-expanded="false"
    aria-haspopup="true"
    (click)="onLogout()"
    [style.zIndex]="isModalOpen ? 1001 : 1"
  >
    <span class="sr-only">Open user menu</span>
    <img
      class="w-8 h-8 rounded-full"
      [src]="
        isModalOpen ? 'assets/icons/close-filled.svg' : 'assets/icons/menu.svg'
      "
      alt="Menu"
    />
  </button>
  <!-- Lohout btn: md+ screen -->
  <button
    class="hidden capitalize rounded-full text-midnight sm:flex btn btn-sm btn-outline"
    (click)="onLogout()"
  >
    <p>Logout</p>
  </button>

  <!-- Logo -->
  <img
    [src]="
      isModalOpen
        ? 'assets/images/EscrowTabLogoReverse.svg'
        : 'assets/images/EscrowTabLogo.svg'
    "
    class="w-auto h-12"
    [style.zIndex]="isModalOpen ? 1001 : 1"
  />
</div>
