export const barcodes = [
  '1031 Documents',
  '1083',
  '1099',
  '2ND Deed Of Trust',
  '2nd Lien Note',
  '2nd Loan Package',
  '2nd Signed Hud',
  'Affidavit & Agreement',
  'Affidavit and Indemnity',
  'Agent Package',
  'Amendment',
  'Assignment of Rents',
  'Bill of Sale',
  'BILLS AND DEMANDS',
  'Borrower/Buyer Closing Package',
  'Borrower/Buyer Settlement Statement',
  'Buyer 521',
  'Buyer ALTA SS',
  'Buyer Closing Copies',
  'Buyer Copies',
  'Buyer Information Form',
  'Buyer Lender Set',
  'BUYER SIGNED ESCROW INSTRUCTIONS',
  'Buyer Statement',
  'Buyer WD',
  "Buyer's Agent Closing Docs",
  'Cashier Check Verification',
  'CDA',
  'Certification of Non-Foreign Status',
  'Checks',
  'Closing Disclosure Buyer',
  'Closing Disclosure Form',
  'Closing Disclosure Seller',
  'Closing File Checklist',
  'Closing Instructions',
  'Compliance Agreement',
  'Deed Of Trust',
  'Deposited Check',
  'Deposits',
  'Doc Package',
  'Drivers License',
  'Escrow Agreements',
  'Escrow File',
  'Escrow Ledger',
  'ESG File Checklist',
  'eSign Consent',
  'Executed Survey',
  'FedEx',
  'File Contents',
  'Final Sales Contract',
  'Final Title Commitment',
  'Final Utilities',
  'FinCen Checklist',
  'FinCen Info Collection',
  'Funding Approval',
  'Funding Conditions',
  'HOA Status Letter',
  'Home Equity Affidavit',
  'ID',
  'ILC',
  'Invoices',
  'Lender Closing Instructions',
  'LOAN DOCUMENTS',
  'Loan Package',
  'Misc Documents',
  'Misc Recording Documents',
  'Miscellaneous Correspondence',
  'Money Package',
  'Notary Information Form',
  'Note',
  'Notice',
  'Other',
  'Payment Letter',
  'Payoff',
  'Personal Representative Deed',
  'Policy Delivery',
  'Policy Package',
  'Post Close Check List',
  'Power Of Attorney',
  'Power of Attorney Cert',
  'Premium Report',
  'Privacy Policy',
  'Proceeds Authorization',
  'Processing Worksheet',
  'Proration Agreement',
  'QuitClaim Deed',
  'R and D Ledger',
  'Rate Calculation',
  'Real Estate Documents',
  'Real Property Transfer',
  'Realtor Closing Copies',
  'Receipts and Disbursements',
  'Recording & Policy Instructions',
  'Refinance Documents',
  'Release Of Lien',
  'ROL for Execution',
  'Seller Closing Copies',
  'Seller Copies',
  'Seller Information Sheet',
  'Seller Limited POA',
  'Seller Post Closing Docs',
  'SELLER SIGNED ESCROW INSTRUCTIONS',
  'Seller Signed Lender Docs',
  'Seller Statement',
  'Seller Statement & CD',
  'Seller Statement of Understanding',
  'Seller WD & RA',
  "Seller's Agent Closing Docs",
  "Seller's Closing Package",
  'Signed Buyer Escrow Docs',
  'Signed Buyer Statement',
  'SIGNED COMMISSION INSTRUCTIONS',
  'Signed Escrow Docs',
  'Signed HUD',
  'Signed Loan Package',
  'Signed Seller Closing Disclosure',
  'Signed Seller Escrow Docs',
  'Signed Seller Statement',
  'Signed T-00',
  'Signed Title Docs',
  'Special Warranty Deed',
  'Statement of Authority',
  'Statements',
  'Subordination Agreement',
  'Supplemental Affidavit',
  'Survey',
  'Survey Affidavit',
  'T-47',
  'T00',
  'Tax Agreement',
  'Tax Documents',
  'TD 1000',
  'Texas Disclosure Form',
  'UCC Filing',
  'Utility Agreement',
  'W9',
  'Warranty Deed',
  'Well Transfer',
  'Wire Instructions',
  'Wire Transfer Inst',
  'Wires',
];
