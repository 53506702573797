import { BaseItemApiModel } from './base-item-api-model';
import { BaseItemListApiModel } from './base-item-list-api-model';

/**
 * Base response error wrapper.
 */
export interface BaseResponseErrorApiModel {
  action?: unknown;
  code: string;
  data?: BaseItemApiModel | BaseItemListApiModel;
  info?: unknown;
  msg: string;
}
