import { environment } from '@et/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SecureLink, SecureLinkData } from '@et/typings';

@Injectable({ providedIn: 'root' })
export class SecureLinkService {
  private secureLinksWithAuth = '/secureLinks/auth';
  private secureLinksWithoutAuth = '/secureLinks';

  constructor(private http: HttpClient) {}

  /**
   * Validatd link Id on the backend to make sure it is correct and valid
   * @param {String} linkId - Id of the link
   *
   * @memberof SecureLinkService
   */
  validateLink(linkId: string): Observable<SecureLink> {
    const url =
      environment.apiSecureLinks.domain +
      this.secureLinksWithoutAuth +
      '/validate';
    const params = new HttpParams().append('linkId', linkId);
    return this.http.get<SecureLink>(url, { params });
  }

  /**
   * Validatd pin on the backend
   * @param {String} pin - Document pin
   * @param {String} linkId - Link Id
   *
   * @memberof SecureLinkService
   */
  validatePin(pin: string | undefined, linkId: string): Observable<string> {
    const url = environment.apiSecureLinks.domain + this.secureLinksWithoutAuth;
    const params = new HttpParams()
      .append('linkId', linkId)
      .append('pin', pin as string);
    return this.http.get<string>(url, { params });
  }

  /**
   * Send secure links for a document
   * @param {String} link - SecureLinkData
   *
   * @memberof SecureLinkService
   */
  addSecureLnks(link: SecureLinkData, message: string) {
    const url = `${environment.apiSecureLinks.domain}${this.secureLinksWithAuth}/create?messageTopic=${message}`;
    return this.http.post(url, link);
  }

  /**
   * Resends a secure link based on the provided link data.
   *
   *
   * @param {Partial<SecureLinkData>} link - The data for the secure link to be resent.
   * @returns {Observable<string>} An Observable that emits the response from the server
   */
  resendSecureLink(link: SecureLinkData): Observable<string> {
    const url = `${environment.apiSecureLinks.domain}${this.secureLinksWithAuth}/resend`;
    return this.http.post<string>(url, link);
  }
}
