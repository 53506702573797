import { BaseItemObjectApiModel } from './base-item-object-api-model';
export interface CompanyResponseApiModel {
  items?: BaseItemObjectApiModel;
}
export interface CompanyAPIModel {
  msg: string;
  code: string;
  info: CompanyAPIInfo;
  data: CompanyAPIData;
}

export interface CompanyAPIData {
  type: string;
  website: string;
  'email-domain': string;
  phone: string;
  name: string;
  id: string;
  'utc-date-created': string;
  'utc-date-modified': string;
  active: boolean;
}

export interface CompanyAPIInfo {
  version: string;
  action: string;
  path: string;
  format: string;
}
