import {
  ordersSortOrderEnum,
  ordersSortTypeEnum,
  ordersStatusEnum,
  ordersTypeEnum,
} from '../interfaces/order.interface';

export const ORDERS_MAX_ITEMS_PER_PAGE = 20;
export const ORDERS_DEFAULT_FILTER_ORDER_OPTION =
  ordersSortOrderEnum.descending;
export const ORDERS_DEFAULT_FILTER_SORT_OPTION = ordersSortTypeEnum.date;
export const ORDERS_DEFAULT_FILTER_TYPE_OPTION = ordersTypeEnum.active;
export const ORDERS_DEFAULT_FILTER_STATUS_OPTION = ordersStatusEnum.all;
export const ORDERS_CLOSING_EMORTAGE_TYPE_OPTION = [
  { name: 'Electronic (IPEN or RON)', id: 1 },
  { name: 'Electronic (IPEN only)', id: 2 },
  { name: 'Electronic (RON only)', id: 3 },
];
export const ORDERS_CLOSING_NON_EMORTAGE_TYPE_OPTION = [
  { name: 'Wet Ink Note', id: 4 },
  { name: 'All Wet Ink Closing', id: 5 },
];
export const ordersOrderOptions = [
  { value: ordersSortOrderEnum.ascending, name: 'Ascending' },
  { value: ordersSortOrderEnum.descending, name: 'Descending' },
];
export const ordersSortOptions = [
  { value: ordersSortTypeEnum.name, name: 'Name' },
  { value: ordersSortTypeEnum.date, name: 'Date' },
];
export const ordersTypeOptions = [
  { value: ordersTypeEnum.all, name: 'All' },
  { value: ordersTypeEnum.active, name: 'Active' },
  { value: ordersTypeEnum.archived, name: 'Archived' },
];

export const ordersStatusOption = [
  { value: ordersStatusEnum.all, name: 'All' },
  { value: ordersStatusEnum.draft, name: 'Draft' },
  { value: ordersStatusEnum.ready, name: 'Ready' },
  { value: ordersStatusEnum.signed, name: 'Signed' },
  { value: ordersStatusEnum.archived, name: 'Archived' },
  { value: ordersStatusEnum.canceled, name: 'Canceled' },
];

export const ALL_COMPANIES = [
  { id: 'FE2B4CFB-0001-4B55-A406-B9246B90C0EB', name: 'ACME Agency' },
  { id: 'FE2B4CFB-0004-4B55-A406-B9246B90C0EB', name: 'Best Home Mortgage' },
  { id: 'FE2B4CFB-9001-3A02-A406-B9246B90C0EB', name: 'BNT Test Group' },
  { id: 'FE2B4CFB-3001-4B55-A906-B9246B90C001', name: 'Boston National Title' },
  {
    id: 'FB2B4CFB-5001-0B06-A906-B9246B90C001',
    name: 'Brothers Bilingual Notary (BBN)',
  },
  {
    id: 'FE2B4CFB-0007-4B55-A406-B9246B90C0EB',
    name: 'Dev Best Home Mortgage',
  },
  { id: 'FE2B4CFB-0006-4B55-A406-B9246B90C0EB', name: 'Dev First Agent' },
  { id: 'FE2B4CFB-0008-4B55-A406-B9246B90C0EB', name: 'Dev Team Title' },
  {
    id: 'FE2B4CFB-2001-4B55-A906-B9246B90C001',
    name: 'Empire West Title Agency',
  },
  { id: 'FE2B4CFB-0009-4B55-A406-B9246B90C0EB', name: 'EscrowTab' },
  { id: 'FE3B4CFB-3101-4B55-A106-B9246B90C0EB', name: 'EscrowTab Dev' },
  {
    id: 'FE2B4CFB-1202-3A01-A626-B9246B90C0EB',
    name: 'EscrowTab MERS Integration Testing',
  },
  { id: 'FE3B4CFB-4102-9B55-A106-B9246B90C0EB', name: 'EscrowTab Testing' },
  { id: 'FE2B4CFB-2001-4B55-A106-B9246B90C0EB', name: 'ET Demos' },
  { id: 'EF2B4CFB-3D8B-3E04-A406-B9246B90C0EB', name: 'ET Inv Alpine Title' },
  {
    id: 'EF2B4CFB-3D8B-3E01-A406-B9246B90C0EB',
    name: 'ET Inv Empire Title Idaho',
  },
  {
    id: 'EF2B4CFB-3D8B-3E06-A406-B9246B90C0EB',
    name: 'ET Inv Flathead Premiere Title',
  },
  {
    id: 'EF2B4CFB-3D8B-3E02-A406-B9246B90C0EB',
    name: 'ET Inv Northwestern Title Co',
  },
  {
    id: 'EF2B4CFB-3D8B-3E03-A406-B9246B90C0EB',
    name: 'ET Inv Title Co Rockies',
  },
  {
    id: 'fe2b4afc-1501-5a03-a400-b9246b90c0eb',
    name: 'ET Inv TownSquare Title of Wyoming',
  },
  { id: 'EF2B4CFB-3D8B-3E05-A406-B9246B90C0EB', name: 'ET Inv Western Title' },
  { id: 'FE2B4CFB-1001-4B55-A406-B9246B90C0EB', name: 'ET Investments' },
  { id: 'FE2B4CFB-1101-3A02-A406-B9246B90C0EB', name: 'Federal Title' },
  { id: 'FE2B4CFB-0003-4B55-A406-B9246B90C0EB', name: 'First Agent' },
  { id: 'FE2B4CFB-0012-4B55-A406-B9246B90C0EB', name: 'First Lender' },
  { id: 'FE2B4CFB-0011-4B55-A406-B9246B90C0EB', name: 'First Title' },
  {
    id: 'FE2B4CFB-0002-4B55-A406-B9246B90C0EB',
    name: 'Glengarry Glen Ross Agency',
  },
  { id: 'FE2B4CFB-0015-4B55-A406-B9246B90C0EB', name: 'GSE' },
  {
    id: 'FE2B4AFB-1401-4A02-A400-B9246B90C0EB',
    name: 'Guaranteed Title Group',
  },
  { id: 'FE2B4AFB-1201-4A02-A400-B9246B90C0EB', name: 'Independent' },
  {
    id: 'D5ECCB3A-63E4-4D0D-99A6-08DA669D1C91',
    name: 'League of Independents',
  },
  { id: 'FE2B4CFB-0014-4B55-A406-B9246B90C0EB', name: 'MERS' },
  { id: 'FE2B4CFB-0013-4B55-A406-B9246B90C0EB', name: 'MISMO Demo' },
  { id: 'BE2B3CFB-3201-4B55-A906-B9246B90C001', name: 'Penny Mac' },
  { id: 'B9499E11-76DA-45D5-9092-EF023EFDF2F9', name: 'Pioneer Title Agency' },
  { id: 'FE2B4CFB-0005-4B55-A406-B9246B90C0EB', name: 'Team Title' },
  { id: 'FE2B4CFB-4001-0B05-A906-B9246B90C001', name: 'Test Lender' },
  {
    id: 'FE2B4AFC-1501-5A03-A400-B9246B90C0EB',
    name: 'TownSquare Title of Wyoming',
  },
  { id: 'FE2B4AFB-1301-4A02-A400-B9246B90C0EB', name: 'Union Bank and Trust' },
  { id: 'B9499E11-86DA-45D6-9093-EF023EFDF209', name: 'Universal Title' },
  { id: 'FE2B4CFB-0010-4B55-A406-B9246B90C0EB', name: 'Wells Fargo' },
];
