<!-- Single page tab -->
<div
  [routerLink]="routerLink"
  routerLinkActive="!text-primary-content !bg-midnight"
  class="flex items-center p-6 text-lg font-medium text-primary-content bg-neutral-secondary"
  [ngClass]="{
    '!text-primary-content !bg-midnight': isActive,
    'cursor-pointer': routerLink
  }"
>
  <ng-content></ng-content>
</div>
