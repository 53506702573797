import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@et/environment';
import { AddContadtDto, Contact } from '@et/typings';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  private http = inject(HttpClient);

  private readonly path = `${environment.profilev2.domain}/api/Contacts`;

  /**
   * Retrieves contacts by email.
   *
   * This method sends a GET request to retrieve contacts filtered by the provided email and user ID.
   *
   * @param {string} email - The email to filter contacts by.
   * @param {string} userId - The user ID associated with the contacts.
   * @returns {Observable<Contact[]>} An observable that emits the list of contacts.
   */
  getContactsByEmail(email: string, userId: string): Observable<Contact[]> {
    const params = new HttpParams().set('email', email).set('userId', userId);
    return this.http.get<Contact[]>(`${this.path}/FilteredContactsByEmail`, {
      params,
    });
  }

  /**
   * Retrieves contacts by name.
   *
   * This method sends a GET request to retrieve contacts filtered by the provided name and user ID.
   *
   * @param {string} name - The name to filter contacts by.
   * @param {string} userId - The user ID associated with the contacts.
   * @returns {Observable<Contact[]>} An observable that emits the list of contacts.
   */
  getContactsByName(name: string, userId: string): Observable<Contact[]> {
    const params = new HttpParams().set('name', name).set('userId', userId);
    return this.http.get<Contact[]>(`${this.path}/FilteredContactsByName`, {
      params,
    });
  }

  /**
   * Retrieves contacts by name or email.
   *
   * This method sends a GET request to retrieve contacts filtered by the provided name or email and user ID.
   *
   * @param {string} nameOrEmail - The name or email to filter contacts by.
   * @param {string} userId - The user ID associated with the contacts.
   * @returns {Observable<Contact[]>} An observable that emits the list of contacts.
   */
  getContactsByNameOrEmail(
    nameOrEmail: string,
    userId: string,
  ): Observable<Contact[]> {
    const params = new HttpParams()
      .set('filterWord', nameOrEmail)
      .set('userId', userId);
    return this.http.get<Contact[]>(
      `${this.path}/FilteredContactsByEmailOrName`,
      { params },
    );
  }

  /**
   * Retrieves contacts for the specified user.
   *
   * This method sends a GET request to retrieve contacts associated with the provided user ID.
   *
   * @param {string} userId - The user ID associated with the contacts.
   * @returns {Observable<Contact[]>} An observable that emits the list of contacts.
   */
  getUserContacts(userId: string): Observable<Contact[]> {
    return this.http.get<Contact[]>(`${this.path}/GetContactsByUser`, {
      params: { userId },
    });
  }

  /**
   * Adds a new contact.
   *
   * This method sends a POST request to add a new contact using the provided contact data.
   *
   * @param {AddContadtDto} contact - The contact data to be added.
   * @returns {Observable<Contact>} An observable that emits the added contact.
   */
  addContact(contact: AddContadtDto): Observable<Contact> {
    return this.http.post<Contact>(`${this.path}/AddContact`, contact);
  }

  /**
   * Deletes a contact by ID.
   *
   * This method sends a DELETE request to remove the contact associated with the provided contact ID.
   *
   * @param {string} contactId - The ID of the contact to be deleted.
   * @returns {Observable<void>} An observable that completes when the contact is deleted.
   */
  deleteContact(contactId: string): Observable<void> {
    return this.http.delete<void>(`${this.path}/DeleteContact/${contactId}`);
  }
}
