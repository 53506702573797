import {
  DraftCompany,
  DraftFile,
  DraftFlaggedPages,
  DraftMetadata,
  DraftUser,
} from './draft-api-model';

export interface DraftResponseAPI {
  data: DraftResponseDataAPI | DraftResponseDataAPI[] | number;
  errors?: unknown;
  message?: string;
  succeeded?: boolean;
}

export interface DraftResponseDataAPI {
  id: string;
  user: DraftUser;
  company: DraftCompany;
  files: DraftFileResponse[];
  metadata: DraftMetadata;
  totalFileSize: 0;
  flaggedPages: DraftFlaggedPages[];
}

interface DraftFileResponse extends DraftFile {
  url: string;
}
