import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { documentPDFPage } from '@et/typings';

@Component({
  selector: 'et-atoms-document-page',
  templateUrl: './document-page.component.html',
  styleUrls: ['./document-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPageComponent {
  @Input() page!: documentPDFPage;
  @Input() totalPages = 1;
  @Input() pageWidth!: number;
}
