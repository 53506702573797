import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'et-atoms-quick-link',
  templateUrl: './quick-link.component.html',
  styleUrls: ['./quick-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuickLinkComponent {
  @Input() isSelected = false;
  @Input() count: number | null = 0;
}
