export interface NotaryOnboarding {
  'date-issued': string;
  'date-expires': string;
  number: string;
  county: string;
  name: string;
  sosCertification?: File;
  x509Certification?: File;
  x509CertificationPassword?: string;
  x509CertificationPasswordConfirm?: string;
  state: string;
  type?: string;
  country?: string;
  code?: string;
}

export interface NotaryStamp {
  action: string;
  type: string;
  path: string;
}

export interface ClosingNotary {
  displayName: string;
  emailAddress: string;
  closings: number;
  distance: number;
}
