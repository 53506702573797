export interface selectOption {
  value: string;
  name: string;
  disabled: boolean;
}

export enum orderEnum {
  'ascending' = 'ascending',
  'descending' = 'descending',
}
export enum sortEnum {
  'name' = 'name',
  'date' = 'date',
}
export enum typeEnum {
  'all' = 'all',
  'active' = 'active',
  'archived' = 'archived',
}
export enum stateEnum {
  'all' = 'all',
  'signed' = 'signed',
  'unsigned' = 'unsigned',
}

export enum documentOptionsEnum {
  'pdf' = 'pdf',
  'eVault' = 'eVault',
  'auditLog' = 'auditLog',
  'ocr' = 'ocr',
  'archive' = 'archive',
}

export enum quickFiltersEnum {
  Draft = 1,
  InProgress = 2,
  Completed = 3,
  ENotes = 4,
}

export enum packetStatusEnum {
  'Draft' = 'Draft',
  'Ready' = 'Ready',
  'Signed' = 'Signed',
  'Archived' = 'Archived',
  'Canceled' = 'Canceled',
}

export enum tileButtonType {
  Archive = 'archive',
  Download = 'download',
  Duplicate = 'duplicate',
  Edit = 'edit',
  PostPrep = 'postPrep',
  Preview = 'preview',
  Reassign = 'reassign',
  Resend = 'resend',
  AuditLog = 'AuditLog',
  More = 'more',
  SecureLinks = 'secureLinks',
  Void = 'void',
}
