<!-- Zoom in -->
<button [disabled]="disabled" (click)="onZoomIn()">
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_30_1857)">
      <path
        d="M17.7075 11.2275H12.75V6.3075C12.75 6.10859 12.6709 5.91782 12.5303 5.77717C12.3896 5.63652 12.1989 5.5575 12 5.5575C11.8011 5.5575 11.6103 5.63652 11.4696 5.77717C11.329 5.91782 11.25 6.10859 11.25 6.3075V11.2575H6.29993C6.10101 11.2575 5.91031 11.3365 5.76965 11.4772C5.629 11.6178 5.54993 11.8086 5.54993 12.0075C5.54993 12.2064 5.629 12.3972 5.76965 12.5378C5.91031 12.6785 6.10101 12.7575 6.29993 12.7575H11.25V17.715C11.25 17.9139 11.329 18.1047 11.4696 18.2453C11.6103 18.386 11.8011 18.465 12 18.465C12.1989 18.465 12.3896 18.386 12.5303 18.2453C12.6709 18.1047 12.75 17.9139 12.75 17.715V12.7275H17.7075C17.9064 12.7275 18.0971 12.6485 18.2378 12.5078C18.3784 12.3672 18.4575 12.1764 18.4575 11.9775C18.4575 11.7786 18.3784 11.5878 18.2378 11.4472C18.0971 11.3065 17.9064 11.2275 17.7075 11.2275Z"
        [attr.fill]="disabled ? '#C6CCC6' : '#00303C'"
      />
      <path
        d="M11.9999 0C9.62655 0 7.30644 0.703787 5.33305 2.02236C3.35966 3.34094 1.8216 5.21508 0.913346 7.4078C0.00509429 9.60051 -0.232569 12.0133 0.230454 14.3411C0.693477 16.6689 1.8364 18.8071 3.51463 20.4853C5.19286 22.1635 7.33106 23.3064 9.65883 23.7694C11.9866 24.2324 14.3994 23.9948 16.5921 23.0866C18.7848 22.1783 20.6589 20.6402 21.9775 18.6668C23.2961 16.6935 23.9999 14.3734 23.9999 12C23.9999 8.8174 22.7356 5.76515 20.4851 3.51472C18.2347 1.26428 15.1825 0 11.9999 0V0ZM11.9999 22.5C9.92322 22.5 7.89308 21.8842 6.16637 20.7304C4.43965 19.5767 3.0939 17.9368 2.29918 16.0182C1.50446 14.0996 1.29647 11.9884 1.70161 9.95155C2.10676 7.91475 3.10682 6.04383 4.57527 4.57538C6.04372 3.10693 7.91463 2.1069 9.95143 1.70176C11.9882 1.29661 14.0995 1.50455 16.0181 2.29927C17.9367 3.09399 19.5765 4.4398 20.7303 6.16651C21.8841 7.89323 22.4999 9.9233 22.4999 12C22.4999 14.7848 21.3936 17.4555 19.4245 19.4246C17.4554 21.3938 14.7847 22.5 11.9999 22.5V22.5Z"
        [attr.fill]="disabled ? '#C6CCC6' : '#00303C'"
      />
    </g>
    <defs>
      <clipPath id="clip0_30_1857">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</button>

<!-- Zoom out -->
<button [disabled]="disabled" (click)="onZoomOut()">
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_30_1858)">
      <path
        d="M17.7075 11.2275C10.8098 11.2275 13.668 11.2575 6.29993 11.2575C6.10101 11.2575 5.91031 11.3365 5.76965 11.4772C5.629 11.6178 5.54993 11.8086 5.54993 12.0075C5.54993 12.2064 5.629 12.3972 5.76965 12.5378C5.91031 12.6785 6.10101 12.7575 6.29993 12.7575C13.7152 12.7575 10.7767 12.7275 17.7075 12.7275C17.9064 12.7275 18.0971 12.6485 18.2378 12.5078C18.3784 12.3672 18.4575 12.1764 18.4575 11.9775C18.4575 11.7786 18.3784 11.5878 18.2378 11.4472C18.0971 11.3065 17.9064 11.2275 17.7075 11.2275Z"
        [attr.fill]="disabled ? '#C6CCC6' : '#00303C'"
      />
      <path
        d="M11.9999 0C9.62655 0 7.30644 0.703787 5.33305 2.02236C3.35966 3.34094 1.8216 5.21508 0.913346 7.4078C0.00509429 9.60051 -0.232569 12.0133 0.230454 14.3411C0.693477 16.6689 1.8364 18.8071 3.51463 20.4853C5.19286 22.1635 7.33106 23.3064 9.65883 23.7694C11.9866 24.2324 14.3994 23.9948 16.5921 23.0866C18.7848 22.1783 20.6589 20.6402 21.9775 18.6668C23.2961 16.6935 23.9999 14.3734 23.9999 12C23.9999 8.8174 22.7356 5.76515 20.4851 3.51472C18.2347 1.26428 15.1825 0 11.9999 0ZM11.9999 22.5C9.92322 22.5 7.89308 21.8842 6.16637 20.7304C4.43965 19.5767 3.0939 17.9368 2.29918 16.0182C1.50446 14.0996 1.29647 11.9884 1.70161 9.95155C2.10676 7.91475 3.10682 6.04383 4.57527 4.57538C6.04372 3.10693 7.91463 2.1069 9.95143 1.70176C11.9882 1.29661 14.0995 1.50455 16.0181 2.29927C17.9367 3.09399 19.5765 4.4398 20.7303 6.16651C21.8841 7.89323 22.4999 9.9233 22.4999 12C22.4999 14.7848 21.3936 17.4555 19.4245 19.4246C17.4554 21.3938 14.7847 22.5 11.9999 22.5Z"
        [attr.fill]="disabled ? '#C6CCC6' : '#00303C'"
      />
    </g>
    <defs>
      <clipPath id="clip0_30_1858">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</button>
