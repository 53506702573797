import { ProfileDto } from '@escrowtab/v2profile-api-client';
import { GetFullProfile, SetFullProfile } from '@et/guards';
import { Injectable } from '@angular/core';

import { ProfileState } from '@et/guards';
import { Store } from '@ngxs/store';
import {
  map,
  Observable,
  filter,
  first,
  switchMap,
  of,
  combineLatest,
} from 'rxjs';
import { DatabaseService } from '@et/storage';
import { isOnline } from '@et/utils';

@Injectable({
  providedIn: 'root',
})
export class FullProfileResolver {
  constructor(private store: Store, private dbService: DatabaseService) {}

  resolve(): Observable<boolean> {
    const profileFromAPI = this.store.select(ProfileState.getFullProfile);
    const profileFromClaims = this.store.select(
      ProfileState.getAadProfileWithClaims,
    );

    return combineLatest([profileFromClaims, profileFromAPI]).pipe(
      filter(([profileFromClaims]) => !!profileFromClaims),
      map(([, profile]) => {
        if (!profile) {
          const addClaims = this.store.selectSnapshot(
            ProfileState.getAadProfileWithClaims,
          );
          if (isOnline()) {
            this.store.dispatch(new GetFullProfile(addClaims));
          } else {
            this.loadFromDB(addClaims?.idTokenClaims?.oid as string);
          }
        }
        return profile;
      }),
      filter((profile) => !!profile),
      switchMap((profile) => {
        return isOnline()
          ? this.dbService.profileService.saveAll([profile as ProfileDto])
          : of(profile);
      }),
      map((profile) => !!profile),
      first(),
    );
  }
  /**
   * load profile from db
   *
   * @param {string} id
   * @memberof FullProfileResolver
   */
  async loadFromDB(id: string) {
    const profile = await this.dbService.profileService.get(id);
    this.store.dispatch(new SetFullProfile(profile as ProfileDto));
  }
}
