import { Injectable } from '@angular/core';
import { isOnline } from '@et/utils';
import { AnnotationRepositoryService } from './repositories/annotation-repository.service';
import { AuditActionsRepositoryService } from './repositories/audit-actions-repository.service';
import { DansRepositoryService } from './repositories/dans-repository.service';
import { DocumentsTrackerService } from './repositories/documents-tracker.service';
import { EnoteEntityService } from './repositories/enote-entity.service';
import { EnoteFilesRepositoryService } from './repositories/enote-files-repository.service';
import { MarkupRepositoryService } from './repositories/markup-repository.service';
import { PacketRepositoryService } from './repositories/packet-repository.service';
import { ProfileRepositoryService } from './repositories/profile-repository.service';
import { StampsRepositoryService } from './repositories/stamps-repository.service';
import { StorageService } from './storage.service';
import { EnoteImagesRepositoryService } from './repositories/enote-images-repository.service';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  constructor(
    public annotations: AnnotationRepositoryService,
    public packets: PacketRepositoryService,
    public markup: MarkupRepositoryService,
    public eNoteFiles: EnoteFilesRepositoryService,
    public eNoteEntities: EnoteEntityService,
    public eNoteImages: EnoteImagesRepositoryService,
    public auditActions: AuditActionsRepositoryService,
    public documentsTracker: DocumentsTrackerService,
    public stampService: StampsRepositoryService,
    public profileService: ProfileRepositoryService,
    public dans: DansRepositoryService,
    private storageService: StorageService,
  ) {}

  /**
   * DB connection is open
   * @type boolean
   */
  get isService() {
    return this.storageService.isService;
  }

  /**
   * Opens DB connection
   */
  async init() {
    this.storageService.init();
    await this.storageService.openStore();
  }

  /**
   * Removes all data from all tables for passed user.
   */
  async clearDB(userId: string) {
    await this.annotations.clear();
    await this.packets.clear();
    await this.markup.clear();
    await this.stampService.clear();
    await this.profileService.clear();
    await this.eNoteFiles.clear();
    await this.auditActions.clear();
    await this.dans.clear();
    await this.eNoteEntities.clear();
    await this.eNoteImages.clear();
    await this.documentsTracker.clearByUserId(userId);
  }

  /**
   * Removes entire db and sets it back with dammy data
   */
  async deleteDB() {
    // Storage doesn't support deleteStore on web
    // deleteStore doesn't work on UWP
    if (
      this.storageService.platform === 'web' ||
      this.storageService.platform === 'electron'
    ) {
      await this.annotations.clear();
      await this.packets.clear();
      await this.markup.clear();
      await this.stampService.clear();
      await this.profileService.clear();
      await this.eNoteFiles.clear();
      await this.auditActions.clear();
      await this.dans.clear();
      await this.eNoteEntities.clear();
      await this.eNoteImages.clear();
      await this.documentsTracker.clearAll();
      return;
    }
    try {
      await this.storageService.deleteStore();
      await this.storageService.openStore();
      await this.setDummyValues();
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Deletes all records by id in:
   * - Packets table
   * - Annotations table
   * - Markup table
   * - Stamps table
   * - eNote files table
   * - Audit actions table
   * - Documents tracking table
   * - eNote entities table
   * - Dans table
   * - eNote images table
   * @param {string} id - packet id
   */
  async deleteAllById(id: string) {
    await this.packets.delete(id);
    await this.annotations.delete(id);
    await this.markup.delete(id);
    await this.markup.deleteDefault(id);
    await this.stampService.delete(id);
    await this.profileService.delete(id);
    await this.eNoteFiles.delete(id);
    await this.auditActions.delete(id);
    await this.eNoteEntities.delete(id);
    await this.eNoteImages.delete(id);
    // Delete document tracker and dans only with internet,
    // We neet to release unused dans on cleanup
    if (isOnline()) {
      await this.documentsTracker.delete(id);
      await this.dans.delete(id);
    } else {
      // Set tracker for deletion
      await this.markDocumentForDeletions(id);
    }
  }

  /**
   * Adds dummy values to each table
   * Needs for electorn to work
   */
  async setDummyValues() {
    try {
      await this.packets.addDummy();
      await this.annotations.addDummy();
      await this.markup.addDummy();
      await this.stampService.addDummy();
      await this.profileService.addDummy();
      await this.eNoteFiles.addDummy();
      await this.auditActions.addDummy();
      await this.documentsTracker.addDummy();
      await this.dans.addDummy();
      await this.eNoteEntities.addDummy();
      await this.eNoteImages.addDummy();
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Creates tables in DB
   */
  async setTables() {
    await this.packets.setTable();
    await this.annotations.setTable();
    await this.markup.setTable();
    await this.stampService.setTable();
    await this.profileService.setTable();
    await this.eNoteFiles.setTable();
    await this.auditActions.setTable();
    await this.documentsTracker.setTable();
    await this.dans.setTable();
    await this.eNoteEntities.setTable();
    await this.eNoteImages.setTable();
  }

  /**
   * Mark document for deletion
   * Used when internet needed when deleting itams from db
   * @param {string} id - packetId
   */
  private async markDocumentForDeletions(id: string) {
    const tracker = await this.documentsTracker.get(id);
    if (tracker) {
      await this.documentsTracker.update(id, {
        ...tracker,
        markedForDeletion: true,
      });
    }
  }
}
