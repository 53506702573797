import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@et/environment';
import {
  DraftBodyAPI,
  DraftResponseAPI,
  DraftResponseDataAPI,
} from '../models';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DraftApiService {
  private readonly draftPath = '/drafts';

  constructor(private http: HttpClient) {}

  /**
   * Returns draft by draft id
   * @param {String} draftId - Id of the link
   *
   * @memberof DraftApiService
   */
  getDraftById(draftId: string): Observable<DraftResponseDataAPI> {
    const url = environment.apiOrders.domain + this.draftPath + '/' + draftId;
    return this.http
      .get<DraftResponseAPI>(url)
      .pipe(map((res) => res.data as DraftResponseDataAPI));
  }

  /**
   * Deletes draft by draft id
   * @param {String} draftId - Id of the link
   *
   * @memberof DraftApiService
   */
  deleteDraft(draftId: string): Observable<void> {
    const url = environment.apiOrders.domain + this.draftPath + '/' + draftId;
    return this.http.delete<void>(url);
  }

  /**
   * Returns drafts by user id
   * @param {String} userId - Id of the link
   *
   * @memberof DraftApiService
   */
  getDraftsByUserId(userId: string): Observable<DraftResponseDataAPI[]> {
    const url =
      environment.apiOrders.domain + this.draftPath + `/user/${userId}`;
    return this.http
      .get<DraftResponseAPI>(url)
      .pipe(map((res) => res.data as DraftResponseDataAPI[]));
  }

  /**
   * Returns drafts by company id
   * @param {String} companyId - Id of the link
   *
   * @memberof DraftApiService
   */
  getDraftsByCompanyId(companyId: string): Observable<DraftResponseDataAPI[]> {
    const url =
      environment.apiOrders.domain + this.draftPath + `/company/${companyId}`;
    return this.http
      .get<DraftResponseAPI>(url)
      .pipe(map((res) => res.data as DraftResponseDataAPI[]));
  }

  /**
   * Returns observable of drafts count
   * @param {String} userId - Id of the link
   * @param {String} companyId - Id of the link
   *
   * @memberof DraftApiService
   */
  getDraftsCount(userId: string, companyId: string): Observable<number> {
    const params = new HttpParams()
      .append('userId', userId)
      .append('companyId', companyId);

    const url = environment.apiOrders.domain + this.draftPath + `/count`;
    return this.http
      .get<DraftResponseAPI>(url, { params })
      .pipe(map((res) => res.data as number));
  }

  /**
   * Creates a new drafts and returns observable of created draft
   * @param {DraftBodyAPI} data - DraftBodyAPI
   * @param {File[]} files - File[]
   *
   * @memberof DraftApiService
   */
  addDraft(data: DraftBodyAPI, files: File[]) {
    const url = environment.apiOrders.domain + this.draftPath;
    const formData = new FormData();

    formData.append('data', JSON.stringify(data));
    files.forEach((file) => {
      formData.append('files', file, file.name);
    });

    return this.http
      .post<DraftResponseAPI>(url, formData)
      .pipe(map((res) => res.data as DraftResponseDataAPI));
  }

  /**
   * Takes file url, name and returns downloaded File object
   * @param {String} fileUrl - File url
   * @param {String} fileName - File name
   *
   * @memberof DraftApiService
   */
  getFileByUrl(fileUrl: string, fileName: string) {
    const url = environment.apiOrders.domain + this.draftPath + '/file';
    const params = new HttpParams().append('url', fileUrl);
    return this.http
      .get(url, { responseType: 'arraybuffer', params })
      .pipe(
        map((data) => new File([data], fileName, { type: 'application/pdf' })),
      );
  }

  /**
   * Updates draft and returns observable of updated draft
   * @param {DraftBodyAPI} data - DraftBodyAPI
   * @param {File[]} files - File[]
   *
   * @memberof DraftApiService
   */
  updateDraft(draftId: string, data: DraftBodyAPI, files: File[]) {
    const url = environment.apiOrders.domain + this.draftPath + '/' + draftId;
    const formData = new FormData();

    formData.append('data', JSON.stringify(data));
    files.forEach((file) => {
      formData.append('files', file, file.name);
    });

    return this.http
      .put<DraftResponseAPI>(url, formData)
      .pipe(map((res) => res.data as DraftResponseDataAPI));
  }
}
