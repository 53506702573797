import { Injectable } from '@angular/core';
import { StorageService } from '../storage.service';
import { ENoteSignature } from '@et/typings';

@Injectable({
  providedIn: 'root',
})
export class EnoteImagesRepositoryService {
  protected _tableName = 'enote_images';

  constructor(private storage: StorageService) {}

  /**
   * This function sets initial dummy value in the table
   */
  async addDummy() {
    try {
      await this.setTable();
      await this.storage.setItem('0', '1');
    } catch (error) {
      console.error('EnoteImagesRepositoryService: ', error);
      return;
    }
  }

  /**
   * This function return all values from storage
   */
  async getAll(): Promise<Map<string, ENoteSignature>[]> {
    try {
      await this.setTable();
      const eNoteSignObjJSONArrWithDummy = await this.storage.getAllValues();
      // Remove dummy
      const eNoteSignObjJSONArr = eNoteSignObjJSONArrWithDummy.filter(
        (a) => a !== '1',
      );
      const eNoteSignatures: Map<string, ENoteSignature>[] = [];
      for (const eNoteSignObjJSON of eNoteSignObjJSONArr) {
        const eNoteSignObj = JSON.parse(eNoteSignObjJSON);
        const eNoteSign = new Map<string, ENoteSignature>(
          Object.entries(eNoteSignObj),
        );
        eNoteSignatures.push(eNoteSign);
      }
      return eNoteSignatures;
    } catch (error) {
      console.error('EnoteImagesRepositoryService: ', error);
      return [];
    }
  }

  /**
   * This function gets ENoteSignature Map by id
   * @param {string} id - packet id
   */
  async get(id: string): Promise<Map<string, ENoteSignature> | null> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return null;
      }
      const eNoteSignObjJSON = await this.storage.getItem(id);
      const eNoteSignObj = JSON.parse(eNoteSignObjJSON);
      const eNoteSign = new Map<string, ENoteSignature>(
        Object.entries(eNoteSignObj),
      );
      return eNoteSign;
    } catch (error) {
      console.error('EnoteImagesRepositoryService: ', error);
      return null;
    }
  }

  /**
   * This function adds new ENoteSignature Map to storage
   * @param {string} id - eNote id
   * @param {Map<string, ENoteSignature>} signatureMap - v1Enote
   */
  async createOrUpdate(
    id: string,
    signatureMap: Map<string, ENoteSignature>,
  ): Promise<void> {
    try {
      await this.setTable();
      const eNoteSignObj = Object.fromEntries(signatureMap);
      const eNoteSignObjJSON = JSON.stringify(eNoteSignObj);
      await this.storage.setItem(id, eNoteSignObjJSON);
    } catch (error) {
      console.error('EnoteImagesRepositoryService: ', error);
    }
  }

  /**
   * This function deletes ENoteSignature Map in storage
   * @param {string} id - Packet id
   */
  async delete(id: string): Promise<void> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return;
      }
      await this.storage.removeItem(id);
    } catch (error) {
      console.error('EnoteImagesRepositoryService: ', error);
    }
  }

  /**
   * This function deletes all keys
   */
  async clear(): Promise<void> {
    try {
      await this.setTable();
      await this.storage.clear();
      await this.addDummy();
    } catch (error) {
      console.error('EnoteImagesRepositoryService: ', error);
    }
  }

  /**
   * This function sets storage table
   */
  async setTable() {
    await this.storage.setTable(this._tableName);
  }
}
