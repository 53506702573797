<div
  class="flex flex-wrap box-border relative pt-4 pl-4 pr-4 z-10 text-center h-full bg-base-200 min-w-[152px] rounded-lg disabled-color"
  [style.width.px]="pageWidth"
  [style.min-height.px]="pageWidth * 1.58 > 267 ? pageWidth * 1.58 : 267"
>
  <img
    *ngIf="page.isFlagged"
    src="assets/icons/flag-blue.svg"
    alt="Flag"
    class="absolute w-6 h-6 m-0 bg-white rounded-full top-6 left-6"
  />
  <img
    class="m-0 pointer-events-none"
    width="100%"
    height="auto"
    [src]="page.thumbnail"
  />
  <div
    class="relative flex items-center justify-center w-full h-10 mt-auto mb-0"
  >
    <span class="text-xs font-medium">Pg. {{ page.currentPageNumber }}</span>
  </div>
</div>
