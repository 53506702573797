import { SafeUrl } from '@angular/platform-browser';
import { Dan, Stamp } from '@escrowtab/v2profile-api-client';

export interface AADUserWithClaims {
  homeAccountId: string;
  environment: string;
  tenantId: string;
  username: string;
  localAccountId: string;
  name: string;
  idTokenClaims: Partial<IDTokenClaims>;
}

export interface IDTokenClaims {
  exp: number;
  nbf: number;
  ver: string;
  iss: string;
  sub: string;
  aud: string;
  acr: string;
  nonce: string;
  iat: number;
  auth_time: number;
  oid: string;
  name: string;
  extension_et_user_type: string;
  extension_et_profile_id: string;
  extension_et_company_id: string;
  extension_et_is_company_admin: boolean;
  emails: string[];
  at_hash: string;
}

export interface EJournalBody {
  id?: string;
  state: string;
  feeCharged: number;
  utcCreatedOn: Date;
  thirdPartyId?: 'Stripe';
  thirdPartyVerificationId?: string;
  profileId: string;
  packetId: string;
  signers: EJournalBodySigner[];
  data?: string;
}

interface EJournalBodySigner {
  name?: string;
  state?: string;
  idNumber?: string;
  idType?: string;
  address?: string;
  signatureImg?: string;
  utcIdIssuedOn?: string;
  utcIdExpiresOn?: string;
}

export interface ETJournalAPIResponse {
  data: EJournalBody[] | EJournalBody;
  message: string;
  modelErrors: string;
  status: boolean;
  statusCode: number;
}

export interface EJournalEntry extends EJournalBody {
  documentName: string;
  notaryName: string;
  signersCount: number;
  stateAbbreviation: string;
  stamps?: number;
  stampsDetails: EJournalEntryStampDetails[];
}

export interface EJournalEntryStampDetails {
  page?: number;
  type?: string;
  description: string;
  ['notarial-act']?: string;
  dan?: Dan;
}

export interface StampWithImage extends Stamp {
  image: string | SafeUrl | null;
}

export interface CompanyResponse {
  statusCode: number;
  status: boolean;
  message: any;
  data: CompanyData;
  modelErrors: any;
}

export interface CompanyData {
  companyId: string;
  name: string;
  emailDomain: string;
  companyType: number;
  isActive: boolean;
  enoteEnabled: boolean;
  elsiEnabled: boolean;
  autoTagOnboarding: boolean;
  hqState: any;
  iDverify: boolean;
}
