import * as dayjs from 'dayjs';

/**
 * Generate a unique ID
 * @returns A random string of characters.
 *
 * @memberof Utils
 */
export function uid() {
  return Date.now().toString(36) + Math.random().toString(36);
}

/**
 * It takes a File object and returns a Promise that resolves to a string, ArrayBuffer, or null
 * @param {File} file - The file to be uploaded.
 * @returns A Promise.
 *
 * @memberof Utils
 */
export function readFileAsync(
  file: File,
): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

/**
 * It takes a File object and returns a Promise that resolves to a DataURL
 * @param {File} file - The file to be uploaded.
 * @returns A Promise.
 *
 * @memberof Utils
 */
export function convertFileToDataURLAsync(
  file: File,
): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

/**
 * It takes a URL, a name, and a default type, and returns a File object
 * @param {string} url - The URL of the file you want to download.
 * @param {string} name - The name of the file.
 * @param [defaultType=application/pdf] - The default type of the file.
 * @returns A file object
 */
export async function getFileFromUrl(
  url: string,
  name: string,
  defaultType = 'application/pdf',
) {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
}

/**
 * It downloads a file to the user's device
 * @param {File} file - The file to download
 * @param {string} fileName - The name of the file you want to save.
 * @returns A promise that resolves when the file has been downloaded.
 */
export const downloadFile = async (file: File, fileName: string) => {
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(file);
  link.download = fileName;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 700);
  return Promise.resolve();
};

/**
 * make a mutatable copy of an object
 * @param {T} items - The items to be parsed.
 * @returns The original object in mutatable format.
 */
export function fastParse<T>(items: T): T {
  return JSON.parse(JSON.stringify(items));
}

/**
 * Loads a JavaScript file and returns a promise that resolves to the script element
 * @param {string} src - The URL of the script to load.
 * @returns A promise<HTMLScriptElement>.
 */
export function loadJsScript(src: string): Promise<HTMLScriptElement> {
  return new Promise((resolve, reject) => {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = src;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
    script.onload = () => {
      resolve(script);
    };
    script.onerror = (err) => {
      console.error(err);
      reject(err);
    };
  });
}

/**
 * Is the given value a boolean?
 * @param {T} val - T
 * @returns A boolean value.
 */
export function isBool<T>(val: T): boolean {
  return typeof val === 'boolean';
}

/**
 * It takes a File object and returns Promise of base64 string
 * @param {File} file - The file to be uploaded.
 * @returns A promise<String>.
 *
 * @memberof Utils
 */
export function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64Full = reader.result as string;
      const base64 = base64Full.split(',').pop();
      resolve(base64 as string);
    };
    reader.onerror = (error) => reject(error);
  });
}

/**
 * Capitalize the first letter of a string
 * @param {string} str - The string to capitalize.
 */
export function capitalize(str: string) {
  return str && str[0].toUpperCase() + str.slice(1);
}

/**
 * It generates a list of time stamps.
 * @param {number} step - The number of minutes between each time stamp.
 * @returns An array of strings.
 */
export function generateTimeSeries(step: number, includeCurrent = false) {
  const dt = new Date(1970, 0, 1);
  let rc = [];
  while (dt.getDate() === 1) {
    const time = dt.toLocaleTimeString([]).replace(':00 ', ' ');
    rc.push(time);
    dt.setMinutes(dt.getMinutes() + step);
  }
  if (includeCurrent) {
    const current = dayjs().format('h:mm A');
    rc = [current, ...rc];
  }
  return rc;
}

/**
 * Given a date, return true if the date is before the current date
 * @param {Date} date - The date to check.
 * @returns A boolean value.
 */
export function isDateBeforeCurrentDate(date: Date) {
  const timeNow = new Date().getTime();
  const timeCompare = new Date(date).getTime();
  return timeCompare < timeNow;
}

/**
 * It takes a string and an array of search words, and returns true if all of the search words are
 * found in the string
 * @param {string} text - The text to search in
 * @param {string[]} searchWords - an array of strings to search for
 */
export const multiSearchAnd = (text: string, searchWords: string[]) =>
  searchWords.every((el) => {
    return text.match(new RegExp(el, 'i'));
  });

/**
 * Return an array of numbers from start to end, incrementing by step.
 * @param {number} start - The first number in the range
 * @param {number} end - The end of the range (exclusive).
 * @param [step=1] - The step value. Default is 1.
 * @returns number[]
 */
export const range = (start: number, end: number, step = 1): number[] => {
  return Array.from(
    Array.from(Array(Math.ceil((end - start) / step)).keys()),
    (x) => start + x * step,
  );
};

/**
 * Parses error object and gets error message
 * @param {Error | string} error - Error object Error | String
 * @returns string
 */
export function parseErrorObject(error: any): string {
  // Error is a string
  let errorMsg = error || 'Server error';
  // Error has errors object
  if (error.error && error.error.errors) {
    errorMsg = Object.keys(error.error.errors).map(
      (key) => error.error.errors[key],
    )[0];
  }
  // Error has error object with message
  if (error.error && error.error.message) {
    errorMsg = error.error.message;
  }

  // Handle status errors
  if (error.status) {
    switch (error.status) {
      case 401:
        errorMsg = 'Not Authorized';
        break;
      case 404:
        errorMsg = 'Not Found';
        break;
      case 500:
        errorMsg = 'Server error';
        break;
    }
  }
  return errorMsg;
}

/**
 * Return true if the number is between the range start and range end, or if the range end is less than
 * the number and the number is less than the range start.
 * @param {number} num - The number to check if it's in range.
 * @param {number} rangeStart - The start of the range.
 * @param [rangeEnd=0] - The end of the range. If this is not provided, the range will be from 0 to
 * rangeStart.
 */
export const inRange = (num: number, rangeStart: number, rangeEnd = 0) =>
  (rangeStart < num && num < rangeEnd) || (rangeEnd < num && num < rangeStart);

/**
 * Make csv table from array of objects and download it as a CSV file.
 * Pass header as the first object with the value as columg name
 * @param {String} filename - The name of the file without file extension
 * @param {{ [key: string]: string }[]} rows - The array of object for csv export.
 */
export const downloadCSV = (
  filename: string,
  rows: { [key: string]: string }[],
) => {
  // convert JSON to CSV
  const replacer = (_: string, value: string) => (value === null ? '' : value);
  // specify how you want to handle null values here
  const header = Object.keys(rows[0]);
  const csv = [
    ...rows.map((row: any) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(','),
    ),
  ].join('\r\n');

  // Create link and download
  const link = document.createElement('a');
  link.setAttribute(
    'href',
    'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv),
  );
  link.setAttribute('download', filename + '.csv');
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * Is parses JSON and returns false if JSON is not valid
 * @param {String} str - String
 * @returns String | false.
 */
export function isJSON(str: string) {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
}

/**
 * Is copies passed text to clipboard
 * @param {String} text - String
 * @returns Promise<void>.
 */
export function copyToClipboard(text: string) {
  return new Promise<void>((resolve, reject) => {
    if (!navigator.clipboard) {
      reject('Error occurred');
      return;
    }
    navigator.clipboard.writeText(text).then(
      () => resolve(),
      (err) => reject(err),
    );
  });
}

/**
 * It takes a dataURL and a wanted width, and returns a promise that resolves to a new dataURL with the
 * wanted width
 * @param {string} datas - The data URI of the image you want to resize.
 * @param {number} wantedWidth - The width you want the image to be.
 * @returns A promise that resolves to a string
 */
export function resizedataURL(
  datas: string,
  wantedWidth: number,
): Promise<string> {
  return new Promise(async function (resolve, reject) {
    // We create an image to receive the Data URI
    var img = document.createElement('img');

    // When the event "onload" is triggered we can resize the image.
    img.onload = function () {
      // We create a canvas and get its context.
      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');

      const defaultHeight =
        wantedWidth * ((img?.height || 1) / (img?.width || 1));

      // We set the dimensions at the wanted size.
      canvas.width = wantedWidth;
      canvas.height = defaultHeight;

      // We resize the image with the canvas method drawImage();
      ctx?.drawImage(img, 0, 0, wantedWidth, defaultHeight);

      var dataURI = canvas.toDataURL();

      // This is the return of the Promise
      resolve(dataURI);
    };

    img.onerror = function (err: string | Event) {
      console.error(err);
      reject(err);
    };

    // We put the Data URI in the image's src attribute
    img.src = datas;
  });
}

/**
 * It takes a svg string and width and height as opt, and returns a promise that resolves to a new dataURL
 * @param {string} svgStr - The svg string
 * @param {{ width: number; height: number }} opt - The width and height of image
 * @returns A promise that resolves to a string
 */
export function convertSvgToDataURL(
  svgStr: string,
  opt?: { width: number; height: number; type: 'png' | 'svg' },
) {
  return new Promise<string>((resolve) => {
    const svg = new Blob([svgStr], {
      type: 'image/svg+xml;charset=utf-8',
    });

    const url = URL.createObjectURL(svg);
    const img = new Image();

    img.onload = function () {
      const canvas = document.createElement('canvas');
      if (opt) {
        canvas.width = opt.width;
        canvas.height = opt.height;
      }
      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
      ctx.drawImage(img, 0, 0);
      const dataURI = canvas.toDataURL();
      resolve(dataURI);
    };

    img.src = url;
  });
}

/**
 * This function checks if a given PDF file is encrypted or not.
 * @param {File} file - The `file` parameter is a `File` object representing a PDF file that needs
 * to be checked for encryption.
 * @returns A Promise that resolves to a boolean value indicating whether the provided PDF file is
 * encrypted or not.
 */
export function checkEncryptionForPdf(file: File) {
  return new Promise<boolean>((resolve) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = function () {
      const files = new Blob([reader.result as string], {
        type: 'application/pdf',
      });
      files?.text &&
        files?.text().then((x) => {
          let isEncrypted =
            x.includes('Encrypt') ||
            x
              .substring(x.lastIndexOf('<<'), x.lastIndexOf('>>'))
              .includes('/Encrypt');
          resolve(isEncrypted);
        });
    };
  });
}

/**
 * The function "getDaysAgo" takes a number of days as input and returns the date that many days ago in
 * ISO format.
 * @param {number} days - The `days` parameter is a number that represents the number of days ago from
 * today's date.
 * @returns a string representation of the date that is `days` ago from the current date.
 */
export function getDaysAgo(days: number): string {
  const today = dayjs();
  return today.subtract(days, 'day').toISOString();
}

/**
 * The function `getDateDiffs` calculates the difference in time between two given dates.
 * @param {number} date1 - The `date1` parameter is a number representing a date in milliseconds since
 * the Unix Epoch.
 * @param {number} date2 - The `date2` parameter is the second date that you want to compare with
 * `date1` to calculate the difference between the two dates.
 * @returns the difference in milliseconds between the two input dates.
 */
export function getDateDiffs(date1: number, date2: number) {
  const date1Time = dayjs(date1);
  const date2Time = dayjs(date2);
  return date1Time.diff(date2Time);
}

export const loadSvg = (file: File) => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = function () {
      const files = new Blob([reader.result as string], {
        type: 'application/pdf',
      });

      resolve(files?.text());
    };
  });
};

/**
 * It convert UTC date to local format
 */
export function utcToLocal(date: string | undefined | null) {
  return new Date(date?.replace('Z', '') + 'Z');
}

/**
 * The `awaitTimeout` function returns a promise that resolves after a specified delay.
 * @param {number} delay - The `delay` parameter is a number that represents the amount of time in
 * milliseconds to wait before resolving the promise.
 */
export const awaitTimeout = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay));

/**
 * This function generates a matrix ID based on the provided x and y coordinates.
 * Both x and y must be less than 1, otherwise an error is thrown.
 *
 * @param {number} x - The x-coordinate. Must be less than 1.
 * @param {number} y - The y-coordinate. Must be less than 1.
 *
 * @returns {string} The generated matrix ID in the format 'x_{x}-y_{y}'.
 * @throws {Error} If x or y is greater than 1.
 */
export const makeMatrixId = (x: number, y: number): string => {
  if (x > 1 || y > 1) {
    throw new Error('x and y must be less than 1');
  }
  return `x_${x}-y_${y}`;
};

/**
 * This function parses a matrix ID and returns the x and y coordinates as numbers.
 * The matrix ID must be in the format 'x_{x}-y_{y}'.
 * If the matrix ID does not match this format, null is returned.
 *
 * @param {string} matrixId - The matrix ID to parse.
 *
 * @returns {{ x: number; y: number } | null} An object containing the x and y coordinates as numbers,
 * or null if the matrix ID does not match the expected format.
 */
export const parseMatrixId = (
  matrixId: string,
): { x: number; y: number } | null => {
  const regex = /x_(\d+(\.\d+)?)-y_(\d+(\.\d+)?)/;
  const match = matrixId.match(regex);

  if (match) {
    const x = parseFloat(match[1]);
    const y = parseFloat(match[3]);
    return { x, y };
  } else {
    return null;
  }
};

/**
 * The function `downloadFileFromDataUrl` downloads a file from a Data URL by converting the file to a
 * Data URL asynchronously and creating a temporary link element to trigger the download.
 * @param {File} file - The `file` parameter in the `downloadFileFromDataUrl`
 * @returns The `downloadFileFromDataUrl` function is returning a Promise.
 */
export const downloadFileFromDataUrl = (file: File) => {
  return convertFileToDataURLAsync(file).then((dataURL) => {
    const l = document.createElement('a');
    l.href = dataURL as string;
    l.download = file.name;
    l.click();
    l.remove();
  });
};

export function uniqBy<T>(arr: T[], key1: keyof T, key2: keyof T): T[] {
  const seen: { [key: string]: boolean } = {};
  return arr.filter((item) => {
    const keyValues = `${item[key1]}:${item[key2]}`;
    if (!seen[keyValues]) {
      seen[keyValues] = true;
      return true;
    }
    return false;
  });
}

export function groupBy<T, K>(
  array: T[],
  iteratee: (value: T) => K,
): { [key: string]: T[] } {
  const result: { [key: string]: T[] } = {};

  for (const value of array) {
    const key = iteratee(value);
    const groupName = String(key);

    if (!result[groupName]) {
      result[groupName] = [];
    }

    result[groupName].push(value);
  }

  return result;
}

export function createJsonFile<T>(data: T, name: string) {
  const jsonData = JSON.stringify(data, null, 2);
  const blob = new Blob([jsonData], { type: 'application/json' });
  return new File([blob], name);
}
