import { Dan } from '@escrowtab/v2profile-api-client';

export interface AuditAction {
  entityId: string;
  actionPerformedByName?: string;
  actionType: AuditActionType;
  actionTypeOtherDescription?: string;
  detailDescription?: string;
  actionDateTime?: string;
  systemAuthenticationName?: string;
  id?: string;
  actionId?: string;
}

export interface AuditActionDescription {
  page: number;
  ['page-type']?: string;
  ['notarial-act']?: string;
  signor?: string;
  actionDateTime?: string;
  required?: string;
  dan?: Dan;
}

export enum AuditActionType {
  Authenticated = 'Authenticated',
  Canceled = 'Canceled',
  Completed = 'Completed',
  Created = 'Created',
  Edited = 'Edited',
  Finalized = 'Finalized',
  Login = 'Login',
  Notarized = 'Notarized',
  NonNotarizedStamp = 'NonNotarizedStamp',
  Other = 'Other',
  SaveAndExit = 'SaveAndExit',
  DocumentSent = 'DocumentSent',
  Sealed = 'Sealed',
  Signed = 'Signed',
  Submitted = 'Submitted',
  Viewed = 'Viewed',
}
