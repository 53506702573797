import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { Observable } from 'rxjs';
import {
  Alert,
  NotificationService,
} from './../../services/notification.service';

@Component({
  selector: 'et-notifications-list',
  templateUrl: './alert-list.component.html',
  styleUrls: ['./alert-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('alertAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(30px)' }),
        animate(
          '300ms cubic-bezier(.19,.62,.46,.97)',
          style({ opacity: 1, transform: 'translateY(0)' }),
        ),
      ]),
      transition(':leave', [
        style({ minHeight: 0 }),
        animate(
          '400ms cubic-bezier(.19,.62,.46,.97)',
          style({ transform: 'translateX(500px)' }),
        ),
        animate('300ms 50ms ease-in', style({ height: 0 })),
      ]),
    ]),
  ],
})
export class AlertListComponent implements OnInit {
  alerts$!: Observable<Alert[]>;

  constructor(private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.alerts$ = this.notificationService.alerts$;
  }
}
