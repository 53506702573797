import { Injectable } from '@angular/core';
import { AuditAction } from '@et/typings';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuditActionsRepositoryService {
  protected _tableName = 'audit_actions';

  constructor(private storage: StorageService) {}

  /**
   * This function sets initial dummy value in the table
   */
  async addDummy() {
    try {
      await this.setTable();
      await this.storage.setItem('0', '1');
    } catch (error) {
      console.error('AuditActionsRepositoryService: ', error);
      return;
    }
  }

  /**
   * This function return all values from storage
   */
  async getAll(): Promise<Array<AuditAction[]>> {
    try {
      await this.setTable();
      const actionsJSONArrWithDummy = await this.storage.getAllValues();
      // Remove dummy
      const actionsJSONArr = actionsJSONArrWithDummy.filter((a) => a !== '1');
      const actions: Array<AuditAction[]> = [];
      for (const actionsJSON of actionsJSONArr) {
        const action = JSON.parse(actionsJSON);
        actions.push(action);
      }
      return actions;
    } catch (error) {
      console.error('AuditActionsRepositoryService: ', error);
      return [];
    }
  }

  /**
   * This function gets AuditAction[] by id
   * @param {string} id - packet id or eNote id
   */
  async get(id: string): Promise<AuditAction[] | null> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return null;
      }
      const actionsJSON = await this.storage.getItem(id);
      const actions = JSON.parse(actionsJSON);
      return actions;
    } catch (error) {
      console.error('AuditActionsRepositoryService: ', error);
      return null;
    }
  }

  /**
   * This function adds AuditAction[] to storage
   * @param {string} id - Packet id or eNote id
   * @param {AuditAction[]} actions - AuditAction[]
   */
  async createOrUpdate(id: string, actions: AuditAction[]): Promise<void> {
    try {
      await this.setTable();
      const actionsJSON = JSON.stringify(actions);
      await this.storage.setItem(id, actionsJSON);
    } catch (error) {
      console.error('AuditActionsRepositoryService: ', error);
    }
  }

  /**
   * This function deletes AuditAction[] in storage
   * @param {string} id - Packet id or eNote id
   */
  async delete(id: string): Promise<void> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return;
      }
      await this.storage.removeItem(id);
    } catch (error) {
      console.error('AuditActionsRepositoryService: ', error);
    }
  }

  /**
   * This function deletes all keys
   */
  async clear(): Promise<void> {
    try {
      await this.setTable();
      await this.storage.clear();
      await this.addDummy();
    } catch (error) {
      console.error('AuditActionsRepositoryService: ', error);
    }
  }

  /**
   * This function sets storage table
   */
  async setTable() {
    await this.storage.setTable(this._tableName);
  }
}
