import { Packet } from '@escrowtab/packets-api-client';
import {
  orderEnum,
  packetStatusEnum,
  sortEnum,
  stateEnum,
  typeEnum,
} from './select.interfaces';
import {
  DocPrepDocument,
  DocprepMarkupPage,
  PacketsClosingType,
} from '@et/typings';

export interface documentListItem {
  UID: string;
  name: string;
  docUrl: string;
  date: string | Date;
  agent: string;
  signed: boolean;
  eNote: boolean;
  completed: boolean;
  actionRequired: boolean;
  company: string;
  eNoteTemplate?: string;
  isDraft?: boolean;
  companyId?: string;
  userId?: string;
  thumbnail?: string;
  file?: File;
  totalPages?: number;
}

export interface PacketListItem extends Omit<Packet, 'packetStatus'> {
  packetStatus: packetStatusEnum;
  completed: boolean;
  eNote: boolean;
  actionRequired: boolean;
  isDraft: boolean;
  signed: boolean;
  file?: File;
  downloaded?: boolean;
  eNoteFile?: File;
  inkFile?: File;
  thumbnail?: string;
  totalPages?: number;
  inReview?: boolean;
}

export interface DocUploadMetadata {
  filesMetadata: DocUploadMetadataFile[];
  hasENote?: string;
  includeESignConsent?: boolean;
  flaggedPages?: number[];
  isBuyer?: string;
  signorCount?: number;
  buyers?: number;
  sellers?: number;
  generatedFrom?: string;
  clientData?: ClientData; // Client data sent by clients in clientData.json
  clientMetadata?: ClientMetadata; // Client data updated after document upload. Saved as clientMetadata.json
  signers?: documentUploadInformationSigner[];
  message?: documentUploadMessage;
  processingOptions?: AutotagProsessingOptions;
  isAutoPrepRequired?: boolean | 'self-tag' | 'select-template';
  autotagTemplate?: DocPrepDocument;
}

export interface ClientData {
  filesMetadata: ClientDataFile[];
}

interface ClientDataFile {
  fileName: string;
  id: string;
  vaultID: string;
}

export interface ClientMetadata {
  id: string;
  externalId?: string;
  type?: string;
  name: string;
  isTestDocument: boolean;
  status?: string;
  numberOfPages: number;
  pages: ClientMetadataPage[];
}

export interface ClientMetadataPage {
  pageNumber: number;
  markup: ClientMetadataPageMarkup;
}

export interface ClientMetadataPageMarkup {
  pageType?: string;
  originalFilename?: string;
  vaultID?: string;
}

export interface DocUploadMetadataFile {
  fileName: string;
  id: string;
}

export interface DocumentsFilter {
  order: orderEnum;
  sort: sortEnum;
  type: typeEnum;
  state: stateEnum;
  itemsOnPage: number;
  currentPage: number;
  count?: number;
}

export interface documentUploadSelectItem {
  thumbnail: string;
  fileName: string;
  originalFileName?: string;
  id: string;
  totalPages: number;
  file: File;
  pageRange?: { start: number; end: number };
  showModal?: boolean | undefined;
  integrity?: boolean | undefined;
}

export interface DocioItem {
  Title: string;
  Id: string;
  Owner: string;
  Doctype: string;
  Label: string;
  Owned: boolean;
  DateCreated: Date;
}

export interface RamQuestDocItem {
  Description: string;
  ID: string;
  Publisher: string;
  Doctype: string;
  Owned: boolean;
  Published: Date;
}

export interface StripeCreateSessionItem {
  client_secret: string;
}

export interface documentUploadMoveItem {
  from: {
    index: number;
    item: documentUploadSelectItem;
  };
  to: {
    index: number;
    item: documentUploadSelectItem;
  };
}

export interface documentUploadSelectItems {
  [key: string]: documentUploadSelectItem;
}

export const ENoteSearchTerm = /mismos|smart|doc|v1.02/;

export interface NCSoSStatus {
  NotaryStatus: string;
  ENotaryStatus: string;
}

export interface NCSoSUploadResponse {
  Document: {
    ReceiptInfo: {
      DateTimeReceived: string;
      Errors?: {
        Message: string;
      };
    };
    DocumentId?: string;
    'Status info': string;
  };
}

export interface retrievedDocs {
  title: string;
  document: string;
}

export interface retrievedTileData {
  id: string;
  selected: boolean;
  name: string;
}

export interface documentUploadInformation {
  titleId: string;
  loanId?: string;
  minNumber?: string;
  state: string;
  signorCount: number;
  buyers?: number;
  sellers?: number;
  lender?: string;
  hasENote: string;
  isBuyer?: string;
  loanType?: string;
  includeESignConsent: boolean;
  sendToAutoDocumentPreparation: boolean | 'self-tag' | 'select-template';
  isTestDocument?: boolean;
  signers?: documentUploadInformationSigner[];
  flaggedPages?: number[];
  closingType?: PacketsClosingType;
  clientData?: ClientData;
  clientMetadata?: ClientMetadata;
  generatedFrom?: string;
  isTwoFA?: boolean;
  message?: documentUploadMessage;
  processingOptions?: AutotagProsessingOptions;
  autotagTemplate?: DocPrepDocument;
}

export interface documentUploadMessage {
  subject: string;
  message?: string;
}

export interface documentUploadMetaInformation {
  titleAgentEmail: string;
  titleAgentCompany: string;
  titleAgentDisplayName: string;
  signingAgent: string;
  signingAgentEmail: string;
  closingDate: string;
  closingTime: string;
  docTitleName: string;
  titleAgentId?: string;
  profileId?: string;
  companyId?: string;
  companyName?: string;
  autoTagOnboarding?: boolean;
  signers?: documentUploadInformationSigner[];
  closingType?: PacketsClosingType;
  isTwoFA?: boolean;
  message?: documentUploadMessage;
  processingOptions?: AutotagProsessingOptions;
  markupPages?: DocprepMarkupPage[];
}

export interface documentUploadInformationSigner {
  signerName: string;
  signerEmail: string;
  viewOnly: boolean;
  signerPhone?: string;
  isBuyer?: boolean | null;
}

export interface documentPDFPage {
  thumbnail: string;
  originalPageNumber: number;
  currentPageNumber?: number;
  isDeleted?: boolean;
  isFlagged?: boolean;
  documentId?: string;
}

export interface DraftFilters {
  assignToCompany: boolean;
  assignToMe: boolean;
}

export interface AutotagProsessingOptions {
  guessSignors?: boolean;
  ignoreNonSignatureDateTabs?: boolean;
  ignoreTemplates?: boolean;
  ignoreImageSignatures?: boolean;
}
