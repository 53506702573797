import {
  ChangeDetectionStrategy,
  Component,
  Input,
  TemplateRef,
} from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';

@Component({
  selector: 'et-atoms-hover-question',
  templateUrl: './hover-question.component.html',
  styleUrls: ['./hover-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HoverQuestionComponent {
  @Input() displayTemplate!: TemplateRef<unknown>;
  @Input() iconSrc = 'assets/icons/question-outline-white.svg';
  @Input() width!: number;

  offsetX = -11;
  offsetY = -11;
  isOpen = false;
  positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
    },
    {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'top',
    },
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'top',
      offsetX: 11,
    },
  ];

  /**
   * Open the popover
   */
  onOpen() {
    this.isOpen = true;
  }

  /**
   * Close the popover
   */
  onClose() {
    this.isOpen = false;
  }
}
