// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  environment: 'dev',
  docio: {
    domain: 'https://docio-stg.escrowtab.com',
    aadScope: ['https://aad-stg.escrowtab.com/docio/access_as_user'],
    aadScopeAdmin: ['https://aad-stg.escrowtab.com/docio/access_as_admin'],
  },
  apiServer: {
    domain: 'https://app-dev.escrowtab.com/api/v1/client',
    aadScope: [
      'https://aad-stg.escrowtab.com/auth/api1',
      'https://aad-stg.escrowtab.com/auth/api2',
    ],
    token:
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJldF9kYXRhX2FsbCI6IjEiLCJuYmYiOjE2NDc1MTc3NDEsImV4cCI6NDc3MTY1NTM0MSwiaWF0IjoxNjQ3NTE3NzQxfQ.JrgSiYv9QMtTY1LQSbPzIzUxZNq-c3nN_TlrC82APP4',
  },
  apiDocPrep: {
    domain: 'https://escrowtab-documentprepare.azurewebsites.net/api',
    aadScope: ['https://aad-stg.escrowtab.com/docprep/access_as_user'],
    aadScopeAdmin: ['https://identity.escrowtab.com/docprep/access_as_admin'],
  },
  apiOrders: {
    domain: 'https://orders-stg.escrowtab.com/api',
    aadScope: ['https://aad-stg.escrowtab.com/orders/Orders.Access'],
  },
  apiSecureLinks: {
    domain: 'https://links-stg.escrowtab.com/api',
    aadScope: ['https://aad-stg.escrowtab.com/links/User.Read'],
  },
  nnaScheduler: {
    domain: 'https://scheduler-stg.escrowtab.com/api/nna-scheduler',
  },
  auditService: {
    domain: 'https://auditv2-api-stg.escrowtab.com/api',
    aadScope: ['https://aad-stg.escrowtab.com/auditv2/access_as_user'],
  },
  adminAPI: {
    domain: 'https://admin-api-stg.escrowtab.com/api',
    aadScope: ['https://aad-stg.escrowtab.com/admin/access_as_user'],
  },
  googleAPIS: 'AIzaSyCr9L8QfLTwHpCsuJgFUyirgYib51zAkk0',
  sentryDns:
    'https://c2bc05c4cb3344d1af74580285d315df@o1214306.ingest.sentry.io/6377800',
  sentryNativeDns:
    'https://20582132274048b98c709d7b1fd3d279@o1214306.ingest.sentry.io/4503899375927296',
  sentryElsiDns:
    'https://824fc15c76452c4c6d39da7066216807@o1214306.ingest.sentry.io/4506583402086400',
  aad: {
    clientId: '2468f098-23d2-492c-b4ea-765ea4fd2355',
    clientIdNative: '4fd0a793-e78b-4679-ae0b-a5c6028010a0',
    verifyClientId: 'f420a53d-5973-4ea3-aa04-cb3fafdcd762',
    evaultClientId: '34e109bf-94fb-447c-a04d-dd1f714ab0af',
    b2cPolicies: {
      names: {
        signIn: 'B2C_1_signin-01',
        editProfile: 'B2C_1_editprofile-01',
      },
      authorities: {
        signIn: {
          authority:
            'https://etidentitystg.b2clogin.com/aad-stg.escrowtab.com/B2C_1_signin-01',
        },
        editProfile: {
          authority:
            'https://etidentitystg.b2clogin.com/aad-stg.escrowtab.com/B2C_1_editprofile-01',
        },
      },
      authorityDomain: ['aad-stg.escrowtab.com', 'etidentitystg.b2clogin.com'],
    },
  },
  adminAAD: {
    clientId: 'e0e76bf8-9d85-4df6-a890-091ab415f3e9',
    b2cPolicies: {
      authorities: {
        signIn: {
          authority:
            'https://login.microsoftonline.com/36af3843-3860-422b-8cbe-52bc996630e9',
        },
      },
      authorityDomain: ['escrowtab.com'],
    },
  },
  packetService: {
    domain: 'https://packet-api-stg.escrowtab.com',
    aadScope: ['https://aad-stg.escrowtab.com/packet/access_as_user'],
    aadScopeAdmin: ['https://aad-stg.escrowtab.com/packet/access_as_admin'],
  },
  docPrepService: {
    domain: 'https://docprep-api-stg.escrowtab.com',
    aadScope: ['https://aad-stg.escrowtab.com/docprep/access_as_user'],
    aadScopeAdmin: ['https://aad-stg.escrowtab.com/docprep/access_as_admin'],
  },
  profilev2: {
    domain: 'https://profilev2-api-stg.escrowtab.com',
    aadScope: ['https://aad-stg.escrowtab.com/profilev2/access_as_user'],
    aadScopeAdmin: ['https://aad-stg.escrowtab.com/profilev2/access_as_admin'],
  },
  amplitude: 'd58a4b2baf7844f5a10291e67641376d',
  stripe: {
    publisherKey:
      'pk_test_51L6K9fJrriO4Nt49TDiDyIq9p345OavuRmkoGHFSVtE0leMb82P2kxoS5xQxyrMyMbUZO3bfTJN9mXaQaJylhQCC00N5TgRYh2',
  },
  pusher: {
    apiKey: '8868044924edd1a50a9a',
    cluster: 'ap2',
    channelAuthorizationEndpoint:
      'https://packet-api-stg.escrowtab.com/api/Signer/pageNavigationAuth',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
