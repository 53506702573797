import { DocumentMarkupStatus } from '../constants/native.constants';
import { PacketsClosingType } from '../constants/packets.constants';

export interface DocPrepTemplatesListRequest {
  continuationToken?: string | null;
  titleCompany?: string;
  titleAgentEmail?: string;
}

export interface DocPrepResponse {
  statusCode: number;
  status: true;
  message: null | string;
  modelErrors: null | ModelErrors[];
  data: DocPrepDocumentsList;
}

interface ModelErrors {
  fieldName: string;
  errorMessage: string;
}

export interface DocPrepDocumentsList {
  documents: DocPrepDocument[];
  continuationToken: string | null;
}

export interface DocPrepDocument {
  id: string;
  externalId: string;
  type: string;
  name: string;
  isTestDocument: boolean;
  status: string;
  metadata: Metadata;
  utcDateCreated: string;
  templateInfo: {
    name: string;
    standardizedForm: boolean;
  };
}

export interface Metadata {
  state: string;
  lender: string;
  titleCompany: string;
  signors: number;
  hasEnote: boolean;
  flaggedPages: any[];
  signatures: string[];
  buyers: number;
  sellers: number;
  closingTime: string;
}

export interface DocPrepDetailsResponse {
  statusCode: number;
  status: true;
  data: DocPrepDetails;
}

export interface DocPrepDetails {
  externalId: string;
  id: string;
  isTestDocument: boolean;
  metadata: any;
  name: string;
  numberOfPages: number;
  numberOfTemplatedPages: number;
  pages: DocPrepPages[];
  processingStats: DocPrepProcessingStats;
  status: string;
  templateInfo: any;
  type: number;
  utcDateCreated: Date;
}

export interface DocPrepPages {
  pageNumber: number;
  status: string;
  templateFound: boolean;
  dimensions: any;
  processingStats: any;
  functionProcessingStats: any;
}

export interface DocPrepProcessingStats {
  processingStarted: string;
  processingCompleted: string;
  elapsedProcessingTime: string;
}

export interface MarkupDocument {
  document: DocprepDocument;
  documentURI: string | File;
  documentMarkup: DocprepMarkup;
}

export interface DocPrepDocumentStatusResponse {
  statusCode: number;
  status: boolean;
  message: string;
  data: DocumentMarkupStatus;
  modelErrors: null;
}

export interface DocPrepDocumentStatus {
  status: DocumentMarkupStatus;
  pagesProgressStatus: string[];
}

// Document interface

interface DocprepDocumentMetadata {
  state: string;
  lender: string;
  titleCompany: string;
  signors: number;
  hasEnote: boolean;
  flaggedPages: any[];
  signatures: string[];
  closingType: PacketsClosingType;
}

export interface Dimensions {
  widthPixels: number;
  heightPixels: number;
}

export interface DocprepDocumentPage {
  pageNumber: number;
  status: string;
  templateFound: boolean;
  newModifiedDocumentPage: boolean | null;
  dimensions: Dimensions;
}

export interface DocprepDocument {
  id: string;
  externalId: string;
  type: string;
  name: string;
  isTestDocument: boolean;
  status: string;
  metadata: DocprepDocumentMetadata;
  numberOfPages: number;
  pages: DocprepDocumentPage[];
  utcDateCreated: Date;
}

export interface DocprepDocumentRes {
  statusCode: number;
  status: boolean;
  data: DocprepDocument;
}

// Markup interface

export interface DocprepPageMarkupTags {
  hasNotarySeal: boolean;
  notorialAct?: any;
  printAndSign: boolean;
  noExtraMarks: boolean;
  enote: boolean;
}

interface Coordinates {
  x: number;
  y: number;
  h: number;
  w: number;
}

export interface DocprepPageMarkup {
  template?: PageMarkupTemplate;
  signature: string;
  pageType: any;
  pagePart: any;
  pageCategory: MarkupPageCategory;
  dimensions: Dimensions;
  extractions: MarkupExtraction[];
  tags: DocprepPageMarkupTags;
  tabs: MarkupPageTab[];
  tabGroups: DocprepMarkupPageTabGroup[];
  pageSubset: null | string[];
  pageInstruction?: string | null;
}

export interface DocprepMarkupPageTabGroup {
  id: string;
  name?: string;
  minimum?: number;
  maximum?: number;
  exactly?: number;
  required: boolean;
}

export interface DocprepMarkupPage {
  templateFound: boolean;
  newModifiedDocumentPage: boolean | null;
  pageNumber: number;
  status: string;
  markup: DocprepPageMarkup;
}

export interface DocprepMarkup {
  id: string;
  externalId: string;
  type: string;
  name: string;
  isTestDocument: boolean;
  markupRequired: boolean;
  status: string;
  numberOfPages: number;
  pages: DocprepMarkupPage[];
  metadata: DocprepMarkupMetadata;
  templateInfo?: DocprepMarkupTemplateInfo;
}

export interface DocprepMarkupTemplateInfo {
  name: string;
  standardizedForm: boolean;
}

export interface DocprepMarkupMetadata {
  state: any;
  lenderName: any;
  lender: any;
  titleCompanyName: string;
  titleCompany: string;
  titleAgent: string;
  titleAgentEmail: string;
  signingAgent: any;
  signingAgentEmail: any;
  closingTime: any;
  closingType: string;
  signors: number;
  buyers: number;
  sellers: number;
  signorInfo: DocprepMarkupMetadataSignorInfo[];
  hasEnote: boolean;
  flaggedPages: any[];
  signatures: string[];
}

export interface DocprepMarkupMetadataSignorInfo {
  index: number;
  signorId: string;
  name: string;
  email: string;
  type: string;
}

export interface DocprepMarkupRes {
  statusCode: number;
  status: boolean;
  data: DocprepMarkup;
}

export interface DocprepMarkupURI {
  statusCode: number;
  status: boolean;
  data: string;
}

// Page overlay

interface Coordinates {
  x: number;
  y: number;
  h: number;
  w: number;
}

interface Word {
  id: string;
  text: string;
  coordinates: Coordinates;
}

interface Entity {
  type: string;
  id: string;
  text: string;
  coordinates: Coordinates;
}

export interface PageOverlayLine {
  words: Word[];
  entities: Entity[];
  id: string;
  text: string;
  coordinates: Coordinates;
}

export interface PageOverlayBlock {
  id: string;
  text: string;
  coordinates: Coordinates;
}

interface PageOverlayLineElement {
  id: string;
  coordinates: Coordinates;
}

export interface PageOverlayTable {
  id: string;
  coordinates: Coordinates;
}

export interface MarkupPageOverlay {
  page: number;
  lines: PageOverlayLine[];
  blocks: PageOverlayBlock[];
  lineElements: PageOverlayLineElement[];
  selectionElements: any[];
  tables: PageOverlayTable[];
  barcodes: any[];
  datamatrices: any[];
}

export interface MarkupPageOverlayRes {
  statusCode: number;
  status: boolean;
  data: MarkupPageOverlay;
}

// Page markup

export interface MarkupPageTabOptions {
  signor?: string | null;
  required?: boolean | null;
  keyboard?: boolean | null;
  signorIndex?: number | null;
  mappedTo?: string | null;
  mappedToIndex?: number;
  groupId?: string | null;
}

export interface MarkupPageTab {
  id: string;
  type: string;
  options: MarkupPageTabOptions;
  surroundingText: string;
  coordinates: Coordinates;
}

export interface MarkupExtraction {
  coordinates: Coordinates;
  name?: string;
  id?: string;
  surroundingText?: string;
  withinText?: string;
}

export interface PageMarkup {
  signature: string;
  tags: DocprepPageMarkupTags;
  tabs: MarkupPageTab[];
  extractions: MarkupExtraction[];
  pageType: string | null;
  template?: PageMarkupTemplate;
  pageCategory?: MarkupPageCategory;
}

export interface PageMarkupTemplate {
  documentId: string;
  pageNumber: number;
  standardizedForm: boolean;
  templateId: string;
}

export interface MarkupPageRes {
  statusCode: number;
  status: boolean;
  data: PageMarkup;
}

export interface MarkupPageCategory {
  category: string;
  confidence: number;
  ingestion: string;
  part: number;
}

export interface SignerInfo {
  value: string;
  signorIndex: number;
  signor: string;
}

export interface DocumentWithPageSubset {
  companyId: string;
  companyName: string;
  pageSubset: string[];
}

export interface DocPrepStacking {
  companyId: string;
  companyName: string;
  stacking: Stacking;
}

export interface Stacking {
  buyers: Stack[];
  sellers: Stack[];
}

export interface Stack {
  orderNumber: number;
  pageType: string;
}

export interface AutotagTemplateInfo {
  templateName: string;
  closingType: PacketsClosingType;
  signorCount: number;
  buyers: number;
  sellers: number;
  isBuyer: string;
  titleAgentId: string; // user id
  titleCompanyName: string; // company name
  titleCompany: string; // company id
  titleAgent: string; // agent name
  titleAgentEmail: string; // agent email
}
