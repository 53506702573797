import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { documentUploadSelectItem } from '@et/typings';

interface Data {
  doc: documentUploadSelectItem;
  fileNames: string[];
}

@Component({
  selector: 'et-atoms-doc-rename',
  templateUrl: './document-rename.component.html',
  styleUrls: ['./document-rename.component.scss'],
})
export class DocumentRenameComponent implements OnInit {
  form = new UntypedFormGroup({
    name: new UntypedFormControl(null, Validators.required),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Data,
    private dialogRef: MatDialogRef<DocumentRenameComponent>,
  ) {}

  /**
   * Sets the document name to the form
   *
   * @memberof DocumentRenameComponent
   */
  ngOnInit(): void {
    this.form.get('name')?.setValue(this.data.doc.fileName);
  }

  /**
   * 1. Sets new file name to the doc and updates the doc in the store
   * 2. Closes modal
   *
   * @memberof DocumentRenameComponent
   */
  onSubmit() {
    if (!this.form.valid) {
      this.form.markAsDirty();
      return;
    }
    const newName = this.form.value.name;
    const doc = this.data.doc;
    const oldFile = doc.file;
    const newFile = new File([oldFile], newName, {
      type: oldFile.type,
      lastModified: oldFile.lastModified,
    });
    const updatedDoc: documentUploadSelectItem = {
      ...doc,
      fileName: newName,
      file: newFile,
    };
    this.dialogRef.close(updatedDoc);
  }

  /**
   * Validation function checks if file name exists
   *
   * @memberof DocumentRenameComponent
   */
  checkFileNames(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // No value
      if (!control.value) {
        return { required: true };
      }
      // No files
      if (!this.data.fileNames || !this.data.fileNames.length) {
        return null;
      }
      // Check if file already exist
      if (this.data.fileNames.includes(control.value.toLowerCase())) {
        return { duplicateFileName: true };
      }
      return null;
    };
  }
}
