import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { catchError, Observable } from 'rxjs';

import { multiSearchAnd } from '@et/utils';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const token = localStorage.getItem('ET_TOKEN');
    if (
      token &&
      !multiSearchAnd(request.url, ['/access-token/', 'client/profile'])
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return next.handle(request).pipe(
      // handle errors globally
      catchError((err) => {
        throw new Error(
          `Error performing request, status code = ${err.status}`,
        );
      }),
    );
  }
}
