import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@et/environment';
import { DocioItem } from '@et/typings';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DocumentRetrievalService {
  private callUrl = environment.docio.domain;

  constructor(public http: HttpClient) {}

  /**
   * Calls the API to retrieve a list of documents
   *
   * @param {string} gfno - Specific folder to return
   *
   * @memberof DocumentRetrievalService
   */
  getDocumentList(gfno: string): Observable<DocioItem[]> {
    return this.http.get<DocioItem[]>(
      `${this.callUrl}/DocumentRetrieval/GetDocumentList/${gfno}`,
    );
  }

  /**
   * Calls the API to retrieve a document as pdf string
   *
   * @param {string} folderNum - Folder containing document
   * @param {string} docId - Document ID number
   * @param {string} title - Document title
   *
   * @memberof DocumentRetrievalService
   */
  getDocument(folderNum: string, docId: string, title: string) {
    return this.http.get<string>(
      `${this.callUrl}/DocumentRetrieval/GetDocument/${folderNum}/${docId}/${title}`,
    );
  }

  /**
   * Uploads a document to user's 3rd party integration
   *
   * @param {string} gfno - File number
   *
   * @memberof DocumentRetrievalService
   */
  uploadDocument(
    folderNum: string,
    title: string,
    fileNum: string,
    docstring: string,
  ) {
    let headers = new HttpHeaders().append('Content-Type', 'text/json');
    headers = headers.append('Accept', 'text/plain');

    const body = {
      pdf: docstring,
    };

    return this.http.put<string>(
      `${this.callUrl}/DocumentRetrieval/UploadDocument/${folderNum}/${title}/${fileNum}`,
      JSON.stringify(body),
      { headers },
    );
  }

  /**
   * Upload a document by file name
   * @param folder - folder number 'GFNO'
   * @param fileName - Pdf file name in storage
   * @param packetId - Packet ID
   * @returns
   */
  uploadByFileName(folder: string, fileName: string, packetId: string) {
    return this.http.post(
      `${this.callUrl}/DocumentRetrieval/FileScanUploadPDF/${folder}/${packetId}/${fileName}`,
      {},
    );
  }
}
