import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@et/environment';
import { AuditAction } from '@et/typings';
import { Observable, catchError, forkJoin, of } from 'rxjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  constructor(private http: HttpClient) {}

  /**
   * It takes an AuditAction object as a parameter, and returns an Observable of type any
   * @param {AuditAction} action - AuditAction - this is the object that will be sent to the audit
   * service.
   * @returns The AuditAction object
   */
  setAuditAction(action: AuditAction) {
    return this.http.post<AuditAction>(
      `${environment.auditService.domain}/Audit`,
      action,
    );
  }

  /**
   * It takes an array of AuditAction objects, and for each one, it makes a POST request to the Audit
   * Service
   * @param {AuditAction[]} actions - AuditAction[] - an array of AuditAction objects
   * @returns An Observable of AuditAction requests
   */
  setAuditActions(actions: AuditAction[]) {
    return this.http.post<AuditAction>(
      `${environment.auditService.domain}/Audit/actions`,
      actions,
    );
  }

  /**
   * It takes a document ID as a parameter, and returns an Observable of AuditAction
   * @param {String} documentId - document UID
   * @returns The AuditAction object
   */
  getAuditActionsByDocumentId(documentId: string) {
    const params = new HttpParams().append('entityId', documentId);
    return this.http
      .get<AuditAction[]>(`${environment.auditService.domain}/Audit/trail/`, {
        params,
      })
      .pipe(
        catchError((err) => {
          // If audit log doesn't exist return empty array instead of throwing an error
          if (err.status === 404) {
            return of(null);
          }
          throw err;
        }),
      );
  }

  /**
   * It takes an array of document IDs, and for each one it makes a GET request to the Audit
   * trial
   * @param {String[]} documentIds - document UIDs
   * @returns An array of Observables
   */
  getAuditActionsByDocumentIds(documentIds: string[]) {
    const actionsReqs = documentIds.map((documentId) =>
      this.getAuditActionsByDocumentId(documentId),
    );

    return forkJoin(actionsReqs);
  }

  /**
   * Fetches the audit log template for a given packet ID.
   *
   * @param {string} packetId - The ID of the packet for which to fetch the audit log template.
   * @returns {Observable<string>} An Observable that emits the audit log template as an HTML string.
   */
  getAuditLogTemplate(packetId: string): Observable<string> {
    return this.http.get(
      `${environment.auditService.domain}/audit/template/${packetId}`,
      {
        responseType: 'text',
      },
    );
  }

  /**
   * create a pdf for the audit log
   *
   * @param {HTMLElement} data
   * @return {*}
   * @memberof AuditService
   */
  async createPdfAudit(data: HTMLElement) {
    const canvas = await html2canvas(data, { allowTaint: true, useCORS: true });
    const contentDataURL = canvas.toDataURL('image/jpeg', 1.0);
    const imgWidth = 210;
    const pageHeight = 295;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    const doc = new jsPDF('p', 'mm');
    let position = 0;
    doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
    return doc;
  }
}
