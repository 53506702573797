import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Output,
  QueryList,
} from '@angular/core';
import { TileTabComponent } from '../tile-tab/tile-tab.component';

@Component({
  selector: 'et-atoms-tile-tabs',
  templateUrl: './tile-tabs.component.html',
  styleUrls: ['./tile-tabs.component.scss'],
})
export class TileTabsComponent implements AfterContentInit {
  @Output() tabsInit = new EventEmitter<void>();
  @Output() selectedTab = new EventEmitter<number>();
  @ContentChildren(TileTabComponent) tabs!: QueryList<TileTabComponent>;

  ngAfterContentInit(): void {
    if (!this.tabs) {
      return;
    }
    this.tabs.first.selected = true;
    this.tabsInit.emit();
  }

  onSelectTab(clickedTab: TileTabComponent, tabIndex: number) {
    if (!this.tabs || clickedTab.selected) {
      return;
    }
    for (const tab of this.tabs) {
      tab.selected = false;
    }
    clickedTab.selected = true;
    this.selectedTab.emit(tabIndex);
  }
}
