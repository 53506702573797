<!-- Document preview container -->
<div
  class="flex flex-col justify-between w-[304px] h-fit rounded-lg box-border border border-neutral-secondary"
  [ngClass]="{ '!border-midnight shadow-input-focus mb-[170px]': menu.isOpen }"
>
  <!-- Document preview area -->
  <div class="flex items-center justify-center grow">
    <ng-template #docPreview>
      <span class="text-xl font-semibold text-neutral my-[150px]"
        >DOCUMENT PREVIEW</span
      >
    </ng-template>
    <img
      class="w-[304px] h-auto object-cover my-4"
      *ngIf="thumbnail; else docPreview"
      [src]="thumbnail"
    />
  </div>
  <!-- Document info -->
  <div
    class="flex items-center justify-between w-full px-4 py-3 rounded-b-lg bg-base-200"
  >
    <!-- Text -->
    <div class="flex flex-col justify-between h-full max-w-[calc(100%-48px)]">
      @if(fileName) {
      <p class="mt-0 mb-1 text-base font-medium leading-6 truncate">
        {{ fileName }}
      </p>
      }
      <span class="text-base font-normal"
        >{{ totalPages || 'Counting' }} pages</span
      >
    </div>
    <!-- Document menu -->
    <ng-content></ng-content>
  </div>
</div>
