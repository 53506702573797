import { BaseItemObjectUserApiModel } from './base-item-object-user-api-model';
export interface ProfileResponseApiModel {
  items?: BaseItemObjectUserApiModel;
}
export interface ProfileAPIModel {
  msg: string;
  code: string;
  info: ProfileAPIInfo;
  data: ProfileAPIData;
}

export interface ProfileAPIData {
  'company-id': string;
  'is-company-admin': boolean;
  'is-originator': boolean;
  'is-agent': boolean;
  'is-notary': boolean;
  email: string;
  name: string;
  phone: string;
  id: string;
  'utc-date-created': string;
  'utc-date-modified': string;
  code: string;
  active: boolean;
  'data-filter': string;
}

export interface ProfileAPIInfo {
  'access-token': string;
  version: string;
  action: string;
  path: string;
  format: string;
  'route-val': string;
  'route-detail-val': string;
}

export interface NotaryAPIInfo {
  msg: string;
  code: string;
  info: NotaryAPIInfoMeta;
  data: {
    items: NotaryAPIInfoItems[];
    uid: string;
  };
}
export interface NotaryAPIInfoMeta {
  'access-token': string;
  version: string;
  action: string;
  path: string;
  format: string;
  'route-val': string;
  'route-detail-val': string;
}

export interface NotaryAPIInfoItems {
  uid: string;
  code: string;
  name: string;
  state: string;
  country: string;
  county: string;
  'date-expires': string;
  'date-issued': string;
  number: string;
  'profile-id': string;
  image: string;
}
