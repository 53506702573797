export const defaultProfileImageHash =
  'HgPhXFBUBVP2JJDTOAYSgAhom4BNYpBA4I5RY7lAw9ed58Z+WSxAWGPt8TE2isUaayxAWGONBQhrrLEAYY01FiCssfaY2H8FGABI76Vdui1TXAAAAABJRU5ErkJggg==';

export enum ProfileUserType {
  NotarySigningAgent = 1,
  NotaryNotary = 2,
  LenderLoanOfficer = 3,
  LenderClosingDepartment = 4,
  TitleSigningAgent = 5,
  Title = 6,
  Signor = 7,
  ClosingCompany = 8,
}
