import { Injectable } from '@angular/core';
import { ProfileDto } from '@escrowtab/v2profile-api-client';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileRepositoryService {
  protected _tableName = 'profile';

  constructor(private storage: StorageService) {}

  /**
   * This function sets initial dummy value in the table
   */
  async addDummy() {
    try {
      await this.setTable();
      await this.storage.setItem('0', '1');
    } catch (error) {
      console.error('ProfileRepositoryService: ', error);
      return;
    }
  }

  /**
   * This function return all values from storage
   */
  async getAll(): Promise<ProfileDto[]> {
    try {
      await this.setTable();
      const profileJSONArrWithDummy = await this.storage.getAllValues();
      // Remove dummy
      const profileJSONArr = profileJSONArrWithDummy.filter((m) => m !== '1');
      const profiles: ProfileDto[] = [];
      for (const profileJSON of profileJSONArr) {
        const profile = JSON.parse(profileJSON);
        profiles.push(profile);
      }
      return profiles;
    } catch (error) {
      console.error('ProfileRepositoryService: ', error);
      return [];
    }
  }
  /**
   * This function saves all values from storage
   */
  async saveAll(profiles: ProfileDto[]): Promise<ProfileDto[] | null> {
    try {
      for (const profile of profiles) {
        await this.createOrUpdate(profile.profileId as string, profile);
      }
      return profiles;
    } catch (error) {
      console.error('ProfileRepositoryService: ', error);
      return null;
    }
  }

  /**
   * This function gets ProfileDto by id
   * @param {string} id - packet id or eNote id
   */
  async get(id: string): Promise<ProfileDto | null> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return null;
      }
      const profileJSON = await this.storage.getItem(id);
      const profiles = JSON.parse(profileJSON);
      return profiles;
    } catch (error) {
      console.error('ProfileRepositoryService: ', error);
      return null;
    }
  }

  /**
   * This function gets ProfileDtos by pincode
   * @param {string} id - pincode for profile
   */
  async getByPin(id: string): Promise<ProfileDto[]> {
    try {
      await this.setTable();
      const all: ProfileDto[] = await this.getAll();
      return all.filter((profile) => profile.pin === id);
    } catch (error) {
      console.error('ProfileRepositoryService: ', error);
      return [];
    }
  }

  /**
   * This function adds ProfileDto to storage
   * @param {string} id - Packet id or eNote id
   * @param {ProfileDto} profile - PacketListItem
   */
  async createOrUpdate(id: string, profile: ProfileDto): Promise<void> {
    try {
      await this.setTable();
      const profileJSON = JSON.stringify(profile);
      await this.storage.setItem(id, profileJSON);
    } catch (error) {
      console.error('ProfileRepositoryService: ', error);
    }
  }

  /**
   * This function deletes ProfileDto in storage
   * @param {string} id - Packet id or eNote id
   */
  async delete(id: string): Promise<void> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return;
      }
      await this.storage.removeItem(id);
    } catch (error) {
      console.error('ProfileRepositoryService: ', error);
    }
  }

  /**
   * This function deletes all keys
   */
  async clear(): Promise<void> {
    try {
      await this.setTable();
      await this.storage.clear();
      await this.addDummy();
    } catch (error) {
      console.error('ProfileRepositoryService: ', error);
    }
  }

  /**
   * This function sets storage table
   */
  async setTable() {
    await this.storage.setTable(this._tableName);
  }
}
