import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'et-atoms-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RangeSliderComponent,
      multi: true,
    },
  ],
})
export class RangeSliderComponent implements ControlValueAccessor {
  @Input() min = 1;
  @Input() max = 7;

  protected widthSizeModel = 7;

  protected onChange!: (value: number) => void;

  private onTouched!: () => void;

  @HostBinding('class.disabled')
  disabled = false;

  /**
   * This method is called when the user changes the value of the slider
   * it calls the onChange and onTouched callback functions
   * @param value
   */
  onValueChanged(value: number) {
    this.onChange(value);
    this.onTouched();
  }

  /**
   * This method is called when the user changes the value of the slider
   * @param value
   */
  writeValue(value: number): void {
    this.widthSizeModel = value;
  }

  /**
   * This method registered the callback function that should be called when the value of the slider changes
   * @param fn
   */
  registerOnChange(fn: (_: number) => void): void {
    this.onChange = fn;
  }

  /**
   * This method registered the callback function that should be called when the slider is touched
   * @param fn
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * This method is called when disabled state changes
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
