import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
  WritableSignal,
  computed,
  inject,
} from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AutotagProcessingData {
  ignoreTemplates: boolean;
  pagesStatus: WritableSignal<string[]>;
}

interface ProcessingStatus {
  status: string;
  iconTemplate: string;
}

@Component({
  selector: 'lib-autotag-processing',
  standalone: true,
  imports: [NgTemplateOutlet],
  templateUrl: './autotag-processing.component.html',
  styleUrl: './autotag-processing.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutotagProcessingComponent implements OnInit {
  private data = inject<AutotagProcessingData>(MAT_DIALOG_DATA);

  private allStatusesCompletion = new Map<string, number>()
    .set('PreprocessDocument', 3.3) // 3.4%
    .set('PreprocessPage', 3.7) // 0.3%
    .set('ConvertPDFToPNGs', 25) // 21.4%
    .set('GenerateImageSignatures', 30) // 4.6%
    .set('HandleModifiedPages', 32) // 1.7%
    .set('CheckImageSignatures', 34) // 3%
    .set('OCR', 42) // 8%
    .set('DetectEntities', 48) // 5.5%
    .set('MLToolPageType', 52) // 4.4%
    .set('OpenCVDetection', 64) // 11.5%
    .set('PageIdentification', 85) // 21.5%
    .set('GenerateOverlay', 89) // 4%
    .set('GenerateMarkup', 93) // 4.4%
    .set('PostprocessPage', 98) // 5.8%
    .set('PostprocessDocument', 99.9) // 0.5%
    .set('Processed', 100)
    .set('Markedup', 100);

  private statuses: ProcessingStatus[] = [
    {
      status: 'Analyzing pages',
      iconTemplate: 'analyzingPagesIcon',
    },
    {
      status: 'Matching templates',
      iconTemplate: 'matchingTemplatesIcon',
    },
    {
      status: 'Applying Optical Character Recognition',
      iconTemplate: 'applyingOCRIcon',
    },
    {
      status: 'Checking tag entities',
      iconTemplate: 'checkingEntitiesIcon',
    },
    {
      status: 'Generating signer tags',
      iconTemplate: 'generatingMarkupIcon',
    },
    {
      status: 'Finalizing AutoTag',
      iconTemplate: 'finalizingAutoTagIcon',
    },
  ];

  @Output() exit = new EventEmitter<void>();

  progress = computed(() => this.getProgress(this.data.pagesStatus()));
  status = computed<ProcessingStatus | undefined>(() =>
    this.getStatus(this.progress()),
  );

  ngOnInit(): void {
    // Skip 'Matching templates' status if ignoreTemplates is true
    if (this.data.ignoreTemplates) {
      const matchingTemplateStatus = this.statuses.find(
        (status) => status.status === 'Matching templates',
      ) as ProcessingStatus;
      matchingTemplateStatus.iconTemplate = this.statuses[0].iconTemplate;
      matchingTemplateStatus.status = this.statuses[0].status;
    }
  }

  /**
   * Emits an event to indicate the exit action from the component.
   */
  onExit() {
    this.exit.emit();
  }

  /**
   * Calculates and returns the processing status based on the given progress percentage.
   *
   * @param {number} progress - The current progress of the document processing.
   * @returns {ProcessingStatus} The current processing status.
   */
  private getStatus(progress: number): ProcessingStatus {
    const index = Math.floor((progress / 100) * this.statuses.length);
    const currentStatus = this.statuses[index];
    return currentStatus || this.statuses[this.statuses.length - 1];
  }

  /**
   * Calculates the average progress based on the completion status of each page.
   *
   * This function maps each page's status to its corresponding completion
   * percentage as defined in `allStatusesCompletion`.
   * It then calculates the average of these percentages to determine the overall progress.
   * If `pageStatuses` is empty, the function returns 0, indicating no progress.
   *
   * @param {string[]} pageStatuses - An array of page statuses to evaluate.
   * @returns {number} The average progress percentage of all pages.
   */
  private getProgress(pageStatuses: string[]): number {
    if (pageStatuses.length === 0) return 0;

    const pagesProgress = pageStatuses.map((status) =>
      this.allStatusesCompletion.get(status),
    ) as number[];
    const average =
      pagesProgress.reduce((a, b) => a + b) / pagesProgress.length;
    return average;
  }
}
