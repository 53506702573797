<div for="templateModel" (click)="toggleModal($event)">
  <ng-container *ngTemplateOutlet="openButtonTemplate"> </ng-container>
</div>
<input
  type="checkbox"
  [(ngModel)]="isOpen"
  id="templateModel"
  class="modal-toggle"
/>
<div [id]="id" class="modal" [ngClass]="{ 'modal-visible': isOpen }">
  <div *ngIf="isOpen" class="p-0 modal-box modal-z-index">
    <ng-container *ngTemplateOutlet="bodyTemplate"> </ng-container>
    <div class="modal-action" *ngIf="footerTemplate">
      <ng-container *ngTemplateOutlet="footerTemplate"> </ng-container>
    </div>
  </div>
  <div
    class="fixed top-0 w-screen h-screen bg-black opacity-30"
    (click)="toggleModal($event)"
  ></div>
</div>
