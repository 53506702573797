export interface AddContadtDto {
  userId: string;
  name: string;
  email: string;
  phone: string | null | undefined;
  companyId: string;
}

export interface Contact {
  contactId: string;
  userId: string;
  name: string;
  email: string;
  phone: string | null | undefined;
  companyId: string;
}
