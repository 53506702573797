export interface AdminUserCreate {
  email: string;
  firstName: string;
  lastName: string;
  company: string;
  userType: string;
  profileId: string;
  companyId: string;
  isCompanyAdmin: boolean;
  sendOnboardEmail: boolean;
  authType: 'Local';
  userId?: string;
}

export interface AdminUserCreateV2 {
  profileId: string;
  email: string;
  firstName: string;
  lastName: string;
  companyId: string;
  userType: number;
  authType: 'Local';
  phone?: string;
  isCompanyAdmin: boolean;
  userId?: string;
  legacyProfileId?: string;
  isActive?: boolean;
  sendOnboardEmail: boolean;
  company: {
    companyId: string;
    name: string;
  };
}

export interface AdminUser {
  dateCreated: string;
  datePasswordChanged: string;
  dateSignInSessionsValidFrom: string;
  enabled: boolean;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  state: string;
  mobilePhone: string;
  signInIdentities: SignInIdentity[];
  userType: string;
  profileId: string;
  companyId: string;
  isCompanyAdmin: string;
  displayName: string;
}

export interface AdminUserV2 {
  profileId: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  userType: number;
  isCompanyAdmin: boolean;
  isActive: boolean;
  isPremium: boolean;
  hasTablet: boolean;
  signingCount: number;
  rating: number;
  serviceAreaZipCode: string;
  servicingDistanceInMiles: number;
  pin: string;
  legacyProfileId: string;
  utcCreatedOn: string;
  utcModifiedOn: string;
  company: AssociatedCompaniesEntityOrCompany;
  associatedCompanies?: AssociatedCompaniesEntityOrCompany[] | null;
  skills?: SkillsEntity[] | null;
  devices?: DevicesEntity[] | null;
}

export interface StampV2 {
  stampId: string;
  commissionNumber: string;
  code: string;
  notaryName: string;
  country: string;
  state: string;
  county: string;
  format: string;
  isApproved?: boolean;
  imageUri: string;
  certificationUri: string;
  utcCreatedOn: string;
  utcIssuedOn: string;
  utcExpiresOn: string;
  isPrimary: boolean;
  profileId: string;
  profileFirstName: string;
  profileLastName: string;
  profileEmail: string;
  profilePhone?: string;
  profileUserType: string;
  profileIsCompanyAdmin: boolean;
  profileIsActive: boolean;
  profileIsPremium: boolean;
  profileLegacyProfileId?: string;
  companyId: string;
  companyName?: string;
}

export interface AssociatedCompaniesEntityOrCompany {
  companyId: string;
  name: string;
}
export interface SkillsEntity {
  skillId: number;
  name: string;
  description: string;
}
export interface DevicesEntity {
  deviceId: string;
  intuneDeviceId: string;
  deviceType: number;
  manufacturer: string;
  model: string;
  serial: string;
  name: string;
  udid: string;
  imei: string;
  iccid: string;
}

export interface SignInIdentity {
  issuer: string;
  signInType: string;
  issuerAssignedId?: string;
}
