<!-- Title -->
<h2 class="text-3xl font-bold max-w-[500px] text-center mb-16">
  Just a moment for AutoTag processing.
</h2>

<!-- Icons -->
<div class="min-h-[88px]">
  @switch (status()?.iconTemplate) { @case ('analyzingPagesIcon') {
  <ng-container *ngTemplateOutlet="analyzingPagesIcon"></ng-container>} @case
  ('matchingTemplatesIcon') {
  <ng-container *ngTemplateOutlet="matchingTemplatesIcon"></ng-container>} @case
  ('applyingOCRIcon') {
  <ng-container *ngTemplateOutlet="applyingOCRIcon"></ng-container>} @case
  ('checkingEntitiesIcon') {
  <ng-container *ngTemplateOutlet="checkingEntitiesIcon"></ng-container>
  } @case ('generatingMarkupIcon') {
  <ng-container *ngTemplateOutlet="generatingMarkupIcon"></ng-container>
  } @case ('finalizingAutoTagIcon') {
  <ng-container *ngTemplateOutlet="finalizingAutoTagIcon"></ng-container>
  } @default {
  <ng-container *ngTemplateOutlet="finalizingAutoTagIcon"></ng-container>
  } }
</div>

<!-- Status -->
<p class="text-center">{{ status()?.status || 'Loading' }}...</p>

<!-- Progress bar -->
<progress
  class="w-[264px] h-4 min-h-[16px]"
  [value]="progress()"
  max="100"
></progress>

<!-- Spacer -->
<div class="grow"></div>

<!-- Footer -->
<div class="flex flex-col justify-center gap-3 text-center">
  <button class="underline" (click)="onExit()">Exit</button>
  <p>You can return to tag the document later.</p>
  <p>Emails will only send after you review and finalize.</p>
</div>

<!-- Icon templates -->

<!-- Analyzing pages -->
<ng-template #analyzingPagesIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="89"
    height="88"
    viewBox="0 0 89 88"
    fill="none"
  >
    <path
      d="M86.0311 24.3375L63.7011 2.0075C62.4086 0.715 60.6761 0 58.8336 0H34.4136C30.6186 0 27.5386 3.08 27.5386 6.875V13.9975C12.1386 15.4 0.0385742 28.3525 0.0385742 44.11C0.0385742 59.8675 12.1386 72.82 27.5386 74.2225V81.125C27.5386 84.92 30.6186 88 34.4136 88H81.1636C84.9586 88 88.0386 84.92 88.0386 81.125V29.205C88.0386 27.3625 87.3236 25.6575 86.0311 24.3375ZM5.64857 46.145L16.5661 46.0625C17.3361 46.0625 18.0511 45.6225 18.3811 44.935L22.7536 36.245L28.2811 58.355C28.5011 59.2625 29.2986 59.895 30.2336 59.9225H30.2886C31.1961 59.9225 31.9936 59.345 32.2686 58.465L37.6311 41.36L39.4736 45.045C39.8311 45.7325 40.5461 46.1725 41.3161 46.1725H47.2286C48.3561 46.1725 49.2911 45.2375 49.2911 44.11C49.2911 42.9825 48.3561 42.0475 47.2286 42.0475H42.6086L39.0611 34.925C38.6761 34.1825 37.8511 33.7425 37.0536 33.7975C36.2011 33.88 35.5136 34.43 35.2386 35.2275L30.5636 50.215L25.4761 29.8375C25.2561 29.0125 24.5686 28.38 23.7161 28.2975C22.8636 28.1875 22.0386 28.655 21.6536 29.425L15.3561 41.9375L5.73107 42.02C6.80357 29.3425 17.4186 19.36 30.3711 19.36C44.0111 19.36 55.1211 30.47 55.1211 44.11C55.1211 50.9025 52.3711 57.035 47.9436 61.5175C47.9161 61.5175 47.8886 61.545 47.8611 61.5725C47.8611 61.5725 47.8336 61.6275 47.8061 61.655C43.3236 66.0825 37.1911 68.8325 30.3986 68.8325C17.4461 68.8325 6.80357 58.8225 5.75857 46.1175L5.64857 46.145ZM82.5386 81.125C82.5386 81.895 81.9336 82.5 81.1636 82.5H34.4136C33.6436 82.5 33.0386 81.895 33.0386 81.125V74.2225C39.3086 73.645 45.0561 71.17 49.6211 67.3475L60.2911 78.0175C60.8411 78.5675 61.5286 78.815 62.2436 78.815C62.9586 78.815 63.6461 78.54 64.1961 78.0175C65.2686 76.945 65.2686 75.2125 64.1961 74.14L53.5261 63.47C57.8986 58.2175 60.5386 51.48 60.5386 44.1375C60.5386 28.38 48.4386 15.4275 33.0386 14.025V6.9025C33.0386 6.1325 33.6436 5.5275 34.4136 5.5275H58.8336C59.1911 5.5275 59.5486 5.665 59.7961 5.94L82.1261 28.27C82.3736 28.5175 82.5386 28.875 82.5386 29.2325V81.1525V81.125Z"
      fill="#00C494"
    />
  </svg>
</ng-template>

<!-- Matching templates -->
<ng-template #matchingTemplatesIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="89"
    height="88"
    viewBox="0 0 89 88"
    fill="none"
  >
    <path
      d="M86.0311 21.6425L77.4511 13.0625C76.1586 11.77 74.3986 11.055 72.5836 11.055H59.2461C59.2461 11.055 59.1086 11.0825 59.0261 11.11L49.9511 2.035C48.6586 0.742496 46.8986 0.0274963 45.0836 0.0274963H20.6636C16.8686 0.0274963 13.7886 3.1075 13.7886 6.9025V11.055H6.91357C3.11857 11.055 0.0385742 14.135 0.0385742 17.93V70.18C0.0385742 73.975 3.11857 77.055 6.91357 77.055H13.7886V81.1525C13.7886 84.9475 16.8686 88.0275 20.6636 88.0275H67.4136C71.2086 88.0275 74.2886 84.9475 74.2886 81.1525V77.055H81.1636C84.9586 77.055 88.0386 73.975 88.0386 70.18V26.51C88.0386 24.695 87.2961 22.935 86.0311 21.6425ZM68.7886 81.125C68.7886 81.895 68.1836 82.5 67.4136 82.5H20.6636C19.8936 82.5 19.2886 81.895 19.2886 81.125V77.0275H39.0061C42.8011 77.0275 45.8811 73.9475 45.8811 70.1525V57.75C45.8811 56.2375 44.6436 55 43.1311 55C41.6186 55 40.3811 56.2375 40.3811 57.75V70.1525C40.3811 70.9225 39.7761 71.5275 39.0061 71.5275H6.91357C6.14357 71.5275 5.53857 70.9225 5.53857 70.1525V17.9025C5.53857 17.1325 6.14357 16.5275 6.91357 16.5275H30.4261C30.7836 16.5275 31.1411 16.665 31.3886 16.94L39.9686 25.52C41.0411 26.5925 42.7736 26.5925 43.8461 25.52C44.9186 24.4475 44.9186 22.715 43.8461 21.6425L35.2661 13.0625C33.9736 11.77 32.2136 11.055 30.3986 11.055H19.2611V6.9025C19.2611 6.1325 19.8661 5.5275 20.6361 5.5275H45.0561C45.4136 5.5275 45.7711 5.665 46.0186 5.94L68.3486 28.27C68.5961 28.5175 68.7611 28.875 68.7611 29.2325V81.1525L68.7886 81.125ZM82.5386 70.1525C82.5386 70.9225 81.9336 71.5275 81.1636 71.5275H74.2886V29.205C74.2886 27.3625 73.5736 25.63 72.2811 24.3375L64.4711 16.5275H72.5836C72.9411 16.5275 73.2986 16.665 73.5461 16.94L82.1261 25.52C82.3736 25.7675 82.5386 26.125 82.5386 26.4825V70.1525Z"
      fill="#00C494"
    />
    <path
      d="M32.6261 35.3375C31.7736 34.485 30.2886 35.09 30.2886 36.3V41.91H16.5386C15.4111 41.91 14.4761 42.845 14.4761 43.9725C14.4761 45.1 15.4111 46.035 16.5386 46.035H30.2886V51.645C30.2886 52.8825 31.7736 53.4875 32.6261 52.6075L40.2986 44.935C40.8486 44.385 40.8486 43.5325 40.2986 42.9825L32.6261 35.31V35.3375Z"
      fill="#00C494"
    />
  </svg>
</ng-template>

<!-- Applying OCR -->
<ng-template #applyingOCRIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="89"
    height="88"
    viewBox="0 0 89 88"
    fill="none"
  >
    <path
      d="M81.1636 88H34.4136C30.6186 88 27.5386 84.92 27.5386 81.125V74.36H33.0386V81.125C33.0386 81.895 33.6436 82.5 34.4136 82.5H81.1636C81.9336 82.5 82.5386 81.895 82.5386 81.125V29.205C82.5386 28.8475 82.4011 28.49 82.1261 28.2425L59.7961 5.9125C59.5486 5.665 59.1911 5.5 58.8336 5.5H34.4136C33.6436 5.5 33.0386 6.105 33.0386 6.875V13.86H27.5386V6.875C27.5386 3.08 30.6186 0 34.4136 0H58.8336C60.6486 0 62.4086 0.7425 63.7011 2.0075L86.0311 24.3375C87.3236 25.63 88.0386 27.3625 88.0386 29.205V81.125C88.0386 84.92 84.9586 88 81.1636 88Z"
      fill="#00C494"
    />
    <path
      d="M47.8886 36.8775H12.6886C11.5611 36.8775 10.6261 35.9425 10.6261 34.815C10.6261 33.6875 11.5611 32.7525 12.6886 32.7525H47.8886C49.0161 32.7525 49.9511 33.6875 49.9511 34.815C49.9511 35.9425 49.0161 36.8775 47.8886 36.8775Z"
      fill="#00C494"
    />
    <path
      d="M47.8886 46.0625H12.6886C11.5611 46.0625 10.6261 45.1275 10.6261 44C10.6261 42.8725 11.5611 41.9375 12.6886 41.9375H47.8886C49.0161 41.9375 49.9511 42.8725 49.9511 44C49.9511 45.1275 49.0161 46.0625 47.8886 46.0625Z"
      fill="#00C494"
    />
    <path
      d="M35.0736 55.2475H12.6886C11.5611 55.2475 10.6261 54.3125 10.6261 53.185C10.6261 52.0575 11.5611 51.1225 12.6886 51.1225H35.0736C36.2011 51.1225 37.1361 52.0575 37.1361 53.185C37.1361 54.3125 36.2011 55.2475 35.0736 55.2475Z"
      fill="#00C494"
    />
    <path
      d="M5.53857 30.3325H0.0385742V16.5825C0.0385742 15.07 1.27607 13.8325 2.78857 13.8325H16.5386V19.3325H5.53857V30.3325Z"
      fill="#00C494"
    />
    <path
      d="M16.5386 74.3325H2.78857C1.27607 74.3325 0.0385742 73.095 0.0385742 71.5825V57.8325H5.53857V68.8325H16.5386V74.3325Z"
      fill="#00C494"
    />
    <path
      d="M57.7886 74.3325H44.0386V68.8325H55.0386V57.8325H60.5386V71.5825C60.5386 73.095 59.3011 74.3325 57.7886 74.3325Z"
      fill="#00C494"
    />
    <path
      d="M60.5386 30.3325H55.0386V19.3325H44.0386V13.8325H57.7886C59.3011 13.8325 60.5386 15.07 60.5386 16.5825V30.3325Z"
      fill="#00C494"
    />
  </svg>
</ng-template>

<!-- Checking entities -->
<ng-template #checkingEntitiesIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="89"
    height="88"
    viewBox="0 0 89 88"
    fill="none"
  >
    <path
      d="M86.0311 21.6425L77.4511 13.0625C76.1586 11.77 74.4261 11.055 72.5836 11.055H60.3736L51.3536 2.035C50.0611 0.742496 48.3286 0.0274963 46.4861 0.0274963H20.6636C16.8686 0.0274963 13.7886 3.1075 13.7886 6.9025V27.5H6.91357C3.11857 27.5 0.0385742 30.58 0.0385742 34.375V67.4025C0.0385742 71.1975 3.11857 74.2775 6.91357 74.2775H13.7886V81.1525C13.7886 84.9475 16.8686 88.0275 20.6636 88.0275H67.4136C71.2086 88.0275 74.2886 84.9475 74.2886 81.1525V77.055H81.1636C84.9586 77.055 88.0386 73.975 88.0386 70.18V26.51C88.0386 24.695 87.2961 22.935 86.0311 21.6425ZM68.7886 81.125C68.7886 81.895 68.1836 82.5 67.4136 82.5H20.6636C19.8936 82.5 19.2886 81.895 19.2886 81.125V74.25H58.3936C59.9061 74.25 61.1436 73.0125 61.1436 71.5C61.1436 69.9875 59.9061 68.75 58.3936 68.75H6.91357C6.14357 68.75 5.53857 68.145 5.53857 67.375V34.3475C5.53857 33.5775 6.14357 32.9725 6.91357 32.9725H57.1836C58.6961 32.9725 59.9336 31.735 59.9336 30.2225C59.9336 28.71 58.6961 27.4725 57.1836 27.4725H19.2886V6.875C19.2886 6.105 19.8936 5.5 20.6636 5.5H46.4861C46.8436 5.5 47.2011 5.6375 47.4486 5.9125L57.2661 15.73L68.3486 26.8125C68.5961 27.06 68.7611 27.4175 68.7611 27.775V81.0975L68.7886 81.125ZM82.5386 70.1525C82.5386 70.9225 81.9336 71.5275 81.1636 71.5275H74.2886V27.775C74.2886 25.9325 73.5736 24.2275 72.2811 22.9075L65.9011 16.5H72.6111C72.9686 16.5 73.3261 16.6375 73.5736 16.9125L82.1536 25.4925C82.4011 25.74 82.5661 26.0975 82.5661 26.455V70.125L82.5386 70.1525Z"
      fill="#00C494"
    />
    <path
      d="M15.7411 39.2975C14.6686 38.225 12.9361 38.225 11.8636 39.2975C10.7911 40.37 10.7911 42.1025 11.8636 43.175L19.5361 50.8475L11.8636 58.52C10.7911 59.5925 10.7911 61.325 11.8636 62.3975C12.4136 62.9475 13.1011 63.195 13.8161 63.195C14.5311 63.195 15.2186 62.92 15.7686 62.3975L25.3936 52.7725C26.4661 51.7 26.4661 49.9675 25.3936 48.895L15.7686 39.27L15.7411 39.2975Z"
      fill="#00C494"
    />
  </svg>
</ng-template>

<!-- Generating markup -->
<ng-template #generatingMarkupIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="89"
    height="88"
    viewBox="0 0 89 88"
    fill="none"
  >
    <path
      d="M86.0311 21.6425L77.4511 13.0625C76.1586 11.77 74.4261 11.055 72.5836 11.055H60.3736L51.3536 2.035C50.0611 0.742496 48.3011 0.0274963 46.4861 0.0274963H20.6636C16.8686 0.0274963 13.7886 3.1075 13.7886 6.9025V27.5H6.91357C3.11857 27.5 0.0385742 30.58 0.0385742 34.375V67.4025C0.0385742 71.1975 3.11857 74.2775 6.91357 74.2775H13.7886V81.1525C13.7886 84.9475 16.8686 88.0275 20.6636 88.0275H67.4136C71.2086 88.0275 74.2886 84.9475 74.2886 81.1525V77.055H81.1636C84.9586 77.055 88.0386 73.975 88.0386 70.18V26.51C88.0386 24.695 87.2961 22.935 86.0311 21.6425ZM19.2886 6.875C19.2886 6.105 19.8936 5.5 20.6636 5.5H46.4861C46.8436 5.5 47.2011 5.6375 47.4486 5.9125L57.2661 15.73L68.3486 26.8125C68.5411 27.005 68.6236 27.225 68.6786 27.4725H19.2886V6.875ZM15.7411 62.4525C15.1911 63.0025 14.5036 63.25 13.7886 63.25C13.0736 63.25 12.3861 62.975 11.8361 62.4525C10.7636 61.38 10.7636 59.6475 11.8361 58.575L19.5086 50.9025L11.8361 43.23C10.7636 42.1575 10.7636 40.425 11.8361 39.3525C12.9086 38.28 14.6411 38.28 15.7136 39.3525L23.3861 47.025L31.0586 39.3525C32.1311 38.28 33.8636 38.28 34.9361 39.3525C36.0086 40.425 36.0086 42.1575 34.9361 43.23L27.2636 50.9025L34.9361 58.575C36.0086 59.6475 36.0086 61.38 34.9361 62.4525C34.3861 63.0025 33.6986 63.25 32.9836 63.25C32.2686 63.25 31.5811 62.975 31.0311 62.4525L23.3586 54.78L15.6861 62.4525H15.7411ZM68.7886 81.125C68.7886 81.895 68.1836 82.5 67.4136 82.5H20.6636C19.8936 82.5 19.2886 81.895 19.2886 81.125V74.25H68.7886V81.125ZM82.5386 70.1525C82.5386 70.9225 81.9336 71.5275 81.1636 71.5275H74.2886V27.775C74.2886 25.9325 73.5736 24.2275 72.2811 22.9075L65.9011 16.5H72.6111C72.9686 16.5 73.3261 16.6375 73.5736 16.9125L82.1536 25.4925C82.4011 25.74 82.5661 26.0975 82.5661 26.455V70.125L82.5386 70.1525Z"
      fill="#00C494"
    />
  </svg>
</ng-template>

<!-- Finalizing AutoTag -->
<ng-template #finalizingAutoTagIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="89"
    height="88"
    viewBox="0 0 89 88"
    fill="none"
  >
    <path
      d="M86.0311 21.6425L77.4511 13.0625C76.1586 11.77 74.4261 11.055 72.5836 11.055H60.3736L51.3536 2.035C50.0611 0.742496 48.3286 0.0274963 46.4861 0.0274963H20.6636C16.8686 0.0274963 13.7886 3.1075 13.7886 6.9025V18.7C5.51107 24.0625 0.0385742 33.385 0.0385742 44C0.0385742 54.615 5.51107 63.9375 13.7886 69.3275V81.125C13.7886 84.92 16.8686 88 20.6636 88H67.4136C71.2086 88 74.2886 84.92 74.2886 81.125V77.0275H81.1636C84.9586 77.0275 88.0386 73.9475 88.0386 70.1525V26.4825C88.0386 24.6675 87.2961 22.9075 86.0311 21.615V21.6425ZM11.2036 45.9525C10.1311 44.88 10.1311 43.1475 11.2036 42.075C12.2761 41.0025 14.0086 41.0025 15.0811 42.075L23.4411 50.435L42.0586 31.8175C43.1311 30.745 44.8636 30.745 45.9361 31.8175C47.0086 32.89 47.0086 34.6225 45.9361 35.695L25.3661 56.265C24.8161 56.815 24.1286 57.0625 23.4136 57.0625C22.6986 57.0625 22.0111 56.7875 21.4611 56.265L11.1486 45.9525H11.2036ZM68.7886 81.125C68.7886 81.895 68.1836 82.5 67.4136 82.5H20.6636C19.8936 82.5 19.2886 81.895 19.2886 81.125V72.16C22.6986 73.5075 26.4111 74.25 30.2886 74.25C47.0086 74.25 60.5386 60.6925 60.5386 44C60.5386 27.3075 46.9811 13.75 30.2886 13.75C26.4111 13.75 22.6986 14.52 19.2886 15.84V6.875C19.2886 6.105 19.8936 5.5 20.6636 5.5H46.4861C46.8436 5.5 47.2011 5.6375 47.4486 5.9125L57.2661 15.73L68.3486 26.8125C68.5961 27.06 68.7611 27.4175 68.7611 27.775V81.0975L68.7886 81.125ZM82.5386 70.1525C82.5386 70.9225 81.9336 71.5275 81.1636 71.5275H74.2886V27.775C74.2886 25.9325 73.5736 24.2 72.2811 22.9075L65.8736 16.5H72.5836C72.9411 16.5 73.2986 16.6375 73.5461 16.9125L82.1261 25.4925C82.3736 25.74 82.5386 26.0975 82.5386 26.455V70.125V70.1525Z"
      fill="#00C494"
    />
  </svg>
</ng-template>
