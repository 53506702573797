import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@et/environment';
import { ClosingNotary } from '@et/typings';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotaryService {
  constructor(private http: HttpClient) {}

  /**
   * takes an address and a radius, and returns an observable of ClosingNotary objects
   * @param {string} address - The address of the property
   * @param [radius=5] - The radius in miles to search for notaries.
   * @returns ClosingNotary[]
   */
  fetchNNANotary(address: string, radius = 5): Observable<ClosingNotary[]> {
    return this.http
      .get<{ data: ClosingNotary[] }>(
        `${environment.nnaScheduler.domain}/recommend-notary?address=${address}&radius=${radius}`,
      )
      .pipe(map((d) => d.data));
  }

  /**
   * "Fetch the notaries from the NNA Scheduler API."
   *
   * takes in an address, a count, and a radius. The address is the address of the property.
   * The count is the number of notaries to return. The radius is the distance from the
   * property to search for notaries
   * @param {string} address - The address of the property
   * @param [count=5] - The number of notaries to return.
   * @param [radius=5] - The radius in miles to search for notaries.
   * @returns An array of ClosingNotary objects.
   */
  fetchNNANotaries(
    address: string,
    count = 5,
    radius = 5,
  ): Observable<ClosingNotary[]> {
    return this.http
      .get<{ data: ClosingNotary[] }>(
        `${environment.nnaScheduler.domain}/recommend-notaries?address=${address}&radius=${radius}&count=${count}`,
      )
      .pipe(map((d) => d.data));
  }
}
