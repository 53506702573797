import { Injectable, computed, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class OnlineStatusService {
  private _isOnline = signal<boolean>(window.navigator.onLine);

  isOnline = computed(() => this._isOnline());
  isOnline$ = toObservable(this.isOnline);

  constructor() {
    this.listenToOnlineStatus();
  }

  /**
   * Sets the online status of the application.
   *
   * This function adds event listeners for the 'online' and 'offline' events on the window object.
   * When the 'online' event is fired, it sets the `_isOnline` signal to true.
   * When the 'offline' event is fired, it sets the `_isOnline` signal to false.
   */
  private listenToOnlineStatus(): void {
    window.addEventListener('online', () => this._isOnline.set(true));
    window.addEventListener('offline', () => this._isOnline.set(false));
  }
}
