import { Injectable } from '@angular/core';
import { Dan } from '@escrowtab/v2profile-api-client';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root',
})
export class DansRepositoryService {
  protected _tableName = 'dans';

  constructor(private storage: StorageService) {}

  /**
   * This function sets initial dummy value in the table
   */
  async addDummy() {
    try {
      await this.setTable();
      await this.storage.setItem('0', '1');
    } catch (error) {
      console.error('DansRepositoryService: ', error);
      return;
    }
  }

  /**
   * This function return all values from store
   */
  async getAll(): Promise<Array<Dan[]>> {
    try {
      await this.setTable();
      const dansJSONArrWithDummy = await this.storage.getAllValues();
      // Remove dummy
      const dansJSONArr = dansJSONArrWithDummy.filter((a) => a !== '1');
      const dans: Array<Dan[]> = [];
      for (const danJSON of dansJSONArr) {
        const dan = JSON.parse(danJSON);
        dans.push(dan);
      }
      return dans;
    } catch (error) {
      console.error('DansRepositoryService: ', error);
      return [];
    }
  }

  /**
   * This function gets annotation by id
   * @param {string} id - Packet id
   */
  async get(id: string): Promise<Dan[] | null> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return null;
      }
      const dansJSON = await this.storage.getItem(id);
      const dans = JSON.parse(dansJSON);
      return dans;
    } catch (error) {
      console.error('DansRepositoryService: ', error);
      return null;
    }
  }

  /**
   * This function adds new dans array to storage
   * @param {string} id - Packet id
   * @param {Dan[]} dans - Dan[]
   */
  async createOrUpdate(id: string, dans: Dan[]): Promise<void> {
    try {
      await this.setTable();
      const dansJSON = JSON.stringify(dans);
      await this.storage.setItem(id, dansJSON);
    } catch (error) {
      console.error('DansRepositoryService: ', error);
    }
  }

  /**
   * This function deletes dans in storage
   * @param {string} id - Packet id
   */
  async delete(id: string): Promise<void> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return;
      }
      await this.storage.removeItem(id);
    } catch (error) {
      console.error('DansRepositoryService: ', error);
    }
  }

  /**
   * This function deletes all keys
   */
  async clear(): Promise<void> {
    try {
      await this.setTable();
      await this.storage.clear();
      await this.addDummy();
    } catch (error) {
      console.error('DansRepositoryService: ', error);
    }
  }

  /**
   * Reset dans to unused in DB
   * @param {string} id - packet Id
   */
  async resetDans(id: string): Promise<void> {
    try {
      const dans = await this.get(id);
      if (!dans) {
        return;
      }
      dans.forEach((dan) => (dan.isUsed = false));
      await this.createOrUpdate(id, dans);
    } catch (error) {
      console.error('DansRepositoryService: ', error);
    }
  }

  /**
   * This function sets storage table
   */
  async setTable() {
    this.storage.setTable(this._tableName);
  }
}
