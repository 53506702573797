import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Stamp, StampsService } from '@escrowtab/v2profile-api-client';
import { environment } from '@et/environment';
import { ProfileState, SetNotaryStamp } from '@et/guards';
import { NotificationService } from '@et/notifications';
import { DatabaseService } from '@et/storage';
import { StampWithImage } from '@et/typings';
import {
  parseErrorObject,
  convertFileToDataURLAsync,
  isOnline,
} from '@et/utils';
import { Store } from '@ngxs/store';
import {
  Observable,
  of,
  switchMap,
  forkJoin,
  map,
  catchError,
  first,
  from,
  retry,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StampsApiService {
  constructor(
    private store: Store,
    private stampsService: StampsService,
    private http: HttpClient,
    private notificationService: NotificationService,
    private dbService: DatabaseService,
  ) {}

  fetchStamps(): Observable<StampWithImage[]> {
    const profileId = this.store.selectSnapshot(ProfileState.v2ProfileId);

    if (!profileId) {
      return of([]);
    }

    if (!isOnline()) {
      return from(this.dbService.stampService.getAll(profileId)).pipe(
        map((stamps) => {
          this.store.dispatch(new SetNotaryStamp(stamps));
          return stamps;
        }),
      );
    }

    return this.stampsService.getStampsByProfile({ profileId }).pipe(
      switchMap((res) => {
        const stamps = res.data as Stamp[];
        if (stamps.length === 0) {
          return of([]);
        }
        // TODO: Investigate how get file from api call with StampsService
        const stampUrls = stamps.map((stamp) => stamp.imageUri) as string[];
        const reqs = this.stampImagesReqs(stampUrls);
        return forkJoin(reqs).pipe(
          map((images) => images.map((image, i) => ({ ...stamps[i], image }))),
          retry({ count: 3, delay: 300 }),
        ) as Observable<StampWithImage[]>;
      }),
      switchMap(async (stamps) => {
        await this.dbService.stampService.saveAll(stamps);
        this.store.dispatch(new SetNotaryStamp(stamps));
        return stamps;
      }),
      catchError((error) => {
        // TODO: enable this when idle behavior is worked out
        // if (error.status === 401) {
        //   this.router.navigate(['/system-error'], {
        //     queryParams: { returnUrl: this.router.url },
        //   });
        // }
        this.store.dispatch(new SetNotaryStamp([]));
        const errMsg = parseErrorObject(error);
        this.notificationService.showError(errMsg);
        return of([]);
      }),
      retry({ count: 3, delay: 300 }),
      first(),
    );
  }

  /**
   * It makes an array of observables with stamp image requests
   * @returns Array of observables resolving with stamp images - Observable<string | ArrayBuffer | null>[]
   */
  private stampImagesReqs(stampUrls: string[] | undefined[] | null[]) {
    return stampUrls.map((stampImageUrl) => {
      if (!stampImageUrl) {
        return of(null);
      }
      const reqUrl = environment.profilev2.domain + '/api/stamps/file';
      const params = new HttpParams().append('url', stampImageUrl);

      return this.http.get(reqUrl, { responseType: 'blob', params }).pipe(
        switchMap((blob) => {
          return blob.text().then((txt) => {
            if (txt.includes('<svg')) {
              return txt;
            } else {
              const file = new File([blob], 'Stamp', { type: 'image/png' });
              return convertFileToDataURLAsync(file);
            }
          });
        }),
      );
    });
  }
}
