<div class="w-full form-control">
  <label class="absolute ml-3 -mt-[18px] text-xs label">
    <span class="px-2 label-text text-light bg-primary-content">{{
      label
    }}</span>
  </label>
  <select
    [(ngModel)]="selected"
    class="bottom-0 w-full font-medium bg-primary-content select-bordered select focus:outline-midnight focus:border-transparent focus:outline-offset-0"
    (ngModelChange)="optionChanged.emit(selected)"
  >
    <option
      *ngFor="let option of options"
      [disabled]="option?.disabled"
      [ngValue]="option"
    >
      {{ option?.name }}
    </option>
  </select>
</div>
