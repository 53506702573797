import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  Input,
  signal,
  ViewChild,
} from '@angular/core';
import { PresentationalAtomsModule } from '../presentational-atoms.module';
import { DocumentProcessApiService } from '@et/api';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  catchError,
  debounceTime,
  filter,
  map,
  Observable,
  switchMap,
  tap,
} from 'rxjs';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import { ConnectedPosition, Overlay } from '@angular/cdk/overlay';
import { TemplatesSearchListComponent } from '../templates-search-list/templates-search-list.component';
import { DocPrepDocument } from '@et/typings';
import { NotificationService } from '@et/notifications';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'et-atoms-templates-search',
  standalone: true,
  imports: [
    PresentationalAtomsModule,
    ReactiveFormsModule,
    TemplatesSearchListComponent,
    AsyncPipe,
    NgTemplateOutlet,
    RouterLink,
  ],
  templateUrl: './templates-search.component.html',
  styleUrl: './templates-search.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TemplatesSearchComponent,
      multi: true,
    },
  ],
})
export class TemplatesSearchComponent implements ControlValueAccessor {
  private autotagService = inject(DocumentProcessApiService);
  private overlay = inject(Overlay);
  private notificationService = inject(NotificationService);

  private onChange!: (value: DocPrepDocument | undefined) => void;
  private onTouched!: () => void;
  protected disabled = false;

  @Input({ required: true }) titleAgentEmail: string | null | undefined;
  @ViewChild('inputEl') inputEl: ElementRef<HTMLInputElement> | undefined;

  template = signal<DocPrepDocument | undefined>(undefined);

  searchCtrl = new FormControl('');
  searchResults$ = this.getSearchResults();

  isSearchMenuOpen = signal<boolean>(false);

  position: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 1,
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetY: -1,
    },
  ];
  scrollStrategy = this.overlay.scrollStrategies.block();

  /**
   * Handles the selection of a template.
   *
   * This method sets the search control value to the selected template's name, closes the search menu,
   * emits the selected template, and blurs the input element.
   *
   * @param {DocPrepDocument} template - The selected template.
   */
  onTemplateSelected(template: DocPrepDocument) {
    this.template.set(template);
    this.isSearchMenuOpen.set(false);
    this.inputEl?.nativeElement.blur();
    this.onChange(template);
    this.onTouched();
  }

  /**
   * Handles the deletion of the selected template.
   *
   * This method emits the `templateDeleted` event and resets the search control.
   */
  onDeleteSelectedTemplate() {
    this.searchCtrl.reset();
    this.template.set(undefined);
    this.onChange(undefined);
    this.onTouched();
    setTimeout(() => this.inputEl?.nativeElement.focus());
  }

  /**
   * Writes the value to the component.
   *
   * This method sets the provided template value to the component.
   *
   * @param {DocPrepDocument} value - The template value to be written.
   * @returns {void}
   */
  writeValue(value: DocPrepDocument): void {
    this.template.set(value);
  }

  /**
   * Registers a callback function to be called when the value changes.
   *
   * This method sets the `onChange` callback function.
   *
   * @param {(value: DocPrepDocument | undefined) => void} fn - The callback function to be registered.
   * @returns {void}
   */
  registerOnChange(fn: (_: DocPrepDocument | undefined) => void): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function to be called when the component is touched.
   *
   * This method sets the `onTouched` callback function.
   *
   * @param {() => void} fn - The callback function to be registered.
   * @returns {void}
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Sets the disabled state of the component.
   *
   * This method sets the `disabled` property of the component based on the provided value.
   *
   * @param {boolean} isDisabled - The disabled state to be set.
   * @returns {void}
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * Retrieves the search results.
   *
   * This method listens for changes in the search value, constructs the request body, and fetches
   * the templates from the `autotagService`. If an error occurs during the fetch, it shows an error notification.
   * It maps the results to the list of documents and updates the search menu open state based on the search value
   * and the number of documents.
   *
   * @returns {Observable<DocPrepDocument[]>} An observable that emits the list of search result documents.
   */
  private getSearchResults(): Observable<DocPrepDocument[]> {
    return this.getSearchValue().pipe(
      switchMap((search) => {
        const body = this.getBody(search);
        return this.autotagService.getTemplates(body).pipe(
          catchError((err) => {
            this.notificationService.showError('Failed to load templates');
            throw err;
          }),
        );
      }),
      map((results) => results.documents),
      tap((documents) => {
        if (this.searchCtrl.value && documents.length > 0) {
          this.isSearchMenuOpen.set(true);
        } else {
          this.isSearchMenuOpen.set(false);
        }
      }),
    );
  }

  /**
   * Retrieves the search value from the search control.
   *
   * This method listens for changes in the search value from the `searchCtrl` form control.
   * It filters out empty values, sets the search menu open state to false if the search value
   * length is less than 3, filters out values with a length of 2 or less, and debounces the
   * input by 300 milliseconds.
   *
   * @returns {Observable<string>} An observable that emits the search value.
   */
  private getSearchValue(): Observable<string> {
    return this.searchCtrl.valueChanges.pipe(
      filter(Boolean),
      tap((search) => {
        if (search.length < 3) this.isSearchMenuOpen.set(false);
      }),
      filter((search) => search.length > 2),
      debounceTime(300),
    );
  }

  /**
   * Constructs the request body for fetching templates.
   *
   * This method creates the request body with the search value and the title agent's email.
   * It sets up the column filters to filter templates based on the search value.
   *
   * @param {string} search - The search value to filter templates by.
   */
  private getBody(search: string) {
    const columnFilters = [
      {
        column: 'Template',
        filterModifier: 'AND',
        filters: [{ type: 'Contains', filter: search }],
      },
    ];
    const titleAgentEmail = this.titleAgentEmail as string;
    return { titleAgentEmail, columnFilters };
  }
}
