export interface PlaceResult {
  address_components?: GeocoderAddressComponent[];
  adr_address?: string;
  aspects?: PlaceAspectRating[];
  formatted_address?: string;
  formatted_phone_number?: string;
  geometry?: PlaceGeometry;
  html_attributions?: string[];
  icon?: string;
  id?: string;
  international_phone_number?: string;
  name: string;
  opening_hours?: OpeningHours;
  permanently_closed?: boolean;
  photos?: PlacePhoto[];
  place_id?: string;
  price_level?: number;
  rating?: number;
  reviews?: PlaceReview[];
  types?: string[];
  url?: string;
  utc_offset?: number;
  vicinity?: string;
  website?: string;
}

interface OpeningHours {
  open_now: boolean;
  periods: OpeningPeriod[];
  weekday_text: string[];
}

interface PlacePhoto {
  height: number;
  html_attributions: string[];
  width: number;
}

interface PlaceReview {
  aspects: PlaceAspectRating[];
  author_name: string;
  author_url: string;
  language: string;
  text: string;
}

interface OpeningPeriod {
  open: OpeningHoursTime;
  close?: OpeningHoursTime;
}

interface GeocoderAddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

interface OpeningHoursTime {
  day: number;
  hours: number;
  minutes: number;
  nextDate: number;
  time: string;
}

interface PlaceAspectRating {
  rating: number;
  type: string;
}
interface PlaceGeometry {
  location: LatLng;
  viewport: LatLngBounds;
}

interface LatLngBoundsLiteral {
  east: number;
  north: number;
  south: number;
  west: number;
}

interface LatLng {
  /**
   * Creates a LatLng object representing a geographic point.
   * Note the ordering of latitude and longitude.
   * @param lat Latitude is specified in degrees within the range [-90, 90].
   * @param lng Longitude is specified in degrees within the range [-180,
   *     180].
   * @param noWrap Set noWrap to true to enable values outside of this range.
   */
  /**
   * Creates a LatLng object representing a geographic point.
   * @param literal Object literal.
   * @param noWrap Set noWrap to true to enable values outside of this range.
   */
  /** Comparison function. */
  equals(other: LatLng): boolean;
  /** Returns the latitude in degrees. */
  lat(): number;
  /** Returns the longitude in degrees. */
  lng(): number;
  /** Converts to string representation. */
  toString(): string;
  /**
   * Returns a string of the form "lat,lng". We round the lat/lng values to 6
   * decimal places by default.
   */
  toUrlValue(precision?: number): string;
  /**
   * Converts to JSON representation. This function is intended to be used
   * via JSON.stringify.
   */
  toJSON(): LatLngLiteral;
}

interface LatLngLiteral {
  /**
   * Latitude in degrees. Values will be clamped to the range [-90, 90]. This
   * means that if the value specified is less than -90, it will be set to
   * -90. And if the value is greater than 90, it will be set to 90.
   */
  lat: number;
  /**
   * Longitude in degrees. Values outside the range [-180, 180] will be
   * wrapped so that they fall within the range. For example, a value of -190
   * will be converted to 170. A value of 190 will be converted to -170. This
   * reflects the fact that longitudes wrap around the globe.
   */
  lng: number;
}

interface LatLngBounds {
  /**
   * Constructs a rectangle from the points at its south-west and north-east
   * corners.
   */
  /** Returns true if the given lat/lng is in this bounds. */
  contains(latLng: LatLng | LatLngLiteral): boolean;
  /** Returns true if this bounds approximately equals the given bounds. */
  equals(other: LatLngBounds | LatLngBoundsLiteral): boolean;
  /** Extends this bounds to contain the given point. */
  extend(point: LatLng | LatLngLiteral): LatLngBounds;
  /** Computes the center of this LatLngBounds */
  getCenter(): LatLng;
  /** Returns the north-east corner of this bounds. */
  getNorthEast(): LatLng;
  /** Returns the south-west corner of this bounds. */
  getSouthWest(): LatLng;
  /** Returns true if this bounds shares any points with the other bounds. */
  intersects(other: LatLngBounds | LatLngBoundsLiteral): boolean;
  /** Returns if the bounds are empty. */
  isEmpty(): boolean;
  /**
   * Converts to JSON representation. This function is intended to be used
   * via JSON.stringify.
   */
  toJSON(): LatLngBoundsLiteral;
  /** Converts the given map bounds to a lat/lng span. */
  toSpan(): LatLng;
  /** Converts to string. */
  toString(): string;
  /**
   * Returns a string of the form "lat_lo,lng_lo,lat_hi,lng_hi" for this
   * bounds, where "lo" corresponds to the southwest corner of the bounding
   * box, while "hi" corresponds to the northeast corner of that box.
   */
  toUrlValue(precision?: number): string;
  /**
   * Extends this bounds to contain the union of this and the given bounds.
   */
  union(other: LatLngBounds | LatLngBoundsLiteral): LatLngBounds;
}
