import { Selector } from '@ngxs/store';
import { EtJournalState, EtJournalModel } from './et-journal.state';

export class EtJournalStateSelectors {
  @Selector([EtJournalState])
  static getSigningSessions(state: EtJournalModel) {
    return state.signingSessions;
  }

  @Selector([EtJournalState])
  static getSigningInfo(state: EtJournalModel) {
    return state.signingInfo;
  }

  @Selector([EtJournalState])
  static getSigningSessionsError(state: EtJournalModel) {
    return state.signingSessionsError;
  }

  @Selector([EtJournalState])
  static getSigningSessionsLoadinger(state: EtJournalModel) {
    return state.isSigningSessionsLoading;
  }

  @Selector([EtJournalState])
  static getSelectedSigningSession(state: EtJournalModel) {
    return state.selectedSigningSession;
  }

  @Selector([EtJournalState])
  static getVerifyErrorMsg(state: EtJournalModel) {
    return state.verifyErrorMsg;
  }

  @Selector([EtJournalState])
  static getEtJournalFlow(state: EtJournalModel) {
    return state.etJournalflow;
  }
}
