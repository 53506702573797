import { StateOperator } from '@ngxs/store';
import {
  compose,
  iif,
  insertItem,
  NoInfer,
  patch,
  Predicate,
  updateItem,
} from '@ngxs/store/operators';

/**
 * This function updates an item in an array if it exists, otherwise it inserts the item.
 * The item is identified by a selector, which can be a number (index) or a predicate function.
 *
 * @param {number | Predicate<T>} selector - The index or predicate function to identify the item.
 * @param {NoInfer<T>} upsertValue - The value to be inserted or used to update the existing item.
 *
 * @returns {StateOperator<T[]>} A state operator that can be used with NGXS to update the state.
 */
export function upsertItem<T>(
  selector: number | Predicate<T>,
  upsertValue: NoInfer<T>,
): StateOperator<T[]> {
  return compose<T[]>(
    (items) => <T[]>(items || []),
    iif<T[]>(
      () => Number(selector) === selector,
      iif<T[]>( // @ts-ignore
        (items) => selector < items.length,
        // @ts-ignore
        updateItem(selector, patch(upsertValue)),
        insertItem(upsertValue, <number>selector),
      ),
      iif<T[]>(
        // @ts-ignore
        (items) => items.some(<Predicate<T>>selector),
        // @ts-ignore
        updateItem(selector, patch(upsertValue)),
        insertItem(upsertValue),
      ),
    ),
  );
}
