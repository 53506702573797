export interface SecureLink {
  linkId: string;
  name: string;
  expirationDate: string;
  linkType?: string;
  pinRequired?: boolean;
}

export interface SecureLinkResendResponse {
  LinkId: string;
  EntityId: string;
  DisplayName: string;
  PINCode: number;
  CompanyName: string;
  OriginatorName: string;
  FileName: string;
  RecipientEmail: string;
  IsExternal: boolean;
  LinkType: string;
  ExpirationDate: string;
  UtcCreatedDate: string;
  Subject: string;
  Message: string;
}

export interface SecureLinkData {
  entityId: string;
  displayname: string;
  companyName: string;
  originatorName: string;
  fileName: string;
  linkType: 'file' | 'signing';
  emails: string[];
  isExternal?: boolean;
  pinRequired?: boolean;
  subject?: string;
  message?: string;
}
