import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { JwtGuard } from '@et/guards';
import { ProfileResolver } from '@et/resolvers';

import { HomeComponent } from './components/home/home.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [JwtGuard, MsalGuard],
    resolve: { profile: ProfileResolver },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@et/et-journal/home').then((mod) => mod.EtJournalHomeModule),
      },
      {
        path: 'verify',
        loadChildren: () =>
          import('@et/et-journal/verify').then(
            (mod) => mod.EtJournalVerifyModule,
          ),
      },
    ],
  },
  {
    path: 'auth',
    loadChildren: () => import('@et/auth').then((mod) => mod.AuthModule),
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
