// Document markup response interfaces

import { DocumentMarkupStatus } from '../constants/native.constants';

export interface DocumentMarkupAPIResponse {
  data: DocumentMarkupResponse;
  code: number;
  msg: string;
}

export interface DocumentMarkupResponse {
  status: DocumentMarkupStatus;
  data: DocumentMarkupDataResponse;
  ['document-id']: string;
}

interface DocumentMarkupDataResponse {
  pages: DocumentMarkupPageResponse[];
}

interface DocumentMarkupPageTabResponse {
  type: string;
  signer: string;
  signerIndex?: number;
  keyboard?: boolean;
  required: boolean;
  x: number;
  y: number;
  heigth: number;
  width: number;
}

interface DocumentMarkupPageResponse {
  page: number;
  status: string;
  type: string | null;
  notarize: boolean;
  enote: boolean;
  ['print-and-sign']?: boolean;
  ['notarial-act']?: boolean;
  ['no-extra-marks']?: boolean;
  tabs: DocumentMarkupPageTabResponse[];
}

// Transformed document markup interfaces

export interface DocumentMarkup {
  status: DocumentMarkupStatus;
  data: DocumentMarkupData;
  ['document-id']: string;
}

export interface DocumentMarkupData
  extends Omit<DocumentMarkupDataResponse, 'pages'> {
  pages: DocumentMarkupPage[];
}

export interface DocumentMarkupPage
  extends Omit<DocumentMarkupPageResponse, 'tabs'> {
  stamped: boolean;
  tabs: DocumentMarkupPageTab[];
}

export interface DocumentMarkupPageTab extends DocumentMarkupPageTabResponse {
  signed: boolean;
  required: boolean;
  id: string;
  matrixId: string;
  eNote: boolean;
}

export interface PdfContainerPosition {
  left: number;
  right: number;
  width: number;
  height: number;
  x: number;
  y: number;
}
