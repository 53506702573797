import { Directive, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: 'input[etAtomsInput]',
})
export class EtInputDirective implements OnInit, OnDestroy {
  lableClickSub!: Subscription;

  labelEl!: HTMLLabelElement;
  inputEl!: HTMLInputElement;

  constructor(private el: ElementRef<HTMLInputElement>) {}

  ngOnInit(): void {
    this.inputEl = this.el.nativeElement;

    // Get label
    this.labelEl = this.el.nativeElement.parentElement?.querySelector(
      'label',
    ) as HTMLLabelElement;
    if (!this.labelEl) {
      console.error(
        '<label></label> is required inside <et-atoms-input></et-atoms-input> component',
      );
    }
    // Focus on input when label is clicked
    const label$ = fromEvent(this.labelEl, 'click');
    this.lableClickSub = label$.subscribe(() => this.inputEl.focus());

    // Adding placeholder for slide up animation for label
    this.inputEl.placeholder = 'placeholder';
  }

  ngOnDestroy(): void {
    this.lableClickSub?.unsubscribe();
  }
}
