export const STAMPS_BY_STATE = [
  {
    showSvg: false,
    manual: true,
    state: 'Alabama',
    'rect-1': 'us-al-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Alaska',
    'rect-1': 'us-ak-rect-1.svg',
  },
  {
    showSvg: true,
    manual: false,
    state: 'Arizona',
    'rect-1': 'us-az-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Arkansas',
    'rect-1': 'us-ar-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'California',
    'rect-1': 'us-ca-rect-1.svg',
  },
  {
    showSvg: true,
    manual: false,
    state: 'Colorado',
    'rect-1': 'us-co-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Connecticut',
    'rect-1': 'us-ct-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Delaware',
    'rect-1': 'us-de-rect-1.svg',
  },
  {
    showSvg: false,
    manual: false,
    state: 'Florida',
    'rect-1': 'us-fl-rect-1.svg',
  },
  {
    showSvg: false,
    manual: false,
    state: 'Georgia',
    'rect-1': 'us-ga-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Hawaii',
    'rect-1': 'us-hi-rect-1.svg',
  },
  {
    showSvg: true,
    manual: false,
    state: 'Idaho',
    'rect-1': 'us-id-rect-1.svg',
    'circle-1': 'us-id-circle-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Illinois',
    'rect-1': 'us-il-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Indiana',
    'rect-1': 'us-in-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Iowa',
    'rect-1': 'us-ia-rect-1.svg',
  },
  {
    showSvg: true,
    manual: false,
    state: 'Kansas',
    'rect-1': 'us-ks-rect-1.svg',
    'circle-1': 'us-ks-circle-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Kentucky',
    'rect-1': 'us-ky-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Louisiana',
    'rect-1': 'us-la-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Maine',
    'rect-1': 'us-me-rect-1.svg',
  },
  {
    showSvg: true,
    manual: false,
    state: 'Maryland',
    'rect-1': 'us-md-rect-1.svg',
    'rect-2': 'us-md-rect-2.svg',
    'circle-1': 'us-md-circle-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Massachusetts',
    'rect-1': 'us-ma-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Michigan',
    'rect-1': 'us-mi-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Minnesota',
    'rect-1': 'us-mn-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Mississippi',
    'rect-1': 'us-ms-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Missouri',
    'rect-1': 'us-mo-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Montana',
    'rect-1': 'us-mt-rect-1.svg',
    'circle-1': 'us-mt-circle-1.svg',
  },
  {
    showSvg: true,
    manual: false,
    state: 'Nebraska',
    'rect-1': 'us-ne-rect-1.svg',
    'circle-1': 'us-ne-circle-1.svg',
  },
  {
    showSvg: false,
    manual: false,
    state: 'Nevada',
    'rect-1': 'us-nv-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'New Hampshire',
    'rect-1': 'us-nh-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'New Jersey',
    'rect-1': 'us-nj-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'New Mexico',
    'rect-1': 'us-nm-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'New York',
    'rect-1': 'us-ny-rect-1.svg',
  },
  {
    showSvg: true,
    manual: false,
    state: 'North Carolina',
    'rect-1': 'us-nc-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'North Dakota',
    'rect-1': 'us-nd-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Ohio',
    'rect-1': 'us-oh-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Oklahoma',
    'rect-1': 'us-ok-rect-1.svg',
  },
  {
    showSvg: false,
    manual: false,
    state: 'Oregon',
    'rect-1': 'us-or-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Pennsylvania',
    'rect-1': 'us-pa-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Rhode Island',
    'rect-1': 'us-ri-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'South Carolina',
    'rect-1': 'us-sc-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'South Dakota',
    'rect-1': 'us-sd-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Tennessee',
    'rect-1': 'us-tn-rect-1.svg',
  },
  {
    showSvg: true,
    manual: false,
    state: 'Texas',
    'rect-1': 'us-tx-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Utah',
    'rect-1': 'us-ut-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Vermont',
    'rect-1': 'us-vt-rect-1.svg',
  },
  {
    showSvg: true,
    manual: false,
    state: 'Virginia',
    'rect-1': 'us-va-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Washington',
    'rect-1': 'us-wa-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'West Virginia',
    'rect-1': 'us-wv-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Wisconsin',
    'rect-1': 'us-wi-rect-1.svg',
  },
  {
    showSvg: false,
    manual: true,
    state: 'Wyoming',
    'rect-1': 'us-wy-rect-1.svg',
  },
];
export const STAMP_TEMPLATES_PATH = 'assets/stamp-tamplates/';
