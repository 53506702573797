import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'et-atoms-zoom-buttons',
  templateUrl: './zoom-buttons.component.html',
  styleUrls: ['./zoom-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ZoomButtonsComponent {
  @Input() zoom!: number;
  @Input() max!: number;
  @Input() min = 1;
  @Input() disabled = false;

  @Output() zoomChange = new EventEmitter<number>();

  onZoomIn() {
    if (this.zoom > this.min) {
      this.zoom--;
      this.zoomChange.emit(this.zoom);
    }
  }

  onZoomOut() {
    if (this.zoom < this.max) {
      this.zoom++;
      this.zoomChange.emit(this.zoom);
    }
  }
}
