import { MsalService } from '@azure/msal-angular';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private msalService: MsalService) {}

  /**
   * Logs the user out and redirects them to the home page
   *
   * @memberof AuthService
   */
  logout() {
    this.msalService.logoutRedirect();
  }
}
