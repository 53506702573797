export interface SigningSession {
  id: string;
  name: string;
  fileName: string;
}

export interface SigningInfo {
  feeCharged?: number;
  signerDetails?: SignerDetails[];
  signignState?: string;
  stripeSessionId?: string;
  eJournalId?: string;
}

export interface SignerDetails {
  name?: string;
  state?: string;
  idNumber?: string;
  idType?: string;
  utcIdIssuedOn?: string;
  utcIdExpiresOn?: string;
  address?: string;
  confirmSignerName?: boolean;
  captureSignature?: boolean;
  signatureImg?: string;
}

export interface StripeSessionMetadata {
  userId: string;
  userName: string;
  userPhone?: string;
  userEmail: string;
  documentId: string;
  documentName: string;
  signers: SignerDetails[] | string;
  fee?: number;
  eJournalId?: string;
}

export interface StripeVerificationSession {
  id: string;
  object: string;
  client_secret?: string;
  created: number;
  last_error?: {
    code: string;
    reason: string;
  };
  last_verification_report: string;
  livemode: boolean;
  metadata: {};
  options: {
    document: {
      allowed_types?: string;
      require_id_number: boolean;
      require_live_capture: boolean;
      require_matching_selfie: boolean;
    };
    id_number?: string;
  };
  redaction?: string;
  status: 'requires_input' | 'verified' | 'processing' | 'canceled';
  type: string;
  url?: string;
  verified_outputs?: string;
}

export interface StripeVerificationReport {
  id: string;
  object: string;
  created: number;
  document: StripeDocument;
  id_number?: any;
  livemode: boolean;
  options: {
    document: {
      allowed_types?: any;
      require_id_number: boolean;
      require_live_capture: boolean;
      require_matching_selfie: boolean;
    };
    id_number?: any;
  };
  selfie: {
    document: string;
    error?: any;
    selfie: string;
    status: string;
  };
  type: string;
  verification_session: string;
}

interface StripeDocument {
  address: {
    city: string;
    country: string;
    line1: string;
    line2: string;
    postal_code: string;
    state: string;
  };
  dob?: any;
  error?: any;
  expiration_date?: any;
  files: string[];
  first_name: string;
  issued_date: {
    day: number;
    month: number;
    year: number;
  };
  issuing_country: string;
  last_name: string;
  number?: any;
  status: string;
  type: string;
}

export enum EtJournalFlowType {
  'idVerivication' = 'idVerivication',
  'eJournalEntry' = 'eJournalEntry',
}
