import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';

const MENU_WIDTH = 304;
const MENU_OFFSET_X = 17;
const MENU_OFFSET_Y = -11;

@Component({
  selector: 'et-atoms-document-menu',
  templateUrl: './document-menu.component.html',
  styleUrls: ['./document-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentMenuComponent {
  @Input() width = MENU_WIDTH;
  @Input() offsetX = MENU_OFFSET_X;
  @Input() offsetY = MENU_OFFSET_Y;

  @Input() showEditBtn = true;
  @Input() showSaveAndExit = true;
  @Input() showDelete = true;

  @Input() fileName: string | undefined;
  @Input() totalPages: number | undefined;

  @Output() menuEdit = new EventEmitter<void>();
  @Output() menuSaveAndExit = new EventEmitter<void>();
  @Output() menuDelete = new EventEmitter<void>();
  @Output() menuClose = new EventEmitter<void>();
  @Output() menuOpen = new EventEmitter<void>();

  positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  isOpen = false;

  /**
   * Emits an event menuOpen and toggles menu
   *
   * @memberOf DocumentMenuComponent
   */
  onMenuClick() {
    this.menuOpen.emit();
    this.isOpen = !this.isOpen;
  }

  /**
   * Emits an event menuClose and closes menu
   *
   * @memberOf DocumentMenuComponent
   */
  onClose() {
    this.menuClose.emit();
    this.isOpen = false;
  }

  /**
   * Emits events menuEdit, menuClose and closes menu
   *
   * @memberOf DocumentMenuComponent
   */
  onEdit() {
    this.menuEdit.emit();
    this.onClose();
  }

  /**
   * Emits events menuSaveAndExit, menuClose and closes menu
   *
   * @memberOf DocumentMenuComponent
   */
  onSaveAndExit() {
    this.menuSaveAndExit.emit();
    this.onClose();
  }

  /**
   * Emits events menuDelete, menuClose and closes menu
   *
   * @memberOf DocumentMenuComponent
   */
  onDelete() {
    this.menuDelete.emit();
    this.onClose();
  }
}
