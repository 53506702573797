import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'et-atoms-native-signing-progress',
  templateUrl: './native-signing-progress.component.html',
  styleUrls: ['./native-signing-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NativeSigningProgressComponent {
  @Input() totalItems: number | undefined;
  @Input() completedItems: number | undefined;
  @Input() showText = true;

  /**
   * Checks if passed value is a number. Returns a boolean
   */
  isNumber(val: number | undefined) {
    return Number.isInteger(val);
  }
}
