export const environmentOptions = ['Integration', 'Production'];

export const submittingOrganizations = [
  {
    value: '1014212',
    name: '1014212 - EscrowTab, Inc.',
  },
  { value: '1014848', name: '1014848 - EscrowTab Test A' },
  { value: '1014849', name: '1014849 - EscrowTab Test B' },
  { value: '9999234', name: '9999234 - EscrowTab Test C' },
];

export const requestingOrganizations = [
  {
    value: '1014848',
    name: '1014848 - EscrowTab Test A',
  },
  { value: '1014849', name: '1014849 - EscrowTab Test B' },
  { value: '9999234', name: '9999234 - EscrowTab Test C' },
  { value: '1014212', name: '1014212 - EscrowTab, Inc.' },
  { value: '1009313', name: '1009313 - PennyMac Corp.' },
  {
    value: '1007159',
    name: '1007159 - PennyMac Loan Services, LLC',
  },
  {
    value: '1001489',
    name: '1001489 - Union Bank and Trust Company',
  },
  {
    value: '1016580',
    name: '1016580 - Union Bank and Trust Company',
  },
  {
    value: '1008780',
    name: '1008780 - Kirkland Financial',
  },
  { value: '1002591', name: '1002591 - NFM, Inc.' },
];

export const recievingOrganizations = [
  {
    value: '1000001',
    name: '1000001 - MERS',
  },
  { value: '1014623', name: '1014623 - MERS Test' },
];

export const transferOptions = [
  'TransferAll',
  'TransferControl',
  'TransferLocation',
  'TransferDelegatee',
  'TransferControlAndLocation',
  'TransferLocationAndDelegatee',
  'TransferControlAndDelegatee',
];

export const eNoteStatusOptions = [
  'AssumptionReversal',
  'ChargedOff',
  'ChargedOffReversal',
  'ConvertedToPaper',
  'ConvertedToPaperReversal',
  'DocumentReversal',
  'ModificationReversal',
  'PaidOff',
  'PaidOffReversal',
  'RegistrationReversal',
  'TransferredToProprietaryRegistry',
  'TransferredToProprietaryRegistryReversal',
  'Other',
];

export const inquiryOptions = ['SummaryInformation', 'StatusInformation'];
