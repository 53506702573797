<!-- Button -->
<div (click)="onContentClick()" cdkOverlayOrigin #trigger="cdkOverlayOrigin">
  <!-- Projected content that triggets the popup -->
  <ng-content></ng-content>
</div>

<!-- Popup content tamplate -->
<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayOffsetX]="offsetX"
  [cdkConnectedOverlayOffsetY]="offsetY"
  [cdkConnectedOverlayPositions]="positions"
  [cdkConnectedOverlayFlexibleDimensions]="flexibleDimensions"
  [cdkConnectedOverlayGrowAfterOpen]="growAfterOpen"
  (backdropClick)="onClose()"
>
  <!-- Popup card -->
  <div
    class="flex flex-col justify-between p-6 rounded-lg shadow-[0px_3px_8px_4px_rgba(0,0,0,0.06)] bg-base-200 w-fit h-fit card"
  >
    <!-- Popup projected content -->
    <ng-container *ngTemplateOutlet="popupTemplate"></ng-container>
  </div>
</ng-template>
