<div class="flex items-center justify-center h-[80vh]">
  <div class="flex flex-col">
    <img src="assets/images/EscrowTabLogo.svg" class="w-auto h-32" />
    <p class="pt-6 text-6xl font-bold text-center text-midnight">Welcome</p>
    <p class="pb-12 text-2xl font-medium text-center text-midnight">
      Login to your account
    </p>
    <form
      class="flex flex-col"
      [formGroup]="form"
      (ngSubmit)="login(form.value)"
    >
      <et-atoms-input>
        <label class="!bg-base-100">Username</label>
        <input
          class="!bg-transparent !w-96"
          etAtomsInput
          formControlName="email"
          type="email"
          autocomplete="email"
        />
        <span
          etAtomsInputError
          *ngIf="
            form.get('email')?.touched &&
            form.get('email')?.hasError('isOffline')
          "
          >Please, login when online</span
        >
      </et-atoms-input>
      <div class="flex justify-center">
        <button
          type="submit"
          [disabled]="form.invalid || submitted"
          class="self-center capitalize rounded-full w-44 btn btn-xl btn-primary"
        >
          Login
        </button>
        <et-atoms-spinner
          *ngIf="submitted"
          class="absolute mt-1 text-center ml-[250px]"
        ></et-atoms-spinner>
      </div>
    </form>
    <button
      *ngIf="deviceIsNative && !isOnline"
      routerLink="/pincode"
      class="mt-10 underline normal-case rounded-full btn btn-link text-midnight"
    >
      Login offline
    </button>
  </div>
</div>

<img
  class="fixed left-0 max-w-full -bottom-1 md:-bottom-6 xl:-bottom-8 2xl:-bottom-14 -z-10"
  src="assets/images/Background-Trees.svg"
/>

<!-- App version -->
<p class="absolute text-sm text-white right-2 bottom-1">v {{ version }}</p>
