export interface BaseItemObjectUserApiModel {
  active: boolean;

  /**
   * User email address
   */
  email: string;

  /**
   * User password, MUST contain a mix of upper and lower case letters, as well as digits
   */
  password: string;

  /**
   * User phone number in international format
   */
  phone: null | string;
  username: string;
  uuid: string;
}
