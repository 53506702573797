import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface PreviewData {
  thumbnail: string;
  fileName: string;
}

@Component({
  selector: 'et-atoms-doc-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPreviewComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: PreviewData) {}
}
