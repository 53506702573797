import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './components/alert/alert.component';
import { AlertListComponent } from './components/alert-list/alert-list.component';

@NgModule({
  imports: [CommonModule],
  declarations: [AlertComponent, AlertListComponent],
  exports: [AlertListComponent],
})
export class NotificationsModule {}
