<!-- Wrapper - hover -->
<div
  class="transition-all flex flex-col justify-center border-2 rounded-lg border-opacity-0 hover:border-opacity-100 border-primary w-[160px] h-[76px] cursor-pointer group"
  [ngClass]="{ 'bg-midnight': isSelected }"
>
  <!-- Wrapper - content and divider -->
  <div
    class="relative px-[15px] w-full h-[60px] before:absolute before:w-[1px] before:h-full before:bg-neutral before:-left-[2px] group-hover:before:opacity-0"
    [ngClass]="{ 'before:opacity-0': isSelected }"
  >
    <h1
      class="mb-0 text-4xl font-bold text-midnight"
      [ngClass]="{ 'text-white': isSelected }"
    >
      {{ count }}
    </h1>
    <p
      class="my-0 text-xs font-medium"
      [ngClass]="{
        'text-white': isSelected,
        'text-light': !isSelected
      }"
    >
      <ng-content></ng-content>
    </p>
  </div>
</div>
