import { Injectable } from '@angular/core';
import { VerificationService } from '@et/et-journal/services';
import { EtJournalFlowType, SigningInfo, SigningSession } from '@et/typings';
import { parseErrorObject } from '@et/utils';
import { Action, State, StateContext } from '@ngxs/store';
import produce from 'immer';
import { catchError, map, throwError } from 'rxjs';
import {
  ClearEtJournalStore,
  FetchEtJournalDocuments,
  FetchEtJournalDocumentsError,
  FetchEtJournalDocumentsSuccess,
  SelectSigningSession,
  SetEtJournalFlow,
  SetSigningInfo,
  SetVerificationError,
  UpdateSigningInfo,
} from './et-journal.actions';

export interface EtJournalModel {
  signingSessions: SigningSession[] | undefined;
  selectedSigningSession: SigningSession | undefined;
  isSigningSessionsLoading: boolean;
  signingSessionsError: string | undefined;
  signingInfo: SigningInfo | undefined;
  verifyErrorMsg: string | undefined;
  etJournalflow: EtJournalFlowType | undefined;
}

@State<EtJournalModel>({
  name: 'EtJournalStore',
  defaults: {
    signingSessions: undefined,
    selectedSigningSession: undefined,
    isSigningSessionsLoading: false,
    signingSessionsError: undefined,
    signingInfo: undefined,
    verifyErrorMsg: undefined,
    etJournalflow: undefined,
  },
})
@Injectable()
export class EtJournalState {
  constructor(private verificationService: VerificationService) {}

  @Action(FetchEtJournalDocuments)
  fetchDocuments(ctx: StateContext<EtJournalModel>) {
    // Set state to loading
    ctx.setState({
      ...ctx.getState(),
      selectedSigningSession: undefined,
      signingSessions: undefined,
      isSigningSessionsLoading: true,
      signingSessionsError: undefined,
    });
    return this.verificationService.getUnsignedDocuments().pipe(
      map((docs) => ctx.dispatch(new FetchEtJournalDocumentsSuccess(docs))),
      catchError((error) => {
        const errMsg = parseErrorObject(error);
        ctx.dispatch(new FetchEtJournalDocumentsError(errMsg));
        return throwError(() => error);
      }),
    );
  }

  @Action(FetchEtJournalDocumentsSuccess)
  fetchDocumentSuccess(
    ctx: StateContext<EtJournalModel>,
    { documents }: FetchEtJournalDocumentsSuccess,
  ) {
    const state = produce(ctx.getState(), (draft) => {
      draft.signingSessions = documents;
      draft.isSigningSessionsLoading = false;
      draft.signingSessionsError = undefined;
    });
    ctx.setState(state);
  }

  @Action(FetchEtJournalDocumentsError)
  fetchEtJournalDocumentsError(
    ctx: StateContext<EtJournalModel>,
    { error }: FetchEtJournalDocumentsError,
  ) {
    const state = produce(ctx.getState(), (draft) => {
      draft.isSigningSessionsLoading = false;
      draft.signingSessionsError = error;
    });
    ctx.setState(state);
  }

  @Action(SelectSigningSession)
  selectSigningSession(
    ctx: StateContext<EtJournalModel>,
    { document }: SelectSigningSession,
  ) {
    const state = produce(ctx.getState(), (draft) => {
      draft.selectedSigningSession = document;
    });
    ctx.setState(state);
  }

  @Action(SetSigningInfo)
  setSigningInfo(
    ctx: StateContext<EtJournalModel>,
    { signingInfo }: SetSigningInfo,
  ) {
    const state = produce(ctx.getState(), (draft) => {
      draft.signingInfo = signingInfo
        ? { ...draft.signingInfo, ...signingInfo }
        : undefined;
    });
    ctx.setState(state);
  }

  @Action(UpdateSigningInfo)
  updateSigningInfo(
    ctx: StateContext<EtJournalModel>,
    { signingInfo }: UpdateSigningInfo,
  ) {
    const sInfo = ctx.getState().signingInfo as SigningInfo;
    const state = produce(ctx.getState(), (draft) => {
      draft.signingInfo = { ...sInfo, ...signingInfo };
    });
    ctx.setState(state);
  }

  @Action(SetVerificationError)
  setVerificationError(
    ctx: StateContext<EtJournalModel>,
    { error }: SetVerificationError,
  ) {
    const state = produce(ctx.getState(), (draft) => {
      draft.verifyErrorMsg = error;
    });
    ctx.setState(state);
  }

  @Action(SetEtJournalFlow)
  setEtJournalFlow(
    ctx: StateContext<EtJournalModel>,
    { flowType }: SetEtJournalFlow,
  ) {
    const state = produce(ctx.getState(), (draft) => {
      draft.etJournalflow = flowType;
    });
    ctx.setState(state);
  }

  @Action(ClearEtJournalStore)
  clearStore(ctx: StateContext<EtJournalModel>) {
    ctx.setState({
      selectedSigningSession: undefined,
      signingSessions: undefined,
      isSigningSessionsLoading: true,
      signingSessionsError: undefined,
      signingInfo: undefined,
      verifyErrorMsg: undefined,
      etJournalflow: undefined,
    });
  }
}
