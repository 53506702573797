import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';

const MENU_OFFSET_X = 0;
const MENU_OFFSET_Y = 0;

@Component({
  selector: 'et-atoms-native-popup',
  templateUrl: './native-popup.component.html',
  styleUrls: ['./native-popup.component.scss'],
})
export class NativePopupComponent {
  @Input() offsetX = MENU_OFFSET_X;
  @Input() offsetY = MENU_OFFSET_Y;
  @Input() popupTemplate!: TemplateRef<unknown>;
  @Input() disabled = false;
  @Input() flexibleDimensions = false;
  @Input() growAfterOpen = false;
  @Input()
  positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
    },
  ];

  @Output() menuClose = new EventEmitter<void>();

  isOpen = false;

  /**
   * Changes menu visibility by clicking no projected content
   *
   * @memberOf NativePopupComponent
   */
  onContentClick() {
    if (this.disabled) {
      return;
    }
    this.isOpen = !this.isOpen;
  }

  /**
   * Closes menu
   *
   * @memberOf NativePopupComponent
   */
  onClose() {
    this.isOpen = false;
    this.menuClose.emit();
  }

  /**
   * Open menu
   *
   * @memberOf NativePopupComponent
   */
  open() {
    this.isOpen = true;
  }
}
