import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
} from '@angular/core';
import { DocumentMarkupPage, ENOTE_PAGES_FOR_SIGNING } from '@et/typings';

@Component({
  selector: 'et-atoms-native-required-fields',
  templateUrl: './native-required-fields.component.html',
  styleUrls: ['./native-required-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NativeRequiredFieldsComponent implements OnChanges {
  show = false;
  doNotSign = false;
  signed!: number;
  totaldFields!: number;
  needStamp!: boolean;

  @Input() page!: number;
  @Input() signatureFields: DocumentMarkupPage[] | null | undefined;
  @Input() isENote = false;
  @Input() set printAndSign(val: boolean | undefined) {
    this.doNotSign = val && val === true ? true : false;
  }

  ngOnChanges(): void {
    if (this.page && this.signatureFields) {
      this.show = false;
      this.getRequiredFields();
    }

    // Display 'Do not sign' for eNote doc
    if (this.isENote && !ENOTE_PAGES_FOR_SIGNING.includes(this.page)) {
      this.doNotSign = true;
    } else if (this.isENote && ENOTE_PAGES_FOR_SIGNING.includes(this.page)) {
      this.doNotSign = false;
    }
  }

  /**
   * This function sets class properties: signed, totaldFields, show and needStamp from signatureFields
   */
  private getRequiredFields() {
    const pageSignatures = this.signatureFields?.find(
      (p) => p.page === this.page,
    );
    if (!pageSignatures) {
      return;
    }

    // Display signatures required
    this.signed = pageSignatures.tabs.filter((t) => t.signed === true).length;
    this.totaldFields = pageSignatures.tabs.filter((t) => t.required).length;
    this.show = true;

    // Display stamp required
    if (pageSignatures.notarize && !pageSignatures.stamped) {
      this.needStamp = true;
    } else {
      this.needStamp = false;
    }
  }
}
