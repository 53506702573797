import { Observable, filter, of, switchMap } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Store } from '@ngxs/store';
import { ProfileState } from '../state/profile.state';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { Capacitor } from '@capacitor/core';
import { platformType } from '@et/typings';

@Injectable({ providedIn: 'root' })
export class NativeJwtGuard {
  constructor(
    private router: Router,
    private store: Store,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
  ) {}

  canActivate(_: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const platform = Capacitor.getPlatform();

    if (platform === platformType.ELECTRON) {
      return this.nativeUwp(state);
    } else {
      return this.webGuard(state);
    }
  }

  /**
   * This function is used to guard routes for native applications.
   * It checks if the user profile with claims exists in the store.
   *
   * If the user profile exists, it allows the navigation by returning an Observable of true.
   *
   * If the user profile doesn't exist, it redirects the user to the login page and returns an Observable of false.
   * The URL of the current state is passed as a query parameter 'returnUrl' to the login page.
   * This 'returnUrl' can be used to redirect the user back to the page they were trying to access after they log in.
   *
   * @param {RouterStateSnapshot} state - The current router state.
   * @returns {Observable<boolean>} - An Observable that emits true if the user profile exists, false otherwise.
   */
  private nativeUwp(state: RouterStateSnapshot): Observable<boolean> {
    if (this.store.selectSnapshot(ProfileState.getAadProfileWithClaims)) {
      return of(true);
    } else {
      this.router.navigate(['/auth/login'], {
        queryParams: { returnUrl: state.url },
      });
      return of(false);
    }
  }

  /**
   * This function is used to guard routes for web applications.
   * It waits until the MSAL (Microsoft Authentication Library) service is not in progress,
   * then checks if there are any accounts in the MSAL service.
   *
   * If there are accounts, it allows the navigation by returning an Observable of true.
   *
   * If there are no accounts, it redirects the user to the login page and returns an Observable of false.
   * The URL of the current state is passed as a query parameter 'returnUrl' to the login page.
   * This 'returnUrl' can be used to redirect the user back to the page they were trying to access after they log in.
   *
   * @param {RouterStateSnapshot} state - The current router state.
   * @returns {Observable<boolean>} - An Observable that emits true if there are accounts in the MSAL service, false otherwise.
   */
  private webGuard(state: RouterStateSnapshot): Observable<boolean> {
    return this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None),
      switchMap(() => {
        if (this.msalService.instance.getAllAccounts().length > 0) {
          return of(true);
        }

        this.router.navigate(['/auth/login'], {
          queryParams: { returnUrl: state.url },
        });
        return of(false);
      }),
    );
  }
}
