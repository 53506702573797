import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map, catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IpService {
  private http = inject(HttpClient);

  /**
   * This function retrieves the client's IP address from the 'ipify' API.
   * If the request fails for any reason, it returns 'n/a'.
   *
   * @returns {Observable<string>} An Observable that emits the client's IP address, or 'n/a' if the request fails.
   */
  getMyIP(): Observable<string> {
    return this.http
      .get<{ ip: string }>('https://api.ipify.org/?format=json')
      .pipe(
        map((res) => res.ip),
        catchError((error) => {
          console.error(error);
          return of('n/a');
        }),
      );
  }
}
