import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  RendererFactory2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DocumentDataSource } from './document-sidebar.datasource';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { DocumentMarkup } from '@et/typings';
import {
  hasSignatureRequired,
  hasStampRequired,
  isStampComplete,
} from './document-sidebar.helper';

export interface SidebarPage {
  pageNumber: number;
  img: string;
  complete: boolean | null;
  hasSignatureRequired: boolean | null;
  hasStampRequired: boolean | null;
  isSignatureComplete: boolean | null;
  isStampComplete: boolean | null;
}

export interface ListItemSidebar {
  title: string;
  isSelected: boolean;
  isSignatureComplete: boolean | null;
  isStampComplete: boolean | null;
  hasSignatureRequired: boolean | null;
  hasStampRequired: boolean | null;
}

@Component({
  selector: 'et-atoms-document-sidebar',
  templateUrl: './document-sidebar.component.html',
  styleUrls: ['./document-sidebar.component.scss'],
})
export class DocumentSidebarComponent implements OnInit, OnChanges {
  @HostBinding('style.width.px')
  @Input()
  width = 226;

  @Input() minBufferPx = 100;
  @Input() maxBufferPx = 200;

  @Input() pdfFile: File | string | undefined;

  @Input() selectedPage: number | null = 0;

  @Input() showListView = false;

  @Input() documentMarkup!: DocumentMarkup | null;

  @Output() paggeSelected = new EventEmitter<number>();

  @ViewChild(CdkVirtualScrollViewport)
  scrollViewport!: CdkVirtualScrollViewport;

  preventScroll = false;

  dataSource!: DocumentDataSource;

  mode: 'page-view' | 'list-view' = 'page-view';

  listItems: ListItemSidebar[] = [];

  constructor(private rendererFactory: RendererFactory2) {}

  ngOnInit(): void {
    this.initDataSource();
    this.checkPageTypesCount();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Scroll to selected page
    if (changes['selectedPage'] && this.scrollViewport) {
      if (this.preventScroll) {
        this.preventScroll = false;
        return;
      }
      const previousPage = changes['selectedPage'].previousValue;
      const currentPage = changes['selectedPage'].currentValue;
      const delta = Math.abs(previousPage - currentPage);
      const opt = delta > 4 ? 'auto' : 'smooth';
      const scrollToIndex = changes['selectedPage']?.currentValue - 2 || 0;
      this.scrollViewport.scrollToIndex(scrollToIndex, opt);
    }
    if (changes['documentMarkup']) {
      this.formatListItems();
    }
    this.checkPageTypesCount();
  }

  /**
   * It changes the mode of the document sidebar
   * @param mode - 'page-view' | 'list-view'
   */
  changeMode(mode: 'page-view' | 'list-view') {
    this.mode = mode;
    if (mode === 'page-view') {
      this.initDataSource();
      setTimeout(() => {
        this.scrollToSelectedPage();
      }, 250);
    } else {
      this.scrollViewport?.scrollToIndex(0, 'smooth');
    }
  }

  /**
   * Scroll to selected page
   */
  private scrollToSelectedPage() {
    if (!this.scrollViewport) return;
    const selectedPage = this.selectedPage;
    if (!selectedPage) return;

    this.scrollViewport.scrollToIndex(selectedPage - 2, 'smooth');
  }

  /**
   * It initializes data source for virtual scroll
   * @returns
   */
  initDataSource() {
    if (!this.pdfFile) return;
    const renderer = this.rendererFactory.createRenderer(null, null);
    // Init datasource
    const dataSource = new DocumentDataSource(
      this.pdfFile,
      renderer,
      this.documentMarkup ?? null,
    );
    // Set datasource
    this.dataSource = dataSource;
  }

  /**
   * It takes page number and emits it to parent component
   * @param pageNumber - page number
   * @returns
   */
  onSelectPage(pageNumber: number) {
    if (pageNumber === this.selectedPage) return;
    this.preventScroll = true;
    this.paggeSelected.emit(pageNumber);
  }

  /**
   * It selects an item from the list
   * @param title - title of the item
   */
  onSelectFromList(title: string) {
    const _title = title === 'Unknown' ? null : title;
    const firstPage = this.documentMarkup?.data.pages.find(
      (page) => page.type?.toLowerCase() === _title?.toLowerCase(),
    );
    if (firstPage) {
      this.onSelectPage(firstPage.page);
    }
  }

  /**
   * It is a track by function for virtual scroll
   * @param _ - unused
   * @param page - page
   * @returns - page number
   */
  trackBy(_: unknown, page: SidebarPage) {
    return page.pageNumber;
  }

  /**
   * It formats the list items based on the document markup
   */
  private formatListItems() {
    if (!this.documentMarkup) return;
    const pages = this.documentMarkup?.data.pages.map((page) => {
      return {
        ...page,
        type: page.type ?? 'Unknown',
      };
    });
    const uniqueTypes = [...new Set(pages.map((page) => page.type))];
    this.listItems = uniqueTypes.map((type) => {
      const _hasSignatureRequired = pages
        .filter((page) => page.type?.toLowerCase() === type?.toLowerCase())
        .some((page) =>
          hasSignatureRequired(
            this.documentMarkup as DocumentMarkup,
            page.page,
          ),
        );
      return {
        title: type,
        isSelected: false,
        isSignatureComplete: !_hasSignatureRequired,
        isStampComplete: !!pages
          .filter((page) => page.type?.toLowerCase() === type?.toLowerCase())
          .every((page) =>
            isStampComplete(this.documentMarkup as DocumentMarkup, page.page),
          ),
        hasSignatureRequired: !!_hasSignatureRequired,
        hasStampRequired: !!pages
          .filter((page) => page.type?.toLowerCase() === type?.toLowerCase())
          .some((page) => {
            return hasStampRequired(
              this.documentMarkup as DocumentMarkup,
              page.page,
            );
          }),
      };
    });
  }

  /**
   * It checks if the document markup has at least 2 page types
   */
  private checkPageTypesCount() {
    const pagesTypeCount = this.documentMarkup?.data.pages.filter(
      (page) => page.type !== null,
    );
    if (pagesTypeCount?.length && pagesTypeCount.length > 1) {
      this.mode = 'list-view';
    } else {
      this.showListView = false;
    }
  }
}
