import { Injectable } from '@angular/core';
import { StorageService } from '../storage.service';
import { AnnotationRepositoryService } from './annotation-repository.service';
import { AuditActionsRepositoryService } from './audit-actions-repository.service';
import { EnoteFilesRepositoryService } from './enote-files-repository.service';
import { MarkupRepositoryService } from './markup-repository.service';
import { PacketRepositoryService } from './packet-repository.service';
import { DansService } from '@escrowtab/v2profile-api-client';
import { lastValueFrom } from 'rxjs';
import { isOnline } from '@et/utils';
import { DansRepositoryService } from './dans-repository.service';

interface DocumentInfo {
  dateCreated: string;
  userId: string;
  documentId: string;
  eNoteId: string | null | undefined;
  markedForDeletion?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DocumentsTrackerService {
  protected _tableName = 'documents_tracker';

  constructor(
    private annotations: AnnotationRepositoryService,
    private packets: PacketRepositoryService,
    private markup: MarkupRepositoryService,
    private eNoteFiles: EnoteFilesRepositoryService,
    private auditActions: AuditActionsRepositoryService,
    private dans: DansRepositoryService,
    private dansService: DansService,
    private storage: StorageService,
  ) {}

  /**
   * This function sets initial dummy value in the table
   */
  async addDummy() {
    try {
      await this.setTable();
      await this.storage.setItem('0', '1');
    } catch (error) {
      console.error('DocumentsTrackerService: ', error);
      return;
    }
  }

  /**
   * This function returns all values from DB by user id
   * @param {string} userId - User id
   */
  async getAll(userId: string): Promise<DocumentInfo[]> {
    try {
      await this.setTable();
      const documentsJSONArrWithDummy = await this.storage.getAllValues();
      // Remove dummy
      const documentsJSONArr = documentsJSONArrWithDummy.filter(
        (d) => d !== '1',
      );
      const documents: DocumentInfo[] = [];
      for (const documentsJSON of documentsJSONArr) {
        const document = JSON.parse(documentsJSON) as DocumentInfo;
        if (document.userId === userId) {
          documents.push(document);
        }
      }
      return documents;
    } catch (error) {
      console.error('DocumentsTrackerService: ', error);
      return [];
    }
  }

  /**
   * This function gets tracker by id
   * @param {string} id - Packet id
   */
  async get(id: string): Promise<DocumentInfo | null> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return null;
      }
      const docInfoJSON = await this.storage.getItem(id);
      const docInfo = JSON.parse(docInfoJSON);
      return docInfo;
    } catch (error) {
      console.error('DansRepositoryService: ', error);
      return null;
    }
  }

  /**
   * This function returns a count of document in DB by user id
   * @param {string} userId - User id
   */
  async count(userId: string): Promise<number | null> {
    try {
      await this.setTable();
      const documents = await this.getAll(userId);
      return documents.length;
    } catch (error) {
      console.error('DocumentsTrackerService: ', error);
      return null;
    }
  }

  /**
   * This function adds DocumentInfo to DB
   * @param {string} documentId - Packet id
   * @param {string} userId - User id
   */
  async create(
    userId: string,
    documentId: string,
    eNoteId: string | null | undefined,
  ): Promise<void> {
    try {
      await this.setTable();
      // Don't update document if exists in DB
      const exists = await this.storage.isKey(documentId);
      if (exists) {
        return;
      }
      const documentInfo: DocumentInfo = {
        userId,
        documentId,
        eNoteId,
        dateCreated: new Date().toISOString(),
      };
      const documentInfoJSON = JSON.stringify(documentInfo);
      await this.storage.setItem(documentId, documentInfoJSON);
    } catch (error) {
      console.error('DocumentsTrackerService: ', error);
    }
  }

  /**
   * This function adds DocumentInfo to DB
   * @param {string} packetId - Packet id
   * @param {DocumentInfo} documentInfo - DocumentInfo
   */
  async update(packetId: string, documentInfo: DocumentInfo): Promise<void> {
    try {
      await this.setTable();
      const documentInfoJSON = JSON.stringify(documentInfo);
      await this.storage.setItem(packetId, documentInfoJSON);
    } catch (error) {
      console.error('DocumentsTrackerService: ', error);
    }
  }

  /**
   * This function deletes DocumentInfo in storage
   * @param {string} id - Packet id
   */
  async delete(id: string): Promise<void> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return;
      }
      await this.storage.removeItem(id);
    } catch (error) {
      console.error('DocumentsTrackerService: ', error);
    }
  }

  /**
   * This function deletes expired documents
   * @param {string} userId - Packet id
   */
  async deleteExpired(userId: string): Promise<void> {
    try {
      const expiration = 14 * 24 * 60 * 60 * 1000; /* ms. - 14 days */
      const today = new Date().getTime();
      const expirationDay = today - expiration;
      const documents = await this.getAll(userId);
      for (const document of documents) {
        const isExpired =
          new Date(document.dateCreated).getTime() <= expirationDay;
        if (isExpired || document.markedForDeletion) {
          await this.deleteExpiredDocuments(
            userId,
            document.documentId,
            document.eNoteId,
          );
        }
      }
    } catch (error) {
      console.error('DocumentsTrackerService: ', error);
    }
  }

  /**
   * This function deletes expired document and related records
   * @param {string} userId - user if
   * @param {string} documentId - packet id
   * @param {string} eNoteId - eNote id
   */
  private async deleteExpiredDocuments(
    userId: string,
    documentId: string,
    eNoteId?: string | null,
  ) {
    if (isOnline()) {
      await this.delete(documentId);
      await this.releaseDans(documentId, userId);
      await this.dans.delete(documentId);
    }
    await this.annotations.delete(documentId);
    await this.packets.delete(documentId);
    await this.markup.delete(documentId);
    await this.auditActions.delete(documentId);
    if (eNoteId) {
      await this.eNoteFiles.delete(eNoteId);
      await this.markup.delete(eNoteId);
    }
  }

  /**
   * This function deletes all documents by userId
   * @param {string} userId - user id
   */
  async clearByUserId(userId: string): Promise<void> {
    try {
      await this.setTable();
      const documents = await this.getAll(userId);
      for (const document of documents) {
        if (document.userId === userId) {
          await this.delete(document.documentId);
        }
      }
    } catch (error) {
      console.error('DocumentsTrackerService: ', error);
    }
  }

  /**
   * This function deletes all keys
   */
  async clearAll(): Promise<void> {
    try {
      await this.setTable();
      await this.storage.clear();
      await this.addDummy();
    } catch (error) {
      console.error('DocumentsTrackerService: ', error);
    }
  }

  /**
   * This function sets storage table
   */
  async setTable() {
    await this.storage.setTable(this._tableName);
  }

  /**
   * This function releases dan numbers
   * @param {string} packetId - packet id
   * @param {string} profileId - user id
   * @returns
   */
  private async releaseDans(packetId: string, profileId: string) {
    const dans = await this.dans.get(packetId);
    if (!dans) {
      return;
    }
    try {
      const unusedNumbers = dans.map((dan) => dan.number as string);
      await lastValueFrom(
        this.dansService.useDans({
          useDansDto: { profileId, unusedNumbers },
        }),
      );
    } catch (error) {
      console.log(error);
    }
  }
}
