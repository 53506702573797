<h1 mat-dialog-title class="flex items-center">
  <img
    src="assets/icons/lock-open.svg"
    alt="Open lock"
    class="inline w-auto h-6 m-0 mr-3"
  />
  Logout of ET-verifid
</h1>

<div mat-dialog-content class="flex gap-6">
  <!-- Closing buttons -->
  <div class="flex-col grid-cols-2 gap-6 md:flex">
    <button
      cdkFocusInitial
      (click)="onLogout()"
      class="normal-case rounded-full btn btn-wide md:btn-xl bg-midnight btn-outline min-w-[139px] text-base-100 h-10 mb-3 md:mb-0"
    >
      Logout
    </button>
    <button
      mat-dialog-close
      class="normal-case rounded-full btn btn-wide md:btn-xl btn-outline min-w-[139px] h-10"
    >
      Cancel
    </button>
  </div>
</div>
