import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Output,
} from '@angular/core';

@Component({
  selector: 'et-atoms-tile-menu-item',
  template: ` <ng-content></ng-content> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileMenuItemComponent {
  @Output()
  clicked = new EventEmitter<void>();

  @HostListener('click')
  onClick() {
    this.clicked.emit();
  }
}
