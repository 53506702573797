import { Capacitor } from '@capacitor/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { platformType } from '@et/typings';
let networkStatus: ConnectionStatus;
Network.addListener('networkStatusChange', (status) => {
  networkStatus = status;
});

Network.getStatus().then((status) => {
  networkStatus = status;
});
/**
 * It returns true if the browser is online, and false if it's offline
 * @returns A boolean value.
 */
export function isOnline() {
  if (Capacitor.getPlatform() === platformType.ANDROID) {
    return networkStatus?.connectionType !== 'none' || window.navigator.onLine;
  }
  return window.navigator.onLine;
}
