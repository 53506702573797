import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { ProfileState, SetAADUserWithClaims } from '@et/guards';
import { AADUserWithClaims } from '@et/typings';
import { Store } from '@ngxs/store';
import { map, Observable, filter, first, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileResolver {
  constructor(
    private store: Store,
    private msal: MsalService,
    private router: Router,
  ) {}

  resolve(): Observable<boolean> {
    return this.store.select(ProfileState.getAadProfileWithClaims).pipe(
      map((profile) => {
        if (!profile) {
          const account = this.msal.instance.getActiveAccount();
          if (!account) {
            this.msal
              .logout()
              .pipe(take(1))
              .subscribe(() => this.router.navigate(['/']));
            return false;
          }
          this.store.dispatch(
            new SetAADUserWithClaims(account as AADUserWithClaims),
          );
        }
        return !!profile;
      }),
      filter((profile) => !!profile),
      first(),
    );
  }
}
