import { Injectable } from '@angular/core';
import { StampWithImage } from '@et/typings';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root',
})
export class StampsRepositoryService {
  protected _tableName = 'stamps';

  constructor(private storage: StorageService) {}

  /**
   * This function sets initial dummy value in the table
   */
  async addDummy() {
    try {
      await this.setTable();
      await this.storage.setItem('0', '1');
    } catch (error) {
      console.error('StampsRepositoryService: ', error);
      return;
    }
  }

  /**
   * This function return all values from storage
   */
  async getAll(profileId: string): Promise<StampWithImage[]> {
    try {
      await this.setTable();
      const stampJSONArrWithDummy = await this.storage.getAllValues();
      // Remove dummy
      const stampJSONArr = stampJSONArrWithDummy.filter((m) => m !== '1');
      const stamps: StampWithImage[] = [];
      for (const stampJSON of stampJSONArr) {
        const stamp = JSON.parse(stampJSON);
        stamps.push(stamp);
      }
      return stamps.filter((stamp) => stamp.profile?.profileId === profileId);
    } catch (error) {
      console.error('StampsRepositoryService: ', error);
      return [];
    }
  }
  /**
   * This function saves all values from storage
   */
  async saveAll(stamps: StampWithImage[]): Promise<void> {
    try {
      for (const stamp of stamps) {
        await this.createOrUpdate(stamp.stampId as string, stamp);
      }
    } catch (error) {
      console.error('StampsRepositoryService: ', error);
    }
  }

  /**
   * This function gets StampWithImage by id
   * @param {string} id - packet id or eNote id
   */
  async get(id: string): Promise<StampWithImage | null> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return null;
      }
      const stampJSON = await this.storage.getItem(id);
      const stamps = JSON.parse(stampJSON);
      return stamps;
    } catch (error) {
      console.error('StampsRepositoryService: ', error);
      return null;
    }
  }

  /**
   * This function adds StampWithImage to storage
   * @param {string} id - Packet id or eNote id
   * @param {StampWithImage} stamp - PacketListItem
   */
  async createOrUpdate(id: string, stamp: StampWithImage): Promise<void> {
    try {
      await this.setTable();
      const stampJSON = JSON.stringify(stamp);
      await this.storage.setItem(id, stampJSON);
    } catch (error) {
      console.error('StampsRepositoryService: ', error);
    }
  }

  /**
   * This function deletes StampWithImage in storage
   * @param {string} id - Packet id or eNote id
   */
  async delete(id: string): Promise<void> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return;
      }
      await this.storage.removeItem(id);
    } catch (error) {
      console.error('StampsRepositoryService: ', error);
    }
  }

  /**
   * This function deletes all keys
   */
  async clear(): Promise<void> {
    try {
      await this.setTable();
      await this.storage.clear();
      await this.addDummy();
    } catch (error) {
      console.error('StampsRepositoryService: ', error);
    }
  }

  /**
   * This function sets storage table
   */
  async setTable() {
    await this.storage.setTable(this._tableName);
  }
}
