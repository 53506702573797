import { ClientData, ClientMetadata, PacketsClosingType } from '@et/typings';

export interface DraftBodyAPI {
  user?: DraftUser;
  company?: DraftCompany;
  files: DraftFile[];
  metadata?: DraftMetadata;
  totalFileSize?: number;
  flaggedPages?: DraftFlaggedPages[];
}

export interface DraftUser {
  id: string;
}

export interface DraftCompany {
  id: string;
}

export interface DraftFile {
  id: string;
  totalPages: number;
  integrity?: boolean;
  fileName: string;
  pageRange?: DraftFilePageRange;
}

interface DraftFilePageRange {
  start: number;
  end: number;
}

export interface DraftMetadata {
  flaggedPages?: number[];
  titleId?: string;
  state?: string;
  signorCount?: number;
  hasENote?: string;
  includeESignConsent?: boolean;
  sendToAutoDocumentPreparation?: boolean;
  isTestDocument?: boolean;
  closingType?: PacketsClosingType;
  generatedFrom?: string;
  isBuyer?: string;
  buyers?: number;
  sellers?: number;
  clientData?: ClientData;
  clientMetadata?: ClientMetadata;
}

export interface DraftFlaggedPages {
  fileId: string;
  isFlaggedAll: boolean;
  pageNumbers: number[];
}
