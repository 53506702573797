export enum ETPermissions {
  NotarySigningAgent = 'Notary-Signing-Agent',
  NotaryNotary = 'Notary-Notary',
  LenderLoanOfficer = 'Lender-Loan-Officer',
  LenderClosingDepartment = 'Lender-Closing-Department',
  TitleSigningAgent = 'Title-Signing-Agent',
  Title = 'Title',
  Signor = 'Signor',
  ClosingCompany = 'Closing-Company',
}
export const ETPermissionsOptions = [
  ETPermissions.NotarySigningAgent,
  ETPermissions.NotaryNotary,
  ETPermissions.LenderLoanOfficer,
  ETPermissions.LenderClosingDepartment,
  ETPermissions.TitleSigningAgent,
  ETPermissions.Title,
  ETPermissions.Signor,
  ETPermissions.ClosingCompany,
];
