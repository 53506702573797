import { Injectable } from '@angular/core';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root',
})
export class AnnotationRepositoryService {
  protected _tableName = 'annotations';

  constructor(private storage: StorageService) {}

  /**
   * This function sets initial dummy value in the table
   */
  async addDummy() {
    try {
      await this.setTable();
      await this.storage.setItem('0', '1');
    } catch (error) {
      console.error('AnnotationRepositoryService: ', error);
      return;
    }
  }

  /**
   * This function return all values from store
   */
  async getAll(): Promise<Map<string, string>[]> {
    try {
      await this.setTable();
      const annotationObjJSONArrWithDummy = await this.storage.getAllValues();
      // Remove dummy
      const annotationObjJSONArr = annotationObjJSONArrWithDummy.filter(
        (a) => a !== '1',
      );
      const annotations: Map<string, string>[] = [];
      for (const annotationObjJSON of annotationObjJSONArr) {
        const annotationObj = JSON.parse(annotationObjJSON);
        const annotation = new Map<string, string>(
          Object.entries(annotationObj),
        );
        annotations.push(annotation);
      }
      return annotations;
    } catch (error) {
      console.error('AnnotationRepositoryService: ', error);
      return [];
    }
  }

  /**
   * This function gets annotation by id
   * @param {string} id - Packet id
   */
  async get(id: string): Promise<Map<string, string> | null> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return null;
      }
      const annotationObjJSON = await this.storage.getItem(id);
      const annotationObj = JSON.parse(annotationObjJSON);
      const annotation = new Map<string, string>(Object.entries(annotationObj));
      return annotation;
    } catch (error) {
      console.error('AnnotationRepositoryService: ', error);
      return null;
    }
  }

  /**
   * This function adds new annotation to storage
   * @param {string} id - Packet id
   * @param {Map<string, string>} annotation - Annotation cache
   */
  async createOrUpdate(
    id: string,
    annotation: Map<string, string>,
  ): Promise<void> {
    try {
      await this.setTable();
      const annotationObj = Object.fromEntries(annotation);
      const annotationObjJSON = JSON.stringify(annotationObj);
      await this.storage.setItem(id, annotationObjJSON);
    } catch (error) {
      console.error('AnnotationRepositoryService: ', error);
    }
  }

  /**
   * This function deletes annotation in storage
   * @param {string} id - Packet id
   */
  async delete(id: string): Promise<void> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return;
      }
      await this.storage.removeItem(id);
    } catch (error) {
      console.error('AnnotationRepositoryService: ', error);
    }
  }

  /**
   * This function deletes all keys
   */
  async clear(): Promise<void> {
    try {
      await this.setTable();
      await this.storage.clear();
      await this.addDummy();
    } catch (error) {
      console.error('AnnotationRepositoryService: ', error);
    }
  }

  /**
   * This function sets storage table
   */
  async setTable() {
    this.storage.setTable(this._tableName);
  }
}
