<div class="flex justify-between mb-6">
  <div class="flex items-start gap-4">
    <img src="assets/icons/pages.svg" width="24" height="24" alt="Pages" />
    <div>
      <p class="text-base font-bold">Page select</p>
      <p class="text-sm font-medium">{{ bodyText }}</p>
    </div>
  </div>
  <button class="self-start" (click)="onClose()">
    <img src="assets/icons/close.svg" width="12" height="12" alt="Close" />
  </button>
</div>

<!-- Page preview container -->
@if(file){
<div
  class="-mb-4"
  [ngClass]="{ '!mb-1': hideImages }"
  [ngStyle]="{
    minHeight: pagePreviewHeight + 'px',
    height: pagePreviewHeight + 'px'
  }"
>
  <img
    *ngIf="pagePreview"
    @pagePreviewIn
    [src]="pagePreview"
    [alt]="'Page ' + page"
    [style.marginLeft]="pagePreviewOffset"
  />
</div>
}

<!-- Page stamps indicator -->
@if (!hideImages) {
<div class="relative mb-[70px]" [ngClass]="{ '!mb-11': file }">
  <div
    *ngFor="let p of flaggedPages"
    class="absolute flex flex-col items-center justify-center w-6 m-0"
    [style.left]="calculateOffset(p.page, thumbWidth)"
  >
    <div
      class="flex items-center justify-center w-8 h-8 mb-2"
      [ngClass]="{ 'opacity-0': p.page !== page }"
    >
      <img [src]="getImage(p)" alt="Stamp" class="w-8 h-8" />
    </div>
    <span
      class="w-[8px] h-[8px] m-0 rounded-full transition-colors"
      [ngClass]="{
        '!bg-secondary-focus': p.page === page,
        'bg-[#B4F0CC]': p.notarize,
        'bg-neutral-secondary': !p.notarize
      }"
    ></span>
  </div>
</div>
}

<!-- Slider -->
<input
  #slider
  [formControl]="rangeCtrl"
  type="range"
  min="1"
  [max]="totalPages"
  (change)="pageChanged()"
  class="z-20 w-full h-2 my-6 rounded-full outline-none appearance-none slider bg-neutral-secondary"
/>

<!-- Page number -->
<div
  class="relative flex justify-center w-6 origin-center"
  [style.left]="pageNumLeft"
>
  <span class="mx-auto rounded-full">{{ rangeCtrl.value }}</span>
</div>
