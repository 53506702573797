import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@et/environment';
import {
  AADUserWithClaims,
  SigningInfo,
  SigningSession,
  StripeCreateSessionItem,
  StripeSessionMetadata,
  StripeVerificationReport,
  StripeVerificationSession,
} from '@et/typings';
import { lastValueFrom, take } from 'rxjs';
import { Stripe, loadStripe, StripeError } from '@stripe/stripe-js';
import { ProfileState } from '@et/guards';
import { EtJournalStateSelectors } from '@et/et-journal/state';

@Injectable({ providedIn: 'root' })
export class StripeService {
  private readonly callUrl = environment.docio.domain;

  private stripe!: Stripe | null;

  constructor(private http: HttpClient, private store: Store) {}

  /**
   * Init Stripe
   *
   * @memberof StripeService
   */
  private async init() {
    this.stripe = await loadStripe(environment.stripe.publisherKey);
  }

  /**
   * Verify identity
   *
   * @memberof StripeService
   */
  async verify(): Promise<{
    error: StripeError | undefined;
    sessionId: string;
  } | null> {
    // Init stripe on first call
    if (!this.stripe) {
      await this.init();
    }

    // Make sure stripe object exists
    if (!this.stripe) {
      return null;
    }

    // Get data from store
    const profileId = this.store.selectSnapshot(
      ProfileState.v2ProfileId,
    ) as string;
    const profile = this.store.selectSnapshot(
      ProfileState.getAadProfileWithClaims,
    ) as AADUserWithClaims;
    const profileEmail = this.store.selectSnapshot(
      ProfileState.getProfileEmail,
    ) as string;
    const signingInfo = this.store.selectSnapshot(
      EtJournalStateSelectors.getSigningInfo,
    ) as SigningInfo;
    const selectedSigningSession = this.store.selectSnapshot(
      EtJournalStateSelectors.getSelectedSigningSession,
    ) as SigningSession;

    const signerNames =
      signingInfo.signerDetails?.map((s) => s.name).join(', ') || '';

    // Add metadata to Stripe session entity
    const sessionMetadata: StripeSessionMetadata = {
      eJournalId: signingInfo.eJournalId,
      userId: profileId,
      userName: profile.name,
      userEmail: profileEmail,
      documentId: selectedSigningSession.id,
      documentName: selectedSigningSession.name,
      signers: signerNames,
      fee: signingInfo?.feeCharged as number,
    };

    // Create session
    const session = await lastValueFrom(this.createSession(sessionMetadata));

    // Get sessionId from session string
    const sessionId = this.secretToIdParser(session.client_secret);

    // Show the verification modal
    const { error } = await this.stripe.verifyIdentity(session.client_secret);

    return { error, sessionId };
  }

  /**
   * Calls the API to get verification session report
   *
   * @memberof StripeService
   */
  getVerificationSession(sessionId: string) {
    return this.http.post<StripeVerificationSession>(
      this.callUrl + '/Stripe/GetSessionReport/' + sessionId,
      '',
    );
  }

  /**
   * Calls the API to get verification detailed report
   * @param {string} reportId - Report id
   *
   * @memberof StripeService
   */
  getVerificationReport(reportId: string) {
    return this.http.post<StripeVerificationReport>(
      this.callUrl + '/Stripe/GetVerificationReport/' + reportId,
      '',
    );
  }

  /**
   * Calls the API to create a verification session
   *
   * @memberof StripeService
   */
  private createSession(sessionMetdata: StripeSessionMetadata) {
    return this.http
      .post<StripeCreateSessionItem>(
        this.callUrl + '/Stripe/create-verification-session',
        sessionMetdata,
      )
      .pipe(take(1));
  }

  /**
   * Get verification ID from secret
   *
   * @param {string} secret - Stripe client secret
   * @memberof StripeService
   */
  private secretToIdParser(secret: string) {
    const id = secret.slice(0, 27);
    return id;
  }
}
