import { ENoteFormField, eNoteKeysEnum } from './../interfaces/enote.interface';

export const ENOTE_FORM_FIELDS: ENoteFormField[] = [
  { key: eNoteKeysEnum.loanNumber, name: 'Loan#', canParse: true },
  { key: eNoteKeysEnum.min, name: 'MIN', canParse: true },
  { key: eNoteKeysEnum.closingDate, name: 'Closing Date', canParse: true },
  { key: eNoteKeysEnum.closingCity, name: 'Closing City', canParse: true },
  { key: eNoteKeysEnum.closingState, name: 'Closing State', canParse: true },
  {
    key: eNoteKeysEnum.propertyAddressFull,
    name: 'Property Address(full)',
    canParse: true,
  },
  {
    key: eNoteKeysEnum.propertyHouse,
    name: 'Property house #(parts)',
    canParse: true,
  },
  {
    key: eNoteKeysEnum.propertyStreetName,
    name: 'Property street name(parts)',
    canParse: true,
  },
  {
    key: eNoteKeysEnum.propertyStreetSuffix,
    name: 'Property street suffix(parts)',
    canParse: true,
  },
  { key: eNoteKeysEnum.propertyCity, name: 'Property City', canParse: true },
  {
    key: eNoteKeysEnum.propertyState,
    name: 'Property state',
    canParse: true,
  },
  {
    key: eNoteKeysEnum.propertyZipcode,
    name: 'Property zipcode',
    canParse: true,
  },
  { key: eNoteKeysEnum.propertyCounty, name: 'Property County' },
  { key: eNoteKeysEnum.loanAmount, name: 'Loan amount', canParse: true },
  { key: eNoteKeysEnum.lenderName, name: 'Lender Name' },
  { key: eNoteKeysEnum.interestRate, name: 'Interest Rate', canParse: true },
  { key: eNoteKeysEnum.remittanceDay, name: 'Remittance day', canParse: true },
  { key: eNoteKeysEnum.firstPayment, name: 'First Payment', canParse: true },
  {
    key: eNoteKeysEnum.maturationDate,
    name: 'Maturation Date',
    canParse: true,
  },
  { key: eNoteKeysEnum.payToAddress, name: 'Pay to address', canParse: true },
  { key: eNoteKeysEnum.payToCity, name: 'Pay to city', canParse: true },
  { key: eNoteKeysEnum.payToState, name: 'Pay to state', canParse: true },
  { key: eNoteKeysEnum.payToZipcode, name: 'Pay to zipcode', canParse: true },
  { key: eNoteKeysEnum.paymentAmount, name: 'Payment amount', canParse: true },
  { key: eNoteKeysEnum.latePeriod, name: 'Late period', canParse: true },
  { key: eNoteKeysEnum.lateCharge, name: 'Late charge', canParse: true },
  { key: eNoteKeysEnum.bor1First, name: 'Bor 1 first', canParse: true },
  { key: eNoteKeysEnum.bor1Middle, name: 'Bor 1 Middle', canParse: true },
  { key: eNoteKeysEnum.bor1Last, name: 'Bor 1 Last', canParse: true },
  { key: eNoteKeysEnum.bor1SSN, name: 'Bor 1 SSN', canParse: true },
  { key: eNoteKeysEnum.bor1Type, name: 'Bor 1 Type' },
  { key: eNoteKeysEnum.bor2First, name: 'Bor 2 First', canParse: true },
  { key: eNoteKeysEnum.bor2Middle, name: 'Bor 2 Middle', canParse: true },
  { key: eNoteKeysEnum.bor2Last, name: 'Bor 2 Last', canParse: true },
  { key: eNoteKeysEnum.bor2SSN, name: 'Bor 2 SSN', canParse: true },
  { key: eNoteKeysEnum.bor2type, name: 'Bor 2 type' },
  { key: eNoteKeysEnum.bor3First, name: 'Bor 3 First', canParse: true },
  { key: eNoteKeysEnum.bor3Middle, name: 'Bor 3 Middle', canParse: true },
  { key: eNoteKeysEnum.bor3Last, name: 'Bor 3 Last', canParse: true },
  { key: eNoteKeysEnum.bor3SSN, name: 'Bor 3 SSN', canParse: true },
  { key: eNoteKeysEnum.bor3type, name: 'Bor 3 Type' },
  { key: eNoteKeysEnum.bor4First, name: 'Bor 4 First', canParse: true },
  { key: eNoteKeysEnum.bor4Middle, name: 'Bor 4 Middle', canParse: true },
  { key: eNoteKeysEnum.bor4Last, name: 'Bor 4 Last', canParse: true },
  { key: eNoteKeysEnum.bor4SSN, name: 'Bor 4 SSN', canParse: true },
  { key: eNoteKeysEnum.bor4type, name: 'Bor 4 type' },
  { key: eNoteKeysEnum.lender, name: 'Lender' },
  { key: eNoteKeysEnum.lenderNMLS, name: 'Lender NMLS', canParse: true },
  { key: eNoteKeysEnum.originator, name: 'Originator' },
  {
    key: eNoteKeysEnum.originatorNMLS,
    name: 'Originator NMLS',
    canParse: true,
  },
  { key: eNoteKeysEnum.propertyType, name: 'Property Type' },
  { key: eNoteKeysEnum.lienPosition, name: 'Lien Position' },
  {
    key: eNoteKeysEnum.customRegistryOperatorName,
    name: 'Custom registry operator name',
    disabled: true,
  },
  {
    key: eNoteKeysEnum.loanClosingLenderContactPointType,
    name: 'Loan closing lender contact point type',
    disabled: true,
  },
  {
    key: eNoteKeysEnum.loanClosingLenderContactPointTypeOtherDesc,
    name: 'Loan closing lender contact point description',
    disabled: true,
  },
];

export const PARSED_FIELDS: string[] = [
  eNoteKeysEnum.loanNumber,
  eNoteKeysEnum.min,
  eNoteKeysEnum.bor1SSN,
  eNoteKeysEnum.bor2SSN,
  eNoteKeysEnum.bor3SSN,
  eNoteKeysEnum.bor4SSN,
  eNoteKeysEnum.remittanceDay,
  eNoteKeysEnum.latePeriod,
  eNoteKeysEnum.lenderNMLS,
  eNoteKeysEnum.originatorNMLS,
  eNoteKeysEnum.closingDate,
  eNoteKeysEnum.firstPayment,
  eNoteKeysEnum.maturationDate,
  eNoteKeysEnum.closingCity,
  eNoteKeysEnum.closingState,
  eNoteKeysEnum.propertyAddressFull,
  eNoteKeysEnum.payToAddress,
  eNoteKeysEnum.propertyHouse,
  eNoteKeysEnum.propertyStreetName,
  eNoteKeysEnum.propertyStreetSuffix,
  eNoteKeysEnum.propertyCity,
  eNoteKeysEnum.propertyState,
  eNoteKeysEnum.propertyZipcode,
  eNoteKeysEnum.payToCity,
  eNoteKeysEnum.payToState,
  eNoteKeysEnum.payToZipcode,
  eNoteKeysEnum.bor1First,
  eNoteKeysEnum.bor2First,
  eNoteKeysEnum.bor3First,
  eNoteKeysEnum.bor4First,
  eNoteKeysEnum.bor1Middle,
  eNoteKeysEnum.bor2Middle,
  eNoteKeysEnum.bor3Middle,
  eNoteKeysEnum.bor4Middle,
  eNoteKeysEnum.bor1Last,
  eNoteKeysEnum.bor2Last,
  eNoteKeysEnum.bor3Last,
  eNoteKeysEnum.bor4Last,
  eNoteKeysEnum.loanAmount,
  eNoteKeysEnum.paymentAmount,
  eNoteKeysEnum.interestRate,
  eNoteKeysEnum.lateCharge,
];
