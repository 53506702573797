import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@et/environment';
import { EJournalBody, ETJournalAPIResponse } from '@et/typings';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProfileV2ApiService {
  constructor(private http: HttpClient) {}

  /**
   * This function takes a user profile id and returns ET Journals of passed user
   * @param {String} profileId - user id
   */
  getEjournalsByProfileId(profileId: string) {
    return this.http
      .get<ETJournalAPIResponse>(
        environment.profilev2.domain + '/api/ejournals/profile/' + profileId,
      )
      .pipe(map((res) => res.data));
  }

  /**
   * This function takes a journal id and returns ET Journal entity
   * @param {String} journalId - journal id
   */
  getEJournalById(journalId: string) {
    return this.http
      .get<ETJournalAPIResponse>(
        environment.profilev2.domain + '/api/ejournals/' + journalId,
      )
      .pipe(map((res) => res.data));
  }

  /**
   * This function takes a EJournalEntry and creates eJournal
   * @param {EJournalBody} eJournal - EJournalEntry
   */
  createEJournal(eJournal: EJournalBody) {
    return this.http.post<ETJournalAPIResponse>(
      environment.profilev2.domain + '/api/ejournals',
      eJournal,
    );
  }
}
