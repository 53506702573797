import { Injectable } from '@angular/core';
import { uid } from '@et/utils';
import { BehaviorSubject } from 'rxjs';

export enum AlertType {
  Error = 'error',
  Success = 'success',
}

export interface Alert {
  id: string;
  message: string;
  type: AlertType;
  timer: NodeJS.Timeout;
  remove: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private _alerts = new BehaviorSubject<Alert[]>([]);

  alerts$ = this._alerts.asObservable();

  /**
   * Returns a list of shown notifications
   *
   * @memberOf NotificationService
   *
   */
  private get alertList() {
    return this._alerts.value.slice();
  }

  /**
   * Shows error notification
   *
   * @param {string} message - notification message
   * @param {number?} timeout - notification show time. Default to 5000
   *
   * @memberOf NotificationService
   *
   */
  showError(message: string, timeout: number = 5000) {
    const alert = { message, type: AlertType.Error, id: uid() } as Alert;
    this.addNotificationToList(alert, timeout);
  }

  /**
   * Shows success notification
   *
   * @param {string} message - notification message
   * @param {number?} timeout - notification show time. Default to 5000
   *
   * @memberOf NotificationService
   *
   */
  showSuccess(message: string, timeout: number = 5000) {
    const alert = {
      message,
      type: AlertType.Success,
      id: uid(),
    } as Alert;
    this.addNotificationToList(alert, timeout);
  }

  /**
   * This function adds a notification to the alert list and sets a timeout to remove it.
   *
   * @param {Alert} alert - The alert to add to the list.
   * @param {number} timeout - The time in milliseconds after which the alert should be removed.
   *
   * The function first defines a remove function that removes the alert from the list
   * and clears its timeout.
   *
   * It then sets a timeout to call the clear function after the specified time.
   * The function also adds a remove method to the alert that calls the remove function.
   * Finally, the function adds the alert to the list and emits the updated list.
   *
   * @returns {void}
   */
  private addNotificationToList(alert: Alert, timeout: number): void {
    const removeFn = () => {
      const alerts = this.alertList.filter((a) => a.id !== alert.id);
      this._alerts.next(alerts);
      clearTimeout(alert.timer);
    };

    alert.timer = setTimeout(removeFn, timeout);
    alert.remove = removeFn;

    this._alerts.next([...this.alertList, alert]);
  }
}
