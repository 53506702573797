import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ConnectedPosition } from '@angular/cdk/overlay';
import { NativePopupComponent } from '../native-popup/native-popup.component';
import { DocumentMarkupPage } from '@et/typings';

@Component({
  selector: 'et-atoms-native-pages-clicker',
  templateUrl: './native-pages-clicker.component.html',
  styleUrls: ['./native-pages-clicker.component.scss'],
})
export class NativePagesClickerComponent {
  @Input() totalPages!: number | undefined;
  @Input() disabled = false;
  @Input() disableNav = false;
  @Input() currentPage = 1;
  @Input() flaggedPages: DocumentMarkupPage[] | null = [];
  @Input() set pageWidth(width: number | undefined) {
    if (width) {
      this.navigationWidth = width - 96; // Remove paddings and margins of the page
    }
  }
  @Input() popupPosition: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top',
    },
  ];

  @Output() changePage = new EventEmitter<number>();
  @Output() changeNavigation = new EventEmitter<boolean>();

  @ViewChild(NativePopupComponent)
  navigation!: NativePopupComponent;
  navigationWidth!: number;
  isNavOpen = false;

  /**
   * This function increases current page by one and emits its value
   * @memberof NativePagesClickerComponent
   */
  onNextPage() {
    if (
      !this.disabled &&
      this.totalPages &&
      this.currentPage < this.totalPages
    ) {
      const page = this.currentPage + 1;
      this.changePage.emit(page);
    }
  }

  /**
   * This function reduces current page by one and emits its value
   * @memberof NativePagesClickerComponent
   */
  onPreviousPage() {
    if (!this.disabled && this.totalPages && this.currentPage > 1) {
      const page = this.currentPage - 1;
      this.changePage.emit(page);
    }
  }

  /**
   * This function emits the value of selected page
   * @memberof NativePagesClickerComponent
   */
  onChangePage(page: number) {
    this.changePage.emit(page);
  }

  /**
   * This function opens navigation menu and emits true via changeNavigation event
   * @memberof NativePagesClickerComponent
   */
  openNav() {
    if (!this.disableNav) {
      this.navigation.isOpen = true;
      this.isNavOpen = true;
      this.changeNavigation.emit(true);
    }
  }

  /**
   * This function closes navigation menu and emits false via changeNavigation event
   * @memberof NativePagesClickerComponent
   */
  closeNav() {
    this.navigation.isOpen = false;
    this.isNavOpen = false;
    this.changeNavigation.emit(false);
  }
}
