import {
  DocumentGetAPIResponse,
  DocumentFileGetAPIResponse,
} from './../models/document-response-api-model';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { StrictHttpResponse } from '../configs/strict-http-response';
import { RequestBuilder } from '../../../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ApiConfiguration } from '../configs/api-configuration';
import { BaseService } from './base-service';
import {
  ParamsDocBrowse,
  DocumentResponseApiModel,
  DocumentGetExternalAPIResponse,
} from '../models';

/**
 * Docs operations to regular authenticated API consumers
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentApiService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation docBrowse
   */
  static readonly DocBrowsePath =
    '/doc/find/items/{q}/{sort-direction}/{sort-field}/{page}/{limit}';
  /**
   * Path part for operation docSet
   */
  static readonly DocSetPath = '/doc/set';
  static readonly DocSignedPath = '/doc/state/signed/{id}';
  /**
   * Path part for operation docGet
   */
  static readonly DocGetPath = '/doc/{uuid}';
  static readonly DocGetExternalPath = '/doc/external/{uuid}';

  /**
   * Document search.
   *
   * Search via browse filter, sort order + sort field
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `docBrowse()` instead.
   *
   * This method doesn't expect any request body.
   */
  docBrowse$Response(
    params?: ParamsDocBrowse,
  ): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentApiService.DocBrowsePath,
      'get',
    );
    if (params) {
      rb.path('q', params['q'], { style: 'matrix' });
      rb.path('page', params.page, { style: 'matrix' });
      rb.path('limit', params.limit, { style: 'matrix' });
      rb.path('sort-field', params['sort-field'], { style: 'matrix' });
      rb.path('sort-direction', params['sort-direction'], { style: 'matrix' });
      rb.path('state', params.state, { style: 'matrix' });
      rb.path('type', params.type, { style: 'matrix' });
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<string>;
        }),
      );
  }

  /**
   * Document search.
   *
   * Search via browse filter, sort order + sort field
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `docBrowse$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  docBrowse(params?: ParamsDocBrowse): Observable<DocumentGetAPIResponse> {
    return this.docBrowse$Response(params).pipe(
      map(
        (r: StrictHttpResponse<string>) =>
          r.body as unknown as DocumentGetAPIResponse,
      ),
    );
  }

  /**
   * Set item by UUID.
   *
   * Set item by UUID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `docSet()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  docSet$Response(params: DocumentResponseApiModel): Observable<any> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentApiService.DocSetPath,
      'post',
    );
    const formData = new FormData();
    params.files?.forEach((file) => {
      formData.append(file.name, file);
    });
    formData.append('data', escape(JSON.stringify(params?.data)));
    if (params) {
      rb.body(formData, 'application/x-www-form-urlencoded; charset=UTF-8');
    }
    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<unknown>) => {
          return r as StrictHttpResponse<DocumentResponseApiModel>;
        }),
      );
  }

  /**
   * Set item by UUID.
   *
   * Set item by UUID
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `docSet$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  docSet(
    params: DocumentResponseApiModel,
  ): Observable<DocumentResponseApiModel> {
    return this.docSet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<DocumentResponseApiModel>) =>
          r.body as DocumentResponseApiModel,
      ),
    );
  }
  /**
   * sign document item by UUID.
   *
   * sign document item by UUID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `docSigned()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  docSigned$Response(params: DocumentResponseApiModel): Observable<any> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentApiService.DocSignedPath,
      'post',
    );
    rb.path('id', params?.data?.id, { style: 'matrix' });
    const formData = new FormData();

    params?.files?.slice()?.forEach((file) => {
      formData.append(file.name as string, file);
    });
    formData.append('data', escape(JSON.stringify(params?.data)));
    if (params) {
      rb.body(formData, 'application/x-www-form-urlencoded; charset=UTF-8');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<unknown>) => {
          return r as StrictHttpResponse<DocumentResponseApiModel>;
        }),
      );
  }

  /**
   * Signed document item by UUID.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `docSigned$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  docSigned(
    params: DocumentResponseApiModel,
  ): Observable<DocumentResponseApiModel> {
    return this.docSigned$Response(params).pipe(
      map(
        (r: StrictHttpResponse<DocumentResponseApiModel>) =>
          r.body as DocumentResponseApiModel,
      ),
    );
  }

  /**
   * Get item by UUID.
   *
   * Get item by UUID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `docGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  docGet$Response(params: {
    /**
     * UUID
     */
    uuid: string;
  }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentApiService.DocGetPath,
      'get',
    );
    if (params) {
      rb.path('uuid', params.uuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<unknown>) => {
          return r as StrictHttpResponse<string>;
        }),
      );
  }
  /**
   * Get external mers item by UUID.
   *
   * Get external mers item by UUID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `docGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  docGetExternal$Response(params: {
    /**
     * UUID
     */
    uuid: string;
  }): Observable<StrictHttpResponse<string>> {
    const rb = new RequestBuilder(
      this.rootUrl,
      DocumentApiService.DocGetExternalPath,
      'get',
    );
    if (params) {
      rb.path('uuid', params.uuid, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<unknown>) => {
          return r as StrictHttpResponse<string>;
        }),
      );
  }

  /**
   * Get item by UUID.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `docGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  docGet(params: {
    /**
     * UUID
     */
    uuid: string;
  }): Observable<DocumentFileGetAPIResponse> {
    return this.docGet$Response(params).pipe(
      map(
        (r: StrictHttpResponse<string>) =>
          r.body as unknown as DocumentFileGetAPIResponse,
      ),
    );
  }
  /**
   * Get external mers item by UUID.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `docGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  docGetExternal(params: {
    /**
     * UUID
     */
    uuid: string;
  }): Observable<{ data: DocumentGetExternalAPIResponse }> {
    return this.docGetExternal$Response(params).pipe(
      map(
        (r: StrictHttpResponse<string>) =>
          r.body as unknown as { data: DocumentGetExternalAPIResponse },
      ),
    );
  }
}
