import { isOnline, sessionGet } from '@et/utils';
import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { ProfileState } from '../state/profile.state';

@Injectable({
  providedIn: 'root',
})
export class OfflineGuard {
  constructor(private router: Router, private store: Store) {}
  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const online = isOnline();
    const profile = this.store.selectSnapshot(
      ProfileState.getAadProfileWithClaims,
    );
    const hasSession = sessionGet('lastCheckedPin');
    if (profile && online === false && !hasSession) {
      this.router.navigateByUrl('/pincode');
    }
    return true;
  }
}
