import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';
import { I18nModule } from '@et/i18n';
import { SelectComponent } from './select/select.component';
import { ModalWithTemplatesComponent } from './modal-with-templates/modal-with-templates.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { InputComponent } from './input/input.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { SelectOptionComponent } from './select-option/select-option.component';
import { SelectFieldComponent } from './select-field/select-field.component';
import { DocumentMenuComponent } from './document-menu/document-menu.component';
import { DocumentRenameComponent } from './document-rename/document-rename.component';
import { EtInputDirective } from './input/et-input.directive';
import { EtSelectIconDirective } from './select-input/select-icon.directive';
import { DocumentPreviewComponent } from './document-preview/document-preview.component';
import { QuickLinkComponent } from './quick-link/quick-link.component';
import { PageTabsComponent } from './page-tabs/page-tabs.component';
import { PageTabComponent } from './page-tab/page-tab.component';
import { RouterModule } from '@angular/router';
import { DocumentDisplayComponent } from './document-display/document-display.component';
import { NativeNavIconComponent } from './native-nav-icon/native-nav-icon.component';
import { NativePagesClickerComponent } from './native-pages-clicker/native-pages-clicker.component';
import { NativeSigningProgressComponent } from './native-signing-progress/native-signing-progress.component';
import { NativePopupComponent } from './native-popup/native-popup.component';
import { NativeRequiredFieldsComponent } from './native-required-fields/native-required-fields.component';
import { NativeAdvancedNavigationComponent } from './native-advanced-navigation/native-advanced-navigation.component';
import { EtJournalCardComponent } from './et-journal-card/et-journal-card.component';
import { EtJournalBackBtnComponent } from './et-journal-back-btn/et-journal-back-btn.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TileTabsComponent } from './tile-tabs/tile-tabs.component';
import { TileTabComponent } from './tile-tab/tile-tab.component';
import { HoverQuestionComponent } from './hover-question/hover-question.component';
import { WebBackBtnComponent } from './web-back-btn/web-back-btn.component';
import { RangeSliderComponent } from './range-slider/range-slider.component';
import { DocumentPageComponent } from './document-page/document-page.component';
import { DocumentSidebarComponent } from './document-sidebar/document-sidebar.component';
import { ZoomButtonsComponent } from './zoom-buttons/zoom-buttons.component';
import { TileMenuComponent } from './tile-menu/tile-menu.component';
import { TileMenuItemComponent } from './tile-menu-item/tile-menu-item.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    OverlayModule,
    MatDialogModule,
    I18nModule,
  ],
  declarations: [
    DocumentDisplayComponent,
    DocumentMenuComponent,
    DocumentPageComponent,
    DocumentPreviewComponent,
    DocumentRenameComponent,
    DocumentSidebarComponent,
    EtInputDirective,
    EtJournalBackBtnComponent,
    EtJournalCardComponent,
    EtSelectIconDirective,
    HoverQuestionComponent,
    InputComponent,
    ModalWithTemplatesComponent,
    NativeAdvancedNavigationComponent,
    NativeNavIconComponent,
    NativePagesClickerComponent,
    NativePopupComponent,
    NativeRequiredFieldsComponent,
    NativeSigningProgressComponent,
    PageTabComponent,
    PageTabsComponent,
    QuickLinkComponent,
    RangeSliderComponent,
    SelectComponent,
    SelectFieldComponent,
    SelectInputComponent,
    SelectOptionComponent,
    SpinnerComponent,
    TileMenuComponent,
    TileMenuItemComponent,
    TileTabComponent,
    TileTabsComponent,
    WebBackBtnComponent,
    ZoomButtonsComponent,
  ],
  exports: [
    DocumentDisplayComponent,
    DocumentMenuComponent,
    DocumentPageComponent,
    DocumentSidebarComponent,
    EtInputDirective,
    EtJournalBackBtnComponent,
    EtJournalCardComponent,
    EtSelectIconDirective,
    HoverQuestionComponent,
    InputComponent,
    MatDialogModule,
    ModalWithTemplatesComponent,
    NativeAdvancedNavigationComponent,
    NativeNavIconComponent,
    NativePagesClickerComponent,
    NativePopupComponent,
    NativeRequiredFieldsComponent,
    NativeSigningProgressComponent,
    OverlayModule,
    PageTabComponent,
    PageTabsComponent,
    QuickLinkComponent,
    RangeSliderComponent,
    SelectComponent,
    SelectFieldComponent,
    SelectInputComponent,
    SelectOptionComponent,
    SpinnerComponent,
    TileMenuComponent,
    TileMenuItemComponent,
    TileTabComponent,
    TileTabsComponent,
    WebBackBtnComponent,
    ZoomButtonsComponent,
  ],
})
export class PresentationalAtomsModule {}
