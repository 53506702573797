/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { packetStatusEnum, SigningSession } from '@et/typings';
import { Packet, PacketInfoService } from '@escrowtab/packets-api-client';
import { Store } from '@ngxs/store';
import { ProfileState } from '@et/guards';

@Injectable({
  providedIn: 'root',
})
export class VerificationService {
  constructor(
    private packetInfoService: PacketInfoService,
    private store: Store,
  ) {}

  /**
   * Fetches unsigned docs of user and return onservable of SigningSession[]
   * @returns Observable<SigningSession[]>
   * @memberof VerificationService
   */
  getUnsignedDocuments(): Observable<SigningSession[]> {
    const userId = this.store.selectSnapshot(ProfileState.v2ProfileId);
    return this.packetInfoService
      .getPacketsByUser({ userId })
      .pipe(map((res) => this.formatDocResponse(res)));
  }

  /**
   * Takes response data DocumentGetAPIResponse and returns a SigningSession[]
   * @param {DocumentGetAPIResponse} data - DocumentGetAPIResponse
   * @returns SigningSession[]
   * @memberof VerificationService
   */
  private formatDocResponse(packets: Packet[]): SigningSession[] {
    const formattedDocs = packets
      .filter((d) => d.packetStatus === packetStatusEnum.Ready)
      .sort((a, b) => {
        if (!a.closingDate || !b.closingDate) {
          return -1;
        }
        return (
          new Date(b.closingDate).getTime() - new Date(a.closingDate).getTime()
        );
      })
      .map((d) => ({
        id: d.packetId as string,
        name: d.packetName as string,
        fileName: 'unsigned.pdf',
        signerNames: [{ name: '' }],
      }));

    const paperSigningOpt = {
      id: '00000000-0000-0000-0000-000000000000',
      name: 'Paper signing',
      fileName: 'Paper signing',
      signerNames: [{ name: '' }],
    };

    return [...formattedDocs, paperSigningOpt];
  }
}
