export enum ordersTypeEnum {
  'all' = 'all',
  'active' = 'active',
  'archived' = 'archived',
}

export enum ordersSortOrderEnum {
  'ascending' = 'asc',
  'descending' = 'desc',
}

export enum ordersSortTypeEnum {
  'name' = 'name',
  'date' = 'date',
}

export enum ordersStatusEnum {
  'all' = 'all',
  'draft' = 'draft',
  'ready' = 'ready',
  'signed' = 'signed',
  'archived' = 'archived',
  'canceled' = 'canceled',
}

export interface OrderStatuses {
  isLoaded: boolean;
  statusesList: OrderStatusOption[];
  error?: Error;
}

export interface OrderStatusOption {
  orderStatusId: number;
  orderStatusName: string;
  isActive: boolean;
}

export interface OrdersAPIResponse {
  data: OrderListItemResponse[];
  errors: any;
  firstPage: string;
  lastPage: string;
  message: string;
  nextPage: string | null;
  pageNumber: number;
  pageSize: number;
  previousPage: string;
  recordsThisPage: number;
  succeeded: boolean;
  totalPages: number;
  totalRecords: number;
}

export interface OrderListItem extends OrderListItemResponse {
  isDraft: boolean;
  name: string;
  titleAgent: string;
  signed: boolean;
  eNote: boolean;
  closingType: string;
  actionRequired: boolean;
  completed: boolean;
  docUrl: string | null;
}

export interface OrderListItemResponse {
  agencyId: string;
  agencyName: string;
  agentEmail: string;
  closingDate: string;
  draftId: string;
  hasFinalClosingDisclosure: boolean;
  isEstimatedDate: boolean;
  lenderCompany: string;
  lenderId: string;
  lenderName: string;
  orderId: string;
  orderName: string;
  orderStatus: number;
  orderStatusName: string;
  signingType: number | null;
  signingTypeName: string | null;
  utcDateCreated: string;
  utcDateModified: string;
}

export interface OrderAPIResponse {
  data: OrderListItemResponse;
  succeeded: boolean;
  errors?: any[];
  message: string;
}

export interface OrdersFilter {
  order: ordersSortOrderEnum;
  sort: ordersSortTypeEnum;
  type: ordersTypeEnum;
  status: ordersStatusEnum;
  itemsOnPage: number;
  currentPage: number;
  hasNextPage?: boolean;
}

export interface ParamsOrdersAPI {
  status: number;
  sortType: ordersSortTypeEnum;
  sortOrder: ordersSortOrderEnum;
  type: ordersTypeEnum;
  pageNumber: number;
  pageSize: number;
  search: string;
}

export interface OrderBodyAPI {
  orderId?: string;
  lenderId: string;
  agentEmail: string;
  agencyId: string;
  closingDate: Date;
  isEstimatedDate: boolean;
  signingType?: number | null;
  draftId?: string;
  orderStatus?: number;
  hasFinalClosingDisclosure?: boolean;
}

export interface OrderAgencyAPIResponse {
  id: string;
  emailDomain: string;
  email: string;
  name: string;
  phone: string;
  type: string;
  website: string;
  isActive: boolean;
}

export interface NewOrderForm {
  titleAgency: string;
  titleAgentEmail: string;
  agentCompanyId: string;
  agentUserId: string;
  closingDate: string;
  estimatedClosingTime: boolean;
}
