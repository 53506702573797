import { I18nModule } from '@et/i18n';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { AuthRoutingModule } from './auth-routing.module';
import { PresentationalAtomsModule } from '@et/presentational/atoms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import { LoginFailedComponent } from './components/login-failed/login-failed.component';
import { AuthService } from './services/auth.service';

@NgModule({
  declarations: [LoginComponent, LoginFailedComponent],

  imports: [
    CommonModule,
    PresentationalAtomsModule,
    FormsModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    I18nModule,
    HttpClientModule,
    MsalModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    AuthService,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
})
export class AuthModule {}
