import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  inject,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { DocPrepDocument } from '@et/typings';
import { TemplatesSearchListItemComponent } from '../templates-search-list-item/templates-search-list-item.component';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';

@Component({
  selector: 'et-atoms-templates-search-list',
  standalone: true,
  imports: [TemplatesSearchListItemComponent],
  templateUrl: './templates-search-list.component.html',
  styleUrl: './templates-search-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TemplatesSearchListComponent implements AfterViewInit, OnChanges {
  private cdr = inject(ChangeDetectorRef);

  @Input({ required: true }) templates!: DocPrepDocument[];
  @Output() templateSelected = new EventEmitter<DocPrepDocument>();

  @ViewChildren(TemplatesSearchListItemComponent)
  selectOptions!: QueryList<TemplatesSearchListItemComponent>;

  private keyManager!: ActiveDescendantKeyManager<TemplatesSearchListItemComponent>;

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.handleKeyDown(event);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.keyManager && changes['templates']) {
      Promise.resolve().then(() => {
        this.keyManager?.setActiveItem(0);
        this.cdr.markForCheck();
      });
    }
  }

  ngAfterViewInit(): void {
    this.initKeyManager();
  }

  /**
   * Emits the selected template.
   *
   * @param {DocPrepDocument} template - The selected template.
   */
  onSelect(template: DocPrepDocument) {
    this.templateSelected.emit(template);
  }

  /**
   * Handles keyboard navigation within the list.
   *
   * This method listens for keyboard events and navigates through the list items using the arrow keys.
   * When the Enter key is pressed, it selects the currently active item.
   *
   * @param {KeyboardEvent} event - The keyboard event.
   */
  private handleKeyDown(event: KeyboardEvent) {
    const key = event.key;
    if (key === 'ArrowDown' || key === 'ArrowUp') {
      this.keyManager.onKeydown(event);
    } else if (key === 'Enter') {
      const template = this.keyManager.activeItem?.template;
      if (template) {
        this.onSelect(template);
        event.preventDefault();
      }
    }
  }

  /**
   * Initializes the key manager for keyboard navigation.
   *
   * This method sets up the `ActiveDescendantKeyManager` to manage keyboard navigation within the list.
   * It also sets the first item as the active item and triggers change detection.
   */
  private initKeyManager() {
    this.keyManager =
      new ActiveDescendantKeyManager<TemplatesSearchListItemComponent>(
        this.selectOptions,
      )
        .withTypeAhead()
        .withVerticalOrientation()
        .withHorizontalOrientation('ltr')
        .withHomeAndEnd()
        .withWrap();

    this.keyManager.setActiveItem(0);
    this.cdr.markForCheck();
  }
}
