import { Injectable } from '@angular/core';
import { SignedEnote } from '@et/typings';
import { convertFileToDataURLAsync, getFileFromUrl } from '@et/utils';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root',
})
export class EnoteFilesRepositoryService {
  protected _tableName = 'enote_files';

  constructor(private storage: StorageService) {}

  /**
   * This function sets initial dummy value in the table
   */
  async addDummy() {
    try {
      await this.setTable();
      await this.storage.setItem('0', '1');
    } catch (error) {
      console.error('EnoteFilesRepositoryService: ', error);
      return;
    }
  }

  /**
   * This function return all values from storage
   */
  async getAll(): Promise<SignedEnote[]> {
    try {
      await this.setTable();
      const eNoteFilesJSONArrWithDummy = await this.storage.getAllValues();
      // Remove dummy
      const eNoteFilesJSONArr = eNoteFilesJSONArrWithDummy.filter(
        (m) => m !== '1',
      );
      const eNoteFiles: SignedEnote[] = [];
      for (const eNoteFilesJSON of eNoteFilesJSONArr) {
        const eNoteFiles = JSON.parse(eNoteFilesJSON);
        const pdfFile = await getFileFromUrl(eNoteFiles.pdfFile, 'signed.pdf');
        const metadataFile = await getFileFromUrl(
          eNoteFiles.metadataFile,
          'meta.json',
          'application/json',
        );
        eNoteFiles.push({ pdfFile, metadataFile });
      }
      return eNoteFiles;
    } catch (error) {
      console.error('EnoteFilesRepositoryService: ', error);
      return [];
    }
  }

  /**
   * This function gets SignedEnote by id
   * @param {string} id - eNote id
   */
  async get(id: string): Promise<SignedEnote | null> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return null;
      }
      const eNoteFilesJSON = await this.storage.getItem(id);
      const eNoteFiles = JSON.parse(eNoteFilesJSON);
      const pdfFile = await getFileFromUrl(eNoteFiles.pdfFile, 'signed.pdf');
      const metadataFile = await getFileFromUrl(
        eNoteFiles.metadataFile,
        'meta.json',
        'application/json',
      );
      return { pdfFile, metadataFile };
    } catch (error) {
      console.error('EnoteFilesRepositoryService: ', error);
      return null;
    }
  }

  /**
   * This function adds new SignedEnote to storage
   * @param {string} id - eNote id
   * @param {SignedEnote} signedEnote - SignedEnote
   */
  async createOrUpdate(id: string, signedEnote: SignedEnote): Promise<void> {
    try {
      await this.setTable();
      const pdfFile = await convertFileToDataURLAsync(signedEnote.pdfFile);
      const metadataFile = await convertFileToDataURLAsync(
        signedEnote.metadataFile,
      );
      const signedEnoteJSON = JSON.stringify({ pdfFile, metadataFile });
      await this.storage.setItem(id, signedEnoteJSON);
    } catch (error) {
      console.error('EnoteFilesRepositoryService: ', error);
    }
  }

  /**
   * This function deletes SignedEnote in storage
   * @param {string} id - eNote id
   */
  async delete(id: string): Promise<void> {
    try {
      await this.setTable();
      const exists = await this.storage.isKey(id);
      if (!exists) {
        return;
      }
      await this.storage.removeItem(id);
    } catch (error) {
      console.error('EnoteFilesRepositoryService: ', error);
    }
  }

  /**
   * This function deletes all keys
   */
  async clear(): Promise<void> {
    try {
      await this.setTable();
      await this.storage.clear();
      await this.addDummy();
    } catch (error) {
      console.error('EnoteFilesRepositoryService: ', error);
    }
  }

  /**
   * This function sets storage table
   */
  async setTable() {
    await this.storage.setTable(this._tableName);
  }
}
