import { Injectable } from '@angular/core';

import { DatabaseService } from '@et/storage';

@Injectable({
  providedIn: 'root',
})
export class DataBaseResolver {
  constructor(private dbService: DatabaseService) {}

  async resolve(): Promise<boolean> {
    if (!this.dbService.isService) {
      try {
        await this.dbService.init();
        await this.dbService.setTables();
      } catch (error) {
        console.error(error);
        return true;
      }
    }
    return true;
  }
}
